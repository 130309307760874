import { autoserialize, autoserializeAs } from 'cerialize';
import { User } from './user';

export class HistoryEvent implements IHistoryEvent {
  @autoserialize id: string;
  @autoserialize lng?: number;
  @autoserialize lat?: number;
  @autoserializeAs('created_at') createdAt: string;
  @autoserializeAs('updated_at') updatedAt: string;
  @autoserialize meta: any;
  @autoserialize changes: any;
  @autoserializeAs('event_type') eventType: string;
  @autoserializeAs('target_type') targetType: string;
  @autoserializeAs('target_id') targetId: number;
  @autoserializeAs('user_id') userId?: string;
  @autoserialize user?: User;

  constructor(
    eventPayload: IHistoryEvent
  ) {
    this.id = eventPayload?.id;
    this.createdAt = eventPayload?.createdAt;
    this.updatedAt = eventPayload?.updatedAt;
    this.meta = eventPayload?.meta;
    this.changes = eventPayload?.changes;
    this.eventType = eventPayload?.eventType;
    this.targetId = eventPayload?.targetId;
    this.targetType = eventPayload?.targetType;
    this.userId = eventPayload?.userId?.toString();
    this.user = eventPayload?.user ? new User(eventPayload?.user): undefined;
    this.lng = eventPayload?.lng;
    this.lat = eventPayload?.lat;
  }
}

export interface IHistoryEvent {
  id: string;
  lng?: number;
  lat?: number;
  createdAt: string;
  updatedAt: string;
  meta?: any;
  changes?: any;
  eventType: string;
  targetType: string;
  targetId: number;
  userId?: string;
  user?: User;
}




