import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ButtonActivity,
  Donation,
  DonationDonorState,
  DonationPartnerState,
  PageActivity,
  Partner,
  Truck
} from '@domains';
import { environment } from "@donor/../environments/environment";
import { AppService } from "@donor/app.service";
import { DonationsService, TrucksService } from '@rspl-api';
import { DesignService, Designable, ResponsiveService } from '@rspl-ui';
import * as moment from "moment";
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent extends Designable implements OnInit {
  @ViewChild(MatStepper) stepper?: MatStepper;
  @Input() donationCode!: string;
  @Input() page = true;
  donation?: Donation;
  headerLogo = 'assets/images/rspl_logo.png';
  step = 5;
  stepTitle = 'Your pick-up request has been submitted!'
  donorStates = DonationDonorState;
  partnerStates = DonationPartnerState;
  taxReceiptUrl = environment.urls.taxReceiptUrl || '';
  contactPhone = environment.supportNumber;
  isSubmitting = false;
  statesWithAdjustedQuote = [
    'quote_sent',
    'payment_skipped',
    'en_routed_to_store',
    'arrived_at_store',
    'en_routed_to_secondary',
    'arrived_at_secondary',
    'completed_secondary',
    'completed'
  ];
  isRefreshing = false;
  queryParams;
  etaTimeout: any;
  truck?: Truck | null;
  partner?: Partner | null;
  showMap = false;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService,
    private truckService: TrucksService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.donationCode || this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
    this.showMap = !!this.queryParams['map'];
  }

  get isDayBefore(): boolean {
    return moment().isSameOrAfter(moment(this.donation?.date).add(-1, 'days').hours(0).minutes(0).second(0).milliseconds(0))
      && moment().isBefore(moment(this.donation?.date).add(1, 'days').hours(0).minutes(0).second(0).milliseconds(0));
  }

  get isSameDay(): boolean {
    return moment().format('YYYY-MM-DD') === this.donation?.date;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.refresh(true);
  }

  refresh(shouldSendActivity?: boolean) {
    this.isRefreshing = true;
    this.service.getDonationByCode(this.donationCode).pipe(take(1))
      .subscribe({
        next: donation => {
          this.isRefreshing = false;
          this.donation = donation;
          this.setStep();
          if (shouldSendActivity) {
            this.service.createDonationActivity(
              this.donation?.id,
              PageActivity.DONATION_MANAGEMENT_PAGE,
              donation.partnerState,
              this.queryParams['origin'] ? this.queryParams['origin'] : 'visit')
              .subscribe();
          }
        },
        error: () => {
          this.isRefreshing = false;
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        }});
  }

  confirm() {
    this.isSubmitting = true;
    this.donationsService.updateDonationStateByCode(this.donationCode, {
      stateAction: 'confirm'
    }).pipe(take(1))
      .subscribe({
        next: () => {
          this.refresh();
        },
        error: () => this.isSubmitting = false
      })
  }

  private setStep() {
    if (!this.donation) return;
    this.step = this.service.getDonationStep(this.donation);
    if (this.step === 2) {
      if (!this.partner){
        this.donationsService
          .getDonationByCode(this.donationCode, 'partner')
          .pipe(take(1))
          .subscribe((donation) => {
            this.partner = donation.partner;
            if (donation.partnerState === DonationPartnerState.en_routed && !!this.partner?.meta?.etaTrackingEnabled) {
              this.trackETA();
            }
          });
      } else if (this.partner.meta?.etaTrackingEnabled) {
        this.trackETA();
      }
    } else if (this.etaTimeout) {
      clearTimeout(this.etaTimeout);
    }
    setTimeout(() => {
      if (this.stepper) {
        this.stepper.selectedIndex = this.step;
      }
    });
    this.isSubmitting = false;
    this.setStepTitle();
    this.setStepLogo();
  }

  private setStepTitle() {
    switch (this.step) {
      case 0:
        this.stepTitle = 'Your pick-up request has been submitted!';
        break;
      case 1:
        this.stepTitle = 'Your Driver has been Assigned';
        break;
      case 2:
        this.stepTitle = 'Track your Pickup ETA';
        break;
      case 3:
        this.stepTitle = 'Confirm Payment';
        break;
      case 4:
        this.stepTitle = 'Thank you for donating!';
        break;
    }
    if (this.donation?.donorState === this.donorStates.canceled || this.donation?.partnerState === this.partnerStates.canceled) {
      this.stepTitle = 'Canceled'
    }
  }

  private setStepLogo() {
    if (this.donation?.donorState === this.donorStates.canceled || this.donation?.partnerState === this.partnerStates.canceled) {
      this.headerLogo = 'assets/images/icon_fail.svg';
    }
    if (this.step === 4) {
      this.headerLogo = 'assets/images/icon_success.svg';
    }
  }

  addToCalendar() {
    if (this.donation) {
      this.service.createDonationActivity(this.donation.id, ButtonActivity.ADD_TO_CALENDAR).subscribe();
      this.service.addToCalendar(this.donation);
    }
  }

  formattedPhoneValue(val: string): string {
    if (val?.length > 0) {
      val = `(${val.slice(0, 3)}) ${val.slice(3, 6)} ${val.slice(6, 10)}`;
    }
    return val;
  }

  get hasAdjustedSpec(): boolean {
    return !!this.donation?.partnerState && this.statesWithAdjustedQuote.includes(this.donation?.partnerState);
  }

  get formattedDonorPhoneValue(): string {
    const val = this.donation?.donor?.phone || '';
    return this.formattedPhoneValue(val);
  }

  get formattedCharityPhoneValue(): string {
    const val = this.donation?.charity?.primaryContact?.phone || '';
    return this.formattedPhoneValue(val);
  }

  get formattedSupportPhoneValue(): string {
    const val = '8573424937';
    return this.formattedPhoneValue(val);
  }

  get fullCharityAddress(): string {
    return this.donation?.charity?.address?.street + ', ' + this.donation?.charity?.address?.city + ', ' + this.donation?.charity?.address?.state + ', ' + this.donation?.charity?.address?.zip;
  }

  get fullDonorAddress(): string {
    return this.donation?.address?.street + ', ' + this.donation?.address?.city + ', ' + this.donation?.address?.state + ', ' + this.donation?.address?.zip + (this.donation?.address?.secondary ? (' #'+ this.donation?.address?.secondary) : '');
  }

  onRescheduleOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.RESCHEDULE_DONATION).subscribe();
  }

  onCancelOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.CANCEL_DONATION).subscribe();
  }

  onEditDonorInfoOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.EDIT_DONOR_INFO).subscribe();
  }

  onEditDonationSpecOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.EDIT_DONATION_SPEC).subscribe();
  }

  onDonationPhotosOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.ADD_DONATION_PHOTOS).subscribe();
  }

  onTaxReceiptOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.TAX_RECEIPT).subscribe();
  }

  onReceiptOptionClick(): void {
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.RECEIPT).subscribe();
  }

  trackETA() {
    if (this.etaTimeout) {
      clearTimeout(this.etaTimeout);
    }
    if (this.donation?.id)
      this.donationsService
        .getDonationByCode(this.donationCode, 'vehicle')
        .pipe(take(1))
        .subscribe((donation) => {
          this.truck = donation.vehicle;
          if (donation.partnerState !== DonationPartnerState.en_routed) {
            this.refresh();
          }
        });

    this.etaTimeout = setTimeout(() => {
      this.trackETA();
    }, 30000);
  }
}
