import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class RsplApiModule {
  public static forRoot(): ModuleWithProviders<RsplApiModule> {
    return {
      ngModule: RsplApiModule,
      providers: [RsplApiModule],
    };
  }
}
