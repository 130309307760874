import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ENVIRONMENT, Environment } from '@domains';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeComponent } from './stripe/stripe.component';
@NgModule({
  declarations: [StripeComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NgxStripeModule,
  ],
  exports: [StripeComponent],
})
export class RsplPaymentModule {
  public static forRoot(
    environment: Environment
  ): ModuleWithProviders<RsplPaymentModule> {
    return {
      ngModule: RsplPaymentModule,
      providers: [
        RsplPaymentModule,
        { provide: ENVIRONMENT, useValue: environment },
      ],
    };
  }
}
