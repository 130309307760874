import { Component, Inject, Input, OnChanges } from '@angular/core';
import { AppType, ENVIRONMENT, Environment, Pricing, Specification } from '@domains';
import { DesignService, Designable } from '../designable';
import { ResponsiveService } from '../responsive';
import { MatDialog } from '@angular/material/dialog';
import { DiscountInfoDialogComponent } from './discount-info-dialog/discount-info-dialog.component';

@Component({
  selector: 'rspl-donation-estimate',
  templateUrl: './donation-estimate.component.html',
  styleUrls: ['./donation-estimate.component.scss'],
})
export class DonationEstimateComponent extends Designable implements OnChanges {
  @Input() pricing!: Pricing;

  initialSpecification!: Specification;
  #specification!: Specification;
  @Input() set specification(specification: Specification) {
    this.#specification = specification;
    this.initialSpecification = new Specification({ ...specification });
  }
  get specification(): Specification {
    return this.#specification;
  }

  @Input() showZeros = true;
  @Input() gratuity?: number;
  @Input() bookingFee?: number | null;
  @Input() showPrices = true;
  @Input() showUnitPrices = true;
  @Input() collapsable = false;
  @Input() disabled = false;
  @Input() editable = false;
  @Input() totalLabel = 'Total';
  @Input() bookingLabel = 'Booking Fee';
  @Input() showBookingFee: 'regular' | 'title' | 'none' = 'title';
  @Input() showTodaysCharges = false;
  @Input() showDiscount = false;
  @Input() includeBookingInTotal = false;
  public donationPrice?: number;
  public fuelFee?: number;
  isCharityApp: boolean;
  isDriverApp: boolean;
  isDonorApp: boolean;
  app: AppType;

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    protected dialog: MatDialog,
    override designService: DesignService,
    override responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.isCharityApp = this.config.app === AppType.CHARITY;
    this.isDriverApp = this.config.app === AppType.DRIVER;
    this.isDonorApp = this.config.app === AppType.DONOR;
    this.app = this.config.app;
  }

  ngOnChanges(): void {
    this.updatePrice();
  }

  updatePrice() {
    if (this.showPrices && this.pricing) {
      this.donationPrice = Pricing.getTotalPrice(
        this.specification,
        this.pricing,
        this.gratuity
      );
      this.fuelFee = this.pricing.fuelFee
        ? (Pricing.getTotalBasePrice(this.specification, this.pricing) *
            this.pricing.fuelFee) /
          100
        : 0;
    }
  }

  checkValue(event: any) {
    const val = Number(event.target.value.replaceAll(/[^\d]/g, ''));
    return isNaN(val) || val === undefined ? 0 : val;
  }

  focusInput(event: any) {
    event.target.select();
  }

  public get isChanged() {
    return !this.initialSpecification.isSameAs(this.specification);
  }

  showDiscountInfoDialog() {
    this.dialog.open(DiscountInfoDialogComponent, {
        panelClass: 'discount-info-dialog',
      },
    );
  }
}
