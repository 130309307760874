import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { HistoryEvent, User } from '@domains';
import { HistoryChange } from '@domains';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) {}

  mapChanges(
    history: HistoryEvent[],
    configUsers: { [key: string]: string },
    replaceFields: { [key: string]: string },
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject';
    },
    ignoreFields: string[],
    showUserRole = true
  ): HistoryChange[] {
    const changes: HistoryChange[] = [];
    history.forEach((event) => {
      changes.push({
        event: this.getEvent(event.eventType),
        time: this.datePipe.transform(event.createdAt, 'MMM d, y hh:mm a'),
        user: this.getUser(event.user, event.eventType, configUsers, showUserRole),
        oneValue: !!event.changes?.id,
        fields: this.fields(event.changes, ignoreFields).map((field) => {
          const type = this.getType(field, fieldTypes);
          let oldValue;
          let newValue;
          switch (type) {
            case 'date':
              oldValue = this.datePipe.transform(
                event.changes[field][0],
                'MMM d, y'
              );
              newValue = this.datePipe.transform(
                event.changes[field][1],
                'MMM d, y'
              );
              break;
            case 'dateTime':
              oldValue = this.datePipe.transform(
                event.changes[field][0],
                'MMM d, y hh:mm a'
              );
              newValue = this.datePipe.transform(
                event.changes[field][1],
                'MMM d, y hh:mm a'
              );
              break;
            case 'dateTime':
              oldValue = this.datePipe.transform(
                event.changes[field][0],
                'MMM d, y hh:mm a'
              );
              newValue = this.datePipe.transform(
                event.changes[field][1],
                'MMM d, y hh:mm a'
              );
              break;
            case 'string':
              oldValue = this.convertLink(event.changes[field][0]);
              newValue = this.convertLink(event.changes[field][1]);
              break;
            case 'currency':
              oldValue = this.currencyPipe.transform(
                event.changes[field][0] / 100
              );
              newValue = this.currencyPipe.transform(
                event.changes[field][1] / 100
              );
              break;
            case 'object':
              oldValue = this.getDiff(event.changes[field], 0, field, replaceFields);
              newValue = this.getDiff(event.changes[field], 1, field, replaceFields);
              break;
            case 'objectList':
              oldValue = this.getListDiff(event.changes[field], 0, field, replaceFields);
              newValue = this.getListDiff(event.changes[field], 1, field, replaceFields);
              break;
            case 'objectObject':
              oldValue = this.getObjDiff(event.changes[field], 0, field, replaceFields);
              newValue = this.getObjDiff(event.changes[field], 1, field, replaceFields);
              break;
            case 'listObject':
              const c = [
                event.changes[field][0] ? event.changes[field][0] : null,
                event.changes[field][1] ? event.changes[field][1] : null,
              ];
              oldValue = this.getListDiff(c, 0, field, replaceFields);
              newValue = this.getListDiff(c, 1, field, replaceFields);
              break;
          }
          return {
            name: HistoryService.getFieldName(field, replaceFields),
            oldValue,
            newValue,
          };
        }),
        lng: event.lng,
        lat: event.lat
      });
    });
    return changes;
  }

  getEvent(eventType: string): string {
    return eventType
      .replace('_code_update', '_update')
      .split('_')
      .join(' ')
      .toUpperCase();
  }

  getUser(
    user: User | undefined,
    eventType: string,
    configUsers: { [key: string]: string },
    showUserRole = true
  ): string {
    if (user) {
      return user?.name + (showUserRole ? ` (${user?.roles.join(', ')})` : '');
    } else if (configUsers[eventType]) {
      return configUsers[eventType];
    } else {
      return 'System';
    }
  }

  fields(changes: any, ignoreFields: string[]): string[] {
    return Object.keys(changes).filter((x) => !ignoreFields.includes(x));
  }

  getType(
    field: string,
    fieldTypes: {
      [key: string]:
        | 'string'
        | 'object'
        | 'date'
        | 'dateTime'
        | 'currency'
        | 'objectList'
        | 'objectObject'
        | 'listObject';
    }
  ):
    | 'string'
    | 'object'
    | 'date'
    | 'dateTime'
    | 'currency'
    | 'objectList'
    | 'objectObject'
    | 'listObject' {
    return fieldTypes[field] || 'string';
  }

  convertLink(val: any): string {
    if (Array.isArray(val)) {
      return (
        '<ul><li>' +
        val.map((x) => this.convertLink(x)).join('</li><li>') +
        '</li></ul>'
      );
    }
    if (val?.toString()?.includes('https://')) {
      return `<a href=\"${val}\" target=\"_blank\">${val}</a>`;
    } else {
      return val;
    }
  }

  getDiff(
    change: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string },
    specialDiff?: (
      change: any[],
      position: number,
      parent: string,
      key: string
    ) => string | undefined
  ): string {
    const keys = Object.keys(change[0] || [])
      .concat(Object.keys(change[1] || []))
      .filter((v, i, a) => a.indexOf(v) === i);
    return keys
      .filter(
        (k) =>
          !change[0] || !change[1] || change[0][k]?.toString() !== change[1][k]?.toString()
      )
      .map((k) => {
        const special = specialDiff
          ? specialDiff(change, position, parent, k)
          : undefined;
        if (special) {
          return special;
        }
        let val = change[position] ? change[position][k] : '';
        if (val === null) {
          val = '';
        }
        val = this.convertLink(val);
        return `${HistoryService.getFieldName(k, replaceFields)}: ${val}`;
      })
      .join('<br/>');
  }

  getListDiff(
    changes: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string }
  ): string {
    const ch =
      changes[0] && changes[1]
        ? changes[0].length > changes[1].length
          ? changes[0]
          : changes[1]
        : changes[0] || changes[1];
    const d = ch.map((x: any, i: number) => {
      const diff = this.getDiff(
        [changes[0] ? changes[0][i] : null, changes[1] ? changes[1][i] : null],
        position,
        parent,
        replaceFields
      );
      return diff ? i + 1 + ': <br/>' + diff : '';
    });
    return d.filter((x: string) => x !== '').join('<hr/>');
  }

  getObjDiff(
    changes: any[],
    position: number,
    parent: string,
    replaceFields: { [key: string]: string }
  ): string {
    return Object.keys(changes[0] || [])
      .concat(Object.keys(changes[1] || []))
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((x, i) => {
        const ch = [
          changes[0] && changes[0][x] ? changes[0][x] : null,
          changes[1] && changes[1][x] ? changes[1][x] : null,
        ];
        return (
          (i > 0 ? ': <br/>' : '') +
          this.getDiff(ch, position, parent, replaceFields)
        );
      })
      .join('<hr/>');
  }

  static getFieldName(
    field: string,
    replaceFields: { [key: string]: string }
  ): string {
    return (replaceFields[field] || field)
      .split('_')
      .map((x) => x[0].toUpperCase() + x.slice(1))
      .join(' ');
  }
}
