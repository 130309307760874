import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  DONOR_CANCEL_REASONS,
  Donation,
  DonationPartnerState,
  PageActivity,
  Reason,
} from '@domains';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import {
  ConfirmDialogComponent,
  DesignService,
  Designable,
  ResponsiveService,
} from '@rspl-ui';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent extends Designable implements OnInit {
  donationCode: string;
  donation?: Donation;

  reasons: Reason[];
  selectedReason: Reason;
  reason: FormControl<Reason>;
  subReason: FormControl<string>;
  additionalText: FormControl<string>;

  isSubmitting = false;
  reasonBase = '';
  queryParams: any;
  showErrors = false;
  errorStateMatcher = {
    isErrorState: (ctrl: FormControl<string>) => ctrl.invalid,
  };

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private currency: CurrencyPipe,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;

    this.reason = new FormControl<Reason>(null, Validators.required);
    this.subReason = new FormControl<string>(null, Validators.required);
    this.additionalText = new FormControl<string>(null, Validators.required);
    this.reasons = DONOR_CANCEL_REASONS;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          if (!this.service.canCancelDonation(this.donation)) {
            this.router.navigate([`/i/${this.donationCode}`]);
          }
          this.service
            .createDonationActivity(
              this.donation?.id,
              PageActivity.CANCEL_DONATION_PAGE
            )
            .subscribe();
          if (
            moment(donation.date).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD')
          ) {
            this.reasonBase = '[Same Day] ';
          } else {
            this.reasonBase = '[Prior Notice] ';
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Donation <b>${this.donationCode}</b> could not be found!`,
              url: window.location.href,
            },
          });
        },
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate(['/', 'i', this.donationCode], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate(['/', 'i', this.donationCode]);
    }
  }

  onCancel() {
    this.reason.updateValueAndValidity();
    this.reason.markAsTouched();
    this.subReason.updateValueAndValidity();
    this.subReason.markAsTouched();
    this.additionalText.updateValueAndValidity();
    this.additionalText.markAsTouched();

    this.showErrors =
      this.reason.invalid ||
      this.subReason.invalid ||
      this.additionalText.invalid;
    if (this.showErrors) {
      return;
    }
    const cancelationFee =
      this.donation?.charity?.marketId &&
      this.donation?.pricing?.cancellationFee &&
      ![
        DonationPartnerState.assigned,
        DonationPartnerState.unassigned,
        DonationPartnerState.declined,
      ].includes(this.donation?.partnerState) &&
      this.donation.date === moment().format('YYYY-MM-DD')
        ? this.donation?.pricing?.cancellationFee
        : this.donation?.charity?.marketId
        ? null
        : 15.0;
    this.service.createDonationActivity(
      this.donation.id,
      ButtonActivity.CANCEL_DONATION_POPUP,
      this.reason.value.reason + ': ' + this.subReason.value
    ).pipe(take(1)).subscribe();
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '500px',
        data: {
          color: 'primary',
          title: 'Cancel Donation',
          yes: 'Continue Cancellation',
          no: 'Go Back',
          message:
            this.getCancellationText(
              this.reason.value.reason,
              this.subReason.value
            ) +
            (cancelationFee
              ? `<br/>${this.currency.transform(
                  cancelationFee
                )} cancellation fee will be charged`
              : ''),
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.cancel();
        }
      });
  }

  cancel() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.CANCEL_DONATION_SUBMIT
      )
      .subscribe();
    this.isSubmitting = true;
    let reason =
      this.reasonBase +
      this.reason.value.reason +
      ' : ' +
      (this.subReason.value ? this.subReason.value : '/');
    let note = this.additionalText.value;
    this.donationsService
      .updateDonationStateByCode(this.donationCode, {
        stateAction: 'cancel',
        terminationReason: reason,
        terminationNote: note,
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate([`/i/${this.donationCode}/cancel/success`]);
        },
        error: () => {
          this.isSubmitting = false;
          this.service
            .createDonationActivity(
              this.donation?.id,
              ButtonActivity.CANCEL_DONATION_SUBMIT_ERROR
            )
            .subscribe();
        },
      });
  }

  setSelectedReason(): void {
    this.selectedReason = this.reason.value;
    this.subReason.setValue(null);
    if (this.selectedReason.children) {
      this.subReason.setValidators(Validators.required);
      this.additionalText.clearValidators();
      this.subReason.updateValueAndValidity();
      this.additionalText.updateValueAndValidity();
    } else {
      this.subReason.clearValidators();
      this.additionalText.setValidators(Validators.required);
      this.additionalText.updateValueAndValidity();
    }
  }

  getCancellationText(reason, subReason): string {
    switch (reason + ': ' + subReason) {
      case 'I no longer need the items picked up: I gave away my items':
        return 'Are you sure you’d like to cancel your pickup? Our licensed, insured haulers offer professional disassembly, same-day pickups, and can remove items from anywhere in your home. We hope you’ll consider using ReSupply again, as we’d love to help you in the future!';
      case 'The cost was too high: I thought the service was free':
        return 'Are you sure you’d like to cancel your pickup? Our priority pickups reflect the value of our licensed, trained, and insured haulers; the transportation needed to find your items a new home; and our support team, which would otherwise come out of the pockets of our partner charities. We deeply appreciate each donor and strive to provide the highest level of care as you generously give back to your local charities. If you have any questions about our fees, please contact support.';
      case 'I no longer need the items picked up: I had an emergency':
        return 'Are you sure you want to cancel your pickup? If you need assistance rescheduling, please don’t hesitate to contact our support team. We hope you’ll consider using ReSupply again, as we’d love to help you in the future!';
      case 'The available pickup dates do not meet my needs: I am unavailable to reschedule':
        return 'Are you sure you want to cancel your pickup? If you need assistance rescheduling, please don’t hesitate to contact our support team. We hope you’ll consider using ReSupply again, as we’d love to help you in the future!';
      case 'The cost was too high: The fees were confusing and not explained well':
        return 'Are you sure you want to cancel your pickup? Our service fees help ensure that your items find a new home by covering costs like professional disassembly, or if our licensed, trained, and insured haulers need to use stairs or an elevator to access your items. These fees also support the ethical recycling or disposal of any damaged items. We greatly appreciate your generosity and hope you’ll consider using ReSupply again in the future. If you have any questions about our fees, please contact support.';
      case 'The available pickup dates do not meet my needs: I needed a different date for pickup':
        return 'Are you sure you want to cancel your pickup? You can edit your pickup to another date that fits your schedule. If you need assistance rescheduling, please don’t hesitate to contact our support team. We hope you’ll consider using ReSupply again, as we’d love to help you in the future!';
      case 'I no longer need the items picked up: My replacement items did not arrive':
        return 'Are you sure you’d like to cancel your pickup? Our licensed, insured haulers offer professional disassembly, same-day pickups, and can remove items from anywhere in your home. We hope you’ll consider using ReSupply again, as we’d love to help you in the future!';
      case 'The cost was too high: I disagreed with the recycling fees/upcharges':
        return 'Are you sure you want to cancel your pickup?Our charity partners may not accept certain items, especially if they’re damaged. If that’s the case, your hauler may attempt to deliver your items to another local charity. If that’s not possible, the haulers will ensure the items are recycled or properly disposed of which will incur a recycling or disposal fee. If you have any questions about our fees, please contact support.';
      case 'The cost was too high: I thought the booking fee was the price for the pickup':
        return 'Are you sure you want to cancel your pickup?Our service fees help ensure that your items find a new home by covering costs like professional disassembly, or if our licensed, trained, and insured haulers need to use stairs or an elevator to access your items. These fees also support the ethical recycling or disposal of any damaged items. We greatly appreciate your generosity and hope you’ll consider using ReSupply again in the future. If you have any questions about our fees, please contact support.';
      case 'The cost was too high: I was given a different price over the phone':
        return 'Are you sure you want to cancel your pickup? The final pickup price that is determined on-site by your haul may vary due to factors like disassembly, damaged items, charity restrictions, or stair/elevator fees. We truly value your generosity and strive to provide exceptional care as you support local charities. If you have any questions about pricing, please contact our support team.';
      default:
        return 'Are you sure you want to cancel donation?';
    }
  }
}
