import { autoserialize, autoserializeAs } from 'cerialize';

export enum DonationPaymentType {
  regular = 'regular',
  offline = 'offline',
  manual_payment = 'manual_payment',
  cancellation = 'cancellation',
  floatPayment = 'float_payment',
}

export class DonationPayment implements IDonationPayment {
  @autoserialize completed: boolean;
  @autoserialize failed: boolean;
  @autoserialize processing: boolean;
  @autoserializeAs('payment_type') paymentType?: DonationPaymentType;
  @autoserializeAs('auth_completed') authCompleted: boolean;
  @autoserializeAs('setup_completed') setupCompleted: boolean;
  @autoserializeAs('stripe_application_fee') stripeApplicationFee: number;
  @autoserializeAs('stripe_application_fee_refund')
  stripeApplicationFeeRefund?: string;
  @autoserializeAs('stripe_connected_refund') stripeConnectedRefund?: string;
  @autoserializeAs('stripe_payment_intent') stripePaymentIntent?: string; //can refund cancellation
  @autoserializeAs('stripe_refund') stripeRefund?: string; //cancellation refunded
  @autoserializeAs('booking_intent') bookingIntent?: string; //can refund booking
  @autoserializeAs('booking_refund') bookingRefund?: string; //booking refunded
  @autoserializeAs('stripe_connected_account') stripeConnectedAccount?: string;
  @autoserializeAs('stripe_connected_invoice') stripeConnectedInvoice?: string;
  @autoserializeAs('stripe_invoice') stripeInvoice?: string;
  @autoserializeAs('stripe_connected_payment_intent')
  stripeConnectedPaymentIntent?: string;
  @autoserializeAs('stripe_customer') stripeCustomer?: string;

  constructor(payload?: IDonationPayment) {
    this.completed = !!payload?.completed;
    this.paymentType = payload?.paymentType;
    this.failed = !!payload?.failed;
    this.processing = !!payload?.processing;
    this.authCompleted = !!payload?.authCompleted;
    this.setupCompleted = !!payload?.setupCompleted;
    this.stripeConnectedRefund = payload?.stripeConnectedRefund;
    this.stripeApplicationFeeRefund = payload?.stripeApplicationFeeRefund;
    this.stripeApplicationFee = payload?.stripeApplicationFee || 0;
    this.stripePaymentIntent = payload?.stripePaymentIntent;
    this.stripeRefund = payload?.stripeRefund;
    this.bookingIntent = payload?.bookingIntent;
    this.bookingRefund = payload?.bookingRefund;
    this.stripeConnectedAccount = payload?.stripeConnectedAccount;
    this.stripeConnectedInvoice = payload?.stripeConnectedInvoice;
    this.stripeInvoice = payload?.stripeInvoice;
    this.stripeConnectedPaymentIntent = payload?.stripeConnectedPaymentIntent;
    this.stripeCustomer = payload?.stripeCustomer;
  }
}

export interface IDonationPayment {
  completed: boolean;
  paymentType?: DonationPaymentType;
  failed: boolean;
  processing: boolean;
  authCompleted: boolean;
  setupCompleted: boolean;
  stripeConnectedRefund?: string;
  stripeApplicationFeeRefund?: string;
  stripeApplicationFee?: number;
  stripePaymentIntent?: string;
  stripeRefund?: string;
  bookingIntent?: string;
  bookingRefund?: string;
  stripeConnectedAccount?: string;
  stripeConnectedInvoice?: string;
  stripeInvoice?: string;
  stripeConnectedPaymentIntent?: string;
  stripeCustomer?: string;
}
