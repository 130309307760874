<div class="page-header big-header" fxLayoutAlign="start end">
  <h2>
    Your donation<br/>pickup is <br/> being processed!
  </h2>
</div>
<div class="page-content" *ngIf="!expired">
  <div class="outcome-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <p class="text-center">
      You will receive an email confirmation momentarily with all of the information you need.<br/><br/>For any
      questions please feel free to chat us below or text us at <br/><a
      href="sms:+1{{contactPhone}}"><b>{{contactPhone}}</b></a>.
    </p>
    <p *ngIf="!error && !donationCode">Please  wait</p>
    <mat-spinner *ngIf="!error && !donationCode"></mat-spinner>
    <p class="error" *ngIf="error">Something went wrong please try again ({{error.error.code}})</p>
  </div>
</div>
<div class="page-content" *ngIf="expired">
  <p class="error expired">Your link has expired, please contact support to get a new link</p>
</div>


