import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@donor/app.service';
import { ConfigService } from '@rspl-api';
import { ButtonActivity, Design, Donation, PageActivity } from '@domains';
import { DesignService } from '@rspl-ui';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  public donationCode = '';
  donation?: Donation;
  hasReview = false;
  reviewOptions: any = [];

  constructor(
    private route: ActivatedRoute,
    private service: AppService,
    private configService: ConfigService,
    public designService: DesignService
  ) {
    designService.setDesign(Design.DESIGN_2);
  }

  ngOnInit(): void {
    this.donationCode = this.route.snapshot.params['code'];
    this.service.getDonationByCode(this.donationCode).pipe(take(1))
      .subscribe(
        donation => {
          if (donation.review) {
            this.hasReview = true;
          }
          this.donation = donation;
          this.service.createDonationActivity(this.donation?.id, PageActivity.PAYMENT_SUCCESS_PAGE).subscribe();
        });
    this.getAppConfig();
  }

  getAppConfig(): void {
    this.configService.getAppConfig().pipe(take(1)).subscribe((config) => {
      this.reviewOptions = config.review_options;
    })
  }

  submitPublicReviewActivity(platform: string): void {
    this.service.createDonationActivity(this.donation?.id, this.getActivity(platform)).subscribe();
  }

  getActivity(platform: string): ButtonActivity {
    switch(platform) {
      case 'Yelp':
        return ButtonActivity.YELP
      case 'Google My Business':
        return ButtonActivity.GOOGLE_MY_BUSINESS
      case 'Better Business Bureau':
        return ButtonActivity.BETTER_BUSINESS_BUREAU
      default:
        return ButtonActivity.UNKNOWN;
    }

  }
}
