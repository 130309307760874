import { autoserializeAs } from 'cerialize';

export class ReceiptResponse {
  @autoserializeAs('receipt_email') email?: string;
  @autoserializeAs('receipt_number') number?: string;
  @autoserializeAs('receipt_url') url?: string;

  constructor(
    payload?: IReceiptResponse
  ) {
    this.email = payload?.email;
    this.number = payload?.number;
    this.url = payload?.url;
  }
}

export interface IReceiptResponse {
  email?: string;
  number?: string;
  url?: string;
}
