<h2 mat-dialog-title>
  <div fxLayout="row" fxLayoutGap="10px">
    <div>
      <mat-icon *ngIf="data.icon" svgIcon="{{data.icon}}"></mat-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <span>{{data.title}}</span>
      <span>{{data.subtitle}}</span>
    </div>
  </div>
</h2>
<div mat-dialog-content>
  <p [innerHTML]="data.message"></p>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button class="primary-btn filled-btn" (click)="onChoice(true)" cdkFocusInitial>{{data.button || 'Ok'}}</button>
  </div>
</div>
