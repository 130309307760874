import { Address, IAddress } from './address';
import { Contact, IContact } from './contact';
import { Fee, IFee } from './fee';

import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';
import { IMarket, Market } from './market';
import { IPartnerMeta, PartnerMeta } from './partner-meta';
import { IPartnerPayment, PartnerPayment } from './partner-payment';
import { IPartnerReferences, PartnerReferences } from './partner-references';

export class Partner extends BaseDTO implements IPartner {
  @autoserialize id?: string;
  @autoserialize override name: string;
  @autoserialize ein: string;
  @autoserialize references: PartnerReferences;
  @autoserializeAs(Contact, 'primary_contact') primaryContact?: Contact | null;
  @autoserializeAs(Contact, 'billing_contact') billingContact?: Contact | null;
  @autoserialize address: Address;
  @autoserializeAs(PartnerPayment, 'payment') payment: PartnerPayment;
  @autoserialize score: number;
  @autoserialize journey: boolean | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserializeAs(Market, 'market') market?: Market | null;
  @autoserializeAs('market_id') marketId?: string | null;
  @autoserializeAs('auto_decline') autoDecline?: boolean | null;
  @autoserializeAs(Fee, 'fee') fee?: Fee | null;
  @autoserializeAs(PartnerMeta, 'meta') meta?: PartnerMeta | null;
  @autoserializeAs('acceptance_threshold') threshold?: number | null;
  @autoserialize geofence?: 'soft' | 'hard' | null;

  public get phone(): string {
    return this.primaryContact?.phone || '';
  }

  public get fullAddress(): string {
    return this.address.fullAddress;
  }

  constructor(payload: IPartner) {
    super(payload);
    this.id = payload?.id?.toString();
    this.name = payload?.name;
    this.ein = payload?.ein;
    this.references = new PartnerReferences(payload?.references);
    this.primaryContact = payload?.primaryContact
      ? new Contact(payload?.primaryContact)
      : undefined;
    this.billingContact = payload?.billingContact
      ? new Contact(payload?.billingContact)
      : undefined;
    this.address = new Address(payload?.address);
    this.payment = new PartnerPayment(payload?.payment);
    this.score = payload?.score;
    this.journey = payload?.journey;
    this.discardedAt = payload?.discardedAt;
    this.market = payload?.market ? new Market(payload.market) : undefined;
    this.marketId = payload?.marketId?.toString() || this.market?.id;
    this.autoDecline = payload?.autoDecline;
    this.fee = payload?.fee ? new Fee(payload?.fee) : payload?.fee;
    this.meta = new PartnerMeta(payload?.meta);
    this.threshold = payload?.threshold;
    this.geofence = payload?.geofence || null;
  }

  public static OnSerialized(instance: Partner, json: any): void {
    Partner.thresholdDolarsToCents(json);
  }

  public static OnDeserialized(instance: Partner, json: any): void {
    instance = Partner.thresholdCentsToDolars(instance);
  }

  public static thresholdDolarsToCents(partner: any): any {
    partner.threshold =
      partner.threshold === null || isNaN(partner.threshold)
        ? partner.threshold
        : Math.round(partner.threshold * 100);
    return partner;
  }

  public static thresholdCentsToDolars(partner: any): any {
    partner.threshold =
      partner.threshold !== null ? partner.threshold / 100 : null;
    return partner;
  }
}

export interface IPartner extends IBaseDTO {
  id?: string;
  name: string;
  ein: string;
  references: IPartnerReferences;
  primaryContact?: IContact | null;
  billingContact?: IContact | null;
  address: IAddress;
  payment: IPartnerPayment;
  score: number;
  journey: boolean | null;
  discardedAt?: string | null;
  market?: IMarket | null;
  marketId?: string | null;
  autoDecline?: boolean | null;
  fee?: IFee | null;
  meta?: IPartnerMeta | null;
  threshold?: number | null;
  geofence?: 'soft' | 'hard' | null;
}
