<div class="file-container" (click)="selectImage()" fxLayout="column" fxLayoutAlign="center center">
  <button *ngIf="!imgResultAfterCompress && !currentImageUrl && !isUploading" class="primary-btn block-btn">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span>
    Add Photo
      </span>
      <mat-icon>camera_alt</mat-icon>
    </div>
  </button>
  <input #file type="file" accept="image/*"/>
  <img [src]="currentImageUrl" *ngIf="!imgResultAfterCompress && currentImageUrl">
  <img [src]="imgResultAfterCompress" *ngIf="imgResultAfterCompress && !isUploading">
  <mat-spinner *ngIf="isUploading"></mat-spinner>
  <mat-icon *ngIf="!isUploading && fail" class="retry" (click)="retry($event)">refresh</mat-icon>
  <mat-icon *ngIf="!isUploading && fail" class="fail">cancel</mat-icon>
  <mat-icon *ngIf="!isUploading && success" class="success">check_circle</mat-icon>
</div>
