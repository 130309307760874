import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface InformationDialogData {
  title: string;
  message: string;
  button?: string;
}

@Component({
  selector: 'rspl-information-dialog',
  templateUrl: './information-dialog.component.html'
})
export class InformationDialogComponent {

  public constructor(
    public reference: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationDialogData
  ) {
  }

  public dismiss(): void {
    this.reference.close();
  }
}
