import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ButtonActivity,
  Charity,
  CharityScreeningVersion,
  PageActivity,
  Screening,
  SplitScreenVersion,
} from '@domains';
import { AppService } from '@donor/app.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-split-screen',
  templateUrl: './split-screen.component.html',
  styleUrls: ['./split-screen.component.scss'],
})
export class SplitScreenComponent implements OnInit {
  #charity!: Charity;
  @Input() set charity(charity: Charity) {
    this.#charity = charity;
    if (this.charity) {
      this.service
        .createLeadActivity(
          this.leadId,
          PageActivity.SPLIT_LANDING_PAGE,
          this.charity.meta?.version
        )
        .pipe(take(1))
        .subscribe();
    }
  }
  get charity(): Charity {
    return this.#charity;
  }

  @Input() leadId?: string;
  @Output() close: EventEmitter<{
    disassembly: boolean,
    stairs: boolean,
  } | undefined> = new EventEmitter();
  @ViewChild('RestrictedItems') restrictedItemsTemplate!: TemplateRef<any>;
  restrictedItemsDialog: any;

  public isDesktop: boolean = true;

  splitScreenVersions = SplitScreenVersion;

  @Input() screening?: Screening | null;

  showScreening = false;

  constructor(
    private service: AppService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isDesktop = window.innerWidth > 960;
  }

  get textValues(): string[] {
    return (this.charity.meta?.text?.trim().length || 0) > 0
      ? this.charity.meta?.text?.trim().split('\n') || []
      : [];
  }

  get textValue(): string | undefined {
    return (this.charity.meta?.text?.trim().length || 0) > 0
      ? this.charity.meta?.text?.trim().replaceAll('\n', '<br/>')
      : undefined;
  }

  get textPrimaryValues(): string[] {
    return this.charity.meta?.textPrimary?.split('\n') || [];
  }

  get textPrimaryValue(): string | undefined {
    return (
      this.charity.meta?.textPrimary?.replaceAll('\n', '<br/>') || undefined
    );
  }

  goToPrimary(): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.PRIORITY_OPTION_CLICKED)
      .pipe(take(1))
      .subscribe(() => this.close.next(undefined));
  }

  goToFree(): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.FREE_OPTION_CLICKED)
      .pipe(take(1))
      .subscribe(() => {
        if (
          this.charity.meta?.screening === CharityScreeningVersion.V1 &&
          this.screening
        ) {
          this.showScreening = true;
        } else if (this.charity.meta?.url) {
          window.location.href = (this.charity.meta?.url.startsWith('http') ? '' : 'https://') + this.charity.meta?.url;
        }
      });
  }

  showRestrictedItems() {
    this.restrictedItemsDialog = this.dialog.open(this.restrictedItemsTemplate);
  }
}
