<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <div
    class="item"
    fxLayout="row"
    fxLayoutGap="25px"
    fxLayoutGap.lt-md="5px"
    [ngClass]="{disabled}"
  >
    <div class="image" fxFlex="50px" fxLayoutAlign="space-around start">
      <img class="{{icon}}" src="{{ 'assets/icons-v2/' + icon + '.svg' }}" [alt]="itemTitle" />
    </div>
    <div class="title" (click)="openInfoDialog()">
      <h3>
        {{ itemTitle }}
      </h3>
      <p>{{ subtitle }}</p>
    </div>
    <div class="counter-controls" fxLayout="row" fxLayoutAlign="space-between">
      <div
        class="counter-btn"
        [ngClass]="{ disabled: count === 0 || count === min }"
        (click)="removeItem()"
      >
        <mat-icon>remove</mat-icon>
      </div>
      <span *ngIf="!editable || disabled" [ngClass]="{ zero: count === 0 }">{{
        count
      }}</span>
      <input
        *ngIf="editable && !disabled"
        type="tel"
        [(ngModel)]="count"
        (keyup)="updateItem($event)"
        (focus)="focusInput($event)"
      />
      <div
        class="counter-btn"
        [ngClass]="{ disabled: count === max }"
        (click)="addItem()"
      >
        <mat-icon>add</mat-icon>
      </div>
    </div>
  </div>
</ng-template>
