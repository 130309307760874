import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadFileService } from '@rspl-api';
import { NgxImageCompressService } from 'ngx-image-compress';
import { catchError, finalize, take } from 'rxjs';
import { SelectPhotoComponent } from './select-photo/select-photo.component';

@Component({
  selector: 'rspl-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
})
export class PhotoUploadComponent implements OnInit {
  @ViewChild('file') file!: HTMLInputElement;
  @Input() public currentImageUrl: string | ArrayBuffer | null = '';
  @Output() uploadedImageUrl = new EventEmitter();
  @Input() public disabled = false;
  @Input() photos?: string[] | null;
  public imgResultAfterCompress?: string;
  isUploading = false;
  fail = false;
  success = false;

  constructor(
    private imageCompress: NgxImageCompressService,
    private uploadFileService: UploadFileService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  reset() {
    this.fail = false;
    this.success = false;
    this.isUploading = false;
    this.imgResultAfterCompress = undefined;
  }

  selectImage() {
    if (!this.photos || this.photos?.length === 0) {
      this.compressFile();
    } else {
      this.dialog
        .open(SelectPhotoComponent, {
          data: this.photos,
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: '100vw',
          height: '100vh',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.success = true;
            this.uploadedImageUrl.emit(res);
          } else if (res === null) {
            this.compressFile();
          }
        });
    }
  }

  upload(): void {
    this.uploadFileService
      .uploadFile(this.dataURItoBlob(this.imgResultAfterCompress))
      .pipe(
        catchError((error) => {
          this.fail = true;
          console.log('UPLOAD ERROR:', error);
          this.currentImageUrl = '/assets/images/no-img-placeholder.png';
          return error;
        }),
        finalize(() => (this.isUploading = false)),
        take(1)
      )
      .subscribe((response: any) => {
        this.uploadedImageUrl.emit(response.url);
        this.success = true;
      });
  }

  compressFile() {
    if (this.disabled) {
      return;
    }
    this.imageCompress
      .uploadFileOrReject()
      .then((res) => {
        this.isUploading = true;
        this.imageCompress
          .compressFile(res.image, res.orientation, 50, 50)
          .then((compressed) => {
            this.isUploading = true;
            this.compressed(compressed);
          })
          .catch((err) => {
            this.isUploading = false;
            console.log('Image compress error', err);
          });
      })
      .catch((err) => {
        console.log('Image upload error', err);
      })
      .finally(() => {
        this.isUploading = false;
      });
  }

  private compressed(image: any) {
    this.fail = false;
    this.success = false;
    this.imgResultAfterCompress = image;
    this.upload();
  }

  dataURItoBlob(dataURI: any) {
    let byteString = atob(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  retry(event: MouseEvent) {
    this.fail = false;
    this.success = false;
    event.stopPropagation();
    this.upload();
  }
}
