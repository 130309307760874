import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Address,
  AppType,
  Charity,
  CharityStore,
  Day,
  Donation,
  DonationDonorState,
  DonationPartnerState,
  ENVIRONMENT,
  Environment,
  HistoryEvent,
  Journey,
  JourneyStop,
  JourneyStopType,
  Market,
  Partner,
  Theme,
  Truck,
  Xmile,
} from '@domains';
import { Responsive, ResponsiveService, ThemeService } from '@rspl-ui';
import { JourneyCustomStop } from 'libs/domains/src/journey-custom-stop';
import { AnyPaint, Layer, LngLatBoundsLike } from 'mapbox-gl';
import { MapComponent as MapBoxMapComponent } from 'ngx-mapbox-gl';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'rspl-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends Responsive implements OnInit {
  @ViewChild('map') map!: MapBoxMapComponent;
  isCaptain: boolean;
  appType: AppType;
  appTypes = AppType;
  accessToken: string;
  mapFocused = false;
  theme?: Theme;
  themes = Theme;
  primaryColor?: string;

  @Input() fitBounds?: LngLatBoundsLike = [
    -125.0011, 24.9493, -66.9326, 49.5904,
  ];
  @Input() day?: Day;
  @Input() disabled = false;
  @Input() showAddressSearch = true;
  @Input() zoom?: [number];
  @Input() center?: [number, number];
  address?: Address;

  @Input() multiselectPins = false;
  @Input() showSettings = false;
  @Input() showZoom = true;
  @Input() animations = false;

  @Input() marketsMap: { [key: string]: Market } = {};
  marketsTotalCount = 0;
  @Input() set markets(markets: Array<Market> | undefined) {
    this.marketsMap = {};
    markets?.forEach((m) => {
      if (m.id) this.marketsMap[m.id] = m;
    });
    this.marketsTotalCount = this.markets?.length || 0;
  }

  //#region Zips
  @Input() showZips = false;
  @Input() showAllZips = false;
  @Input() selectableZips = false;
  @Input() hoverableZips = false;
  @Output() zipSelectionUpdated: EventEmitter<string[]> = new EventEmitter();

  paint = {
    'fill-outline-color': '#696969',
    'fill-color': {
      property: 'fill',
      type: 'identity',
    },
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      0.1,
    ],
  } as AnyPaint;

  disabledPaint = {
    'fill-outline-color': 'transparent',
    'fill-color': {
      property: 'fill',
      type: 'identity',
    },
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0,
      0,
    ],
  } as AnyPaint;

  selectedPaint = {
    'fill-outline-color': '#696969',
    'fill-color': {
      property: 'fill',
      type: 'identity',
    },
    'fill-opacity': 1,
  } as AnyPaint;
  @Input() set selectedZipsOpacity(selectedZipsOpacity: number) {
    this.selectedPaint = {
      ...this.selectedPaint,
      'fill-opacity': selectedZipsOpacity,
    };
  }
  @Input() set selectedZipsColor(selectedZipsColor: string) {
    this.selectedPaint = {
      ...this.selectedPaint,
      'fill-color': selectedZipsColor,
      'fill-outline-color': selectedZipsColor,
    };
  }

  zipFilters = ['in', 'ZIP5'];
  hoveredZipId?: string | null;
  zipPopupLngLat = [0, 0];

  #selectedZips: string[] = [];
  @Input() set selectedZips(selectedZips: string[]) {
    this.#selectedZips = selectedZips ? [...selectedZips] : [];
    this.zipFilters = ['in', 'ZIP5', ...this.selectedZips];
  }

  get selectedZips(): string[] {
    return this.#selectedZips;
  }
  //#endregion Zips

  //#region Donations
  donationsMap: { [key: string]: Donation } = {};
  #donations?: Array<Donation>;
  @Input() set donations(donations: Array<Donation> | undefined) {
    this.#donations = donations;
    this.donationsMap = {};
    this.donations?.forEach((d) => {
      if (d.id) {
        this.donationsMap[d.id] = d;
      }
    });
    this.setDonationClasses();
    this.updateBounds(true, true);
    this.drawLine();
  }
  get donations(): Array<Donation> | undefined {
    return this.#donations;
  }
  #showDonations = false;
  @Input() set showDonations(showDonations: boolean) {
    this.#showDonations = showDonations;
  }
  get showDonations(): boolean {
    return this.#showDonations;
  }
  @Input() showDonationPopup = false;
  @Input() showDonationStateColor = true;
  @Input() donationClass?: (
    d: Donation,
    isSelected: boolean
  ) => { [key: string]: boolean };
  donationClasses: { [key: string]: { [key: string]: boolean } } = {};
  selectedDonationLocation?: [number, number];
  #selectedDonation?: Donation;
  @Input() set selectedDonation(selectedDonation: Donation | undefined) {
    if (this.#selectedDonation?.id === selectedDonation?.id) return;
    this.setSelectedDonation(selectedDonation);
  }
  get selectedDonation(): Donation | undefined {
    return this.#selectedDonation;
  }
  #hoveredDonation?: Donation;
  @Input() set hoveredDonation(hoveredDonation: Donation | undefined) {
    if (this.#hoveredDonation?.id === hoveredDonation?.id) return;
    this.setHoveredDonation(hoveredDonation);
  }
  get hoveredDonation(): Donation | undefined {
    return this.#hoveredDonation;
  }
  @Output() onDonationSelected = new EventEmitter<Donation>();
  @Output() onDonationHovered = new EventEmitter<Donation>();
  partnerStates = DonationPartnerState;
  donorStates = DonationDonorState;
  //#endregion Donations

  //#region Partners
  partnersMap: { [key: string]: Partner } = {};
  #partners?: Array<Partner>;
  @Input() set partners(partners: Array<Partner> | undefined) {
    this.#partners = partners;
    this.partners?.forEach((p) => {
      if (p.id) {
        this.partnersMap[p.id] = p;
      }
    });
    this.updateBounds(true, true);
    this.drawLine();
  }
  get partners(): Array<Partner> | undefined {
    return this.#partners;
  }
  #showPartners = false;
  @Input() set showPartners(showPartners: boolean) {
    this.#showPartners = showPartners;
  }
  get showPartners(): boolean {
    return this.#showPartners;
  }
  @Input() showPartnerPopup = false;
  @Input() partnerPinTemplate?: TemplateRef<any>;
  selectedPartnerLocation?: [number, number];
  #selectedPartner?: Partner;
  @Input() set selectedPartner(selectedPartner: Partner | undefined) {
    if (this.#selectedPartner?.id === selectedPartner?.id) return;
    this.setSelectedPartner(selectedPartner);
  }
  get selectedPartner(): Partner | undefined {
    return this.#selectedPartner;
  }
  #hoveredPartner?: Partner;
  @Input() set hoveredPartner(hoveredPartner: Partner | undefined) {
    if (this.#hoveredPartner?.id === hoveredPartner?.id) return;
    this.setHoveredPartner(hoveredPartner);
  }
  get hoveredPartner(): Partner | undefined {
    return this.#hoveredPartner;
  }
  @Output() onPartnerSelected = new EventEmitter<Partner>();
  @Output() onPartnerHovered = new EventEmitter<Partner>();
  //#endregion Partners

  //#region Trucks
  #trucks?: Array<Truck>;
  @Input() set trucks(trucks: Array<Truck> | undefined) {
    this.#trucks = trucks;
    this.updateBounds(true, true);
  }
  get trucks(): Array<Truck> | undefined {
    return this.#trucks;
  }
  #showTrucks = false;
  @Input() set showTrucks(showTrucks: boolean) {
    this.#showTrucks = showTrucks;
  }
  get showTrucks(): boolean {
    return this.#showTrucks;
  }
  @Input() showTruckPopup = false;
  @Input() truckPinTemplate?: TemplateRef<any>;
  @Input() selectedTruck?: Truck;
  selectedTruckLocation?: [number, number];
  @Output() onTruckSelected = new EventEmitter<Truck>();
  //#endregion Trucks

  //#region Charities
  #charities?: Array<Charity>;
  @Input() set charities(charities: Array<Charity> | undefined) {
    this.#charities = charities;
    this.updateBounds(true, true);
  }
  get charities(): Array<Charity> | undefined {
    return this.#charities;
  }
  #showCharities = false;
  @Input() set showCharities(showCharities: boolean) {
    this.#showCharities = showCharities;
  }
  get showCharities(): boolean {
    return this.#showCharities;
  }
  @Input() showCharityPopup = false;
  selectedCharity?: Charity;
  selectedCharityLocation?: [number, number];
  @Output() onCharitySelected = new EventEmitter<Charity>();
  //#endregion Charities

  //#region Stores
  storesMap: { [key: string]: CharityStore } = {};
  #stores?: Array<CharityStore>;
  @Input() set stores(stores: Array<CharityStore> | undefined) {
    this.#stores = stores;
    this.stores?.forEach((s) => {
      if (s.id) {
        this.storesMap[s.id] = s;
      }
    });
    this.updateBounds(true, true);
    this.drawLine();
  }
  get stores(): Array<CharityStore> | undefined {
    return this.#stores;
  }
  @Input() hideStoreLogo: boolean = false;
  #showStores = false;
  @Input() set showStores(showStores: boolean) {
    this.#showStores = showStores;
  }
  get showStores(): boolean {
    return this.#showStores;
  }
  @Input() showStorePopup = false;
  @Input() showSelectedStoreFlag = false;
  selectedStoreLocation?: [number, number];
  #selectedStore?: CharityStore;
  @Input() set selectedStore(selectedStore: CharityStore | undefined) {
    if (this.#selectedStore?.id === selectedStore?.id) return;
    this.setSelectedStore(selectedStore);
  }
  get selectedStore(): CharityStore | undefined {
    return this.#selectedStore;
  }
  #hoveredStore?: CharityStore;
  @Input() set hoveredStore(hoveredStore: CharityStore | undefined) {
    if (this.#hoveredStore?.id === hoveredStore?.id) return;
    this.setHoveredStore(hoveredStore);
  }
  get hoveredStore(): CharityStore | undefined {
    return this.#hoveredStore;
  }
  @Output() onStoreSelected = new EventEmitter<CharityStore>();
  @Output() onStoreHovered = new EventEmitter<CharityStore>();
  //#endregion Stores

  //#region Xmiles
  #xmiles?: Array<Xmile>;
  @Input() set xmiles(xmiles: Array<Xmile> | undefined) {
    this.#xmiles = xmiles;
    this.updateBounds(true, true);
  }
  get xmiles(): Array<Xmile> | undefined {
    return this.#xmiles;
  }
  #showXmiles = false;
  @Input() set showXmiles(showXmiles: boolean) {
    this.#showXmiles = showXmiles;
  }
  get showXmiles(): boolean {
    return this.#showXmiles;
  }
  @Input() showXmilePopup = false;
  selectedXmile?: Xmile;
  selectedXmileLocation?: [number, number];
  @Output() onXmileSelected = new EventEmitter<Xmile>();
  //#endregion Xmiles

  //#region Truck Zips
  @Input() truckZips: { [key: string]: string[] } = {};
  get truckZipIds(): string[] {
    return Object.keys(this.truckZips);
  }
  @Input() selectedTruckPaints?: {
    [key: string]: {
      'fill-color': string;
      'fill-opacity': number;
    };
  };
  @Input() selectedTruckPaint: {
    'fill-color': string;
    'fill-opacity': number;
  } = { 'fill-color': '#038153', 'fill-opacity': 0.3 };
  hoveredTrucks: { [key: string]: Truck } = {};
  get hoveredTruckIds(): string[] {
    return Object.keys(this.hoveredTrucks);
  }
  selectedTrucks: { [key: string]: Truck } = {};
  @Output() onZipTrucksSelected = new EventEmitter<{ [key: string]: Truck }>();
  @Input() selectableTruckZips = false;
  @Input() hoverableTruckZips = false;
  //#endregion Truck Zips

  //#region Store Zips
  @Input() storeZips: { [key: string]: string[] } = {};
  get storeZipIds(): string[] {
    return Object.keys(this.storeZips);
  }

  @Input() selectedStorePaints?: {
    [key: string]: {
      'fill-color': string;
      'fill-opacity': number;
    };
  };
  @Input() selectedStorePaint: {
    'fill-color': string;
    'fill-opacity': number;
  } = {
    'fill-color': '#d0057f',
    'fill-opacity': 0.3,
  };
  hoveredStores: { [key: string]: CharityStore } = {};
  get hoveredStoreIds(): string[] {
    return Object.keys(this.hoveredStores);
  }
  selectedStores: { [key: string]: CharityStore } = {};
  @Output() onZipStoresSelected = new EventEmitter<{
    [key: string]: CharityStore;
  }>();
  @Input() selectableStoreZips = false;
  @Input() hoverableStoreZips = false;
  //#endregion Store Zips

  //#region Truck & Store Zips
  protected showTruckStoreHoverPopup = true;
  openTruckChoiceTimeout: any;
  showTruckStoreList = false;
  truckStorePopupLngLat?: [number, number];
  truckStoreSelectedZipPaint: {
    'fill-color': string;
    'fill-opacity': number;
  } = {
    'fill-color': '#0261ad',
    'fill-opacity': 0.3,
  };
  //#endregion Truck & Store Zips

  //#region Journey
  #journey?: Journey;
  @Input() set journey(journey: Journey | undefined) {
    this.#journey = journey;
    this.journeyDonationsMap = {};
    this.journeyStoresMap = {};
    this.journeyPartnersMap = {};
    this.customStops = [];
    this.journey?.stops?.forEach((stop) => {
      switch (stop.type) {
        case JourneyStopType.Donation:
          this.journeyDonationsMap[stop.id] = stop.order;
          break;
        case JourneyStopType.Store:
          this.journeyStoresMap[stop.id] = stop.order;
          break;
        case JourneyStopType.Partner:
          this.journeyPartnersMap[stop.id] = stop.order;
          break;
        case JourneyStopType.Custom:
          this.customStops.push(stop);
          break;
      }
    });
    this.route = this.journey?.geojson;
    this.updateBounds(true, true);
    this.drawLine();
  }
  get journey(): Journey | undefined {
    return this.#journey;
  }
  @Input() showRoute = false;
  journeyDonationsMap: {
    [key: string]: number;
  } = {};
  journeyStoresMap: {
    [key: string]: number;
  } = {};
  journeyPartnersMap: {
    [key: string]: number;
  } = {};
  customStops: JourneyStop[] = [];
  route: Layer['source'];
  routeID = '';
  @Input() skipFirstStep = false;
  #nextStep?: number;
  @Input() set nextStep(nextStep: number | undefined) {
    this.#nextStep = nextStep;
    if (
      this.nextStep &&
      this.journey?.stops &&
      this.journey?.stops[this.nextStep]
    ) {
      switch (this.journey?.stops[this.nextStep].type) {
        case JourneyStopType.Donation:
          this.setSelectedDonation(
            this.donationsMap[this.journey?.stops[this.nextStep].id]
          );
          break;
        case JourneyStopType.Store:
          this.setSelectedStore(
            this.storesMap[this.journey?.stops[this.nextStep].id]
          );
          break;
      }
    }
  }
  get nextStep(): number | undefined {
    return this.#nextStep;
  }
  #selectedCustomStop?: JourneyStop;
  @Input() set selectedCustomStop(selectedCustomStop: JourneyStop | undefined) {
    if (this.#selectedCustomStop?.id === selectedCustomStop?.id) return;
    this.setSelectedCustomStop(selectedCustomStop);
  }
  get selectedCustomStop(): JourneyStop | undefined {
    return this.#selectedCustomStop;
  }
  #hoveredCustomStop?: JourneyStop;
  @Input() set hoveredCustomStop(hoveredCustomStop: JourneyStop | undefined) {
    if (this.#hoveredCustomStop?.id === hoveredCustomStop?.id) return;
    this.setHoveredCustomStop(hoveredCustomStop);
  }
  get hoveredCustomStop(): JourneyStop | undefined {
    return this.#hoveredCustomStop;
  }
  @Output() onCustomStopSelected = new EventEmitter<JourneyStop>();
  @Output() onCustomStopHovered = new EventEmitter<JourneyStop>();
  //#endregion Journey

  //#region Events
  @Input() showEventsLine = false;
  eventsMap: { [key: string]: HistoryEvent } = {};
  #events?: Array<HistoryEvent>;
  @Input() set events(events: Array<HistoryEvent> | undefined) {
    this.#events = events;
    this.events?.forEach((e) => {
      if (e.id) {
        this.eventsMap[e.id] = e;
      }
    });
    this.updateBounds(true, true);
    if (this.showEventsLine) {
      setTimeout(() => {
        this.drawEventsLine();
      }, 100);
    }
  }
  get events(): Array<HistoryEvent> | undefined {
    return this.#events;
  }
  #showEvents = false;
  @Input() set showEvents(showEvents: boolean) {
    this.#showEvents = showEvents;
  }
  get showEvents(): boolean {
    return this.#showEvents;
  }

  #selectedEvent?: HistoryEvent;
  @Input() set selectedEvent(selectedEvent: HistoryEvent | undefined) {
    if (this.#selectedEvent?.id === selectedEvent?.id) return;
    this.setSelectedEvent(selectedEvent);
  }
  get selectedEvent(): HistoryEvent | undefined {
    return this.#selectedEvent;
  }
  #hoveredEvent?: HistoryEvent;
  @Input() set hoveredEvent(hoveredEvent: HistoryEvent | undefined) {
    if (this.#hoveredEvent?.id === hoveredEvent?.id) return;
    this.setHoveredEvent(hoveredEvent);
  }
  get hoveredEvent(): HistoryEvent | undefined {
    return this.#hoveredEvent;
  }
  @Output() onEventSelected = new EventEmitter<HistoryEvent>();
  @Output() onEventHovered = new EventEmitter<HistoryEvent>();
  //#endregion Events

  //#region User Location
  @Input() set showUserLocation(show: boolean) {
    if (show) {
      this.getLocation();
    } else {
      this.userLocation = undefined;
    }
  }
  userLocation?: [number, number];
  //#endregion User Location

  constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private themeService: ThemeService,
    public override responsiveService: ResponsiveService
  ) {
    super(responsiveService);
    this.accessToken = this.config.mapBoxAccessToken;
    this.isCaptain = [AppType.CAPTAIN, AppType.ZENDESK].includes(
      this.config.app
    );
    this.appType = this.config.app;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.primaryColor = getComputedStyle(document.body)
      .getPropertyValue('--primary')
      .trim();
    this.themeService.theme
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme) => {
        if (!theme.theme) return;
        this.theme = theme.theme;
        const el = document.querySelector(`.${this.theme}`);
        if (el !== null) {
          this.primaryColor =
            getComputedStyle(el).getPropertyValue('--primary');
        }
      });
  }

  updateBounds(ignoreSelected = !this.animations, force = false): void {
    const selectedCoordinates = this.getCoordinates(true, [
      ...(this.selectedDonation
        ? [
            {
              lng: this.selectedDonation.address.lng,
              lat: this.selectedDonation.address.lat,
            },
          ]
        : []),
      ...(this.selectedPartner
        ? [
            {
              lng: this.selectedPartner.address.lng,
              lat: this.selectedPartner.address.lat,
            },
          ]
        : []),
      ...(this.selectedCharity
        ? [
            {
              lng: this.selectedCharity.address.lng,
              lat: this.selectedCharity.address.lat,
            },
          ]
        : []),
      ...(this.selectedStore
        ? [
            {
              lng: this.selectedStore.address.lng,
              lat: this.selectedStore.address.lat,
            },
          ]
        : []),
      ...(this.selectedXmile
        ? [
            {
              lng: this.selectedXmile.address.lng,
              lat: this.selectedXmile.address.lat,
            },
          ]
        : []),
    ]);
    if (this.selectedEvent?.lng && this.selectedEvent?.lat) {
      selectedCoordinates.lats.push(this.selectedEvent.lat);
      selectedCoordinates.lngs.push(this.selectedEvent.lng);
    }
    if (ignoreSelected && !force) {
      return;
    }
    const donationCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showDonations,
            this.donations
              ?.filter(
                (x) =>
                  x.address?.lat !== undefined && x.address.lng !== undefined
              )
              ?.map((x) => ({
                lat: x.address.lat,
                lng: x.address.lng,
              }))
          );
    const partnerCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showPartners,
            this.partners?.map((x) => ({
              lat: x.address.lat,
              lng: x.address.lng,
            }))
          );
    const charityCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showCharities,
            this.charities?.map((x) => ({
              lat: x.address.lat,
              lng: x.address.lng,
            }))
          );
    const storeCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showStores,
            this.stores?.map((x) => ({
              lat: x.address.lat,
              lng: x.address.lng,
            }))
          );
    const xmileCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showXmiles,
            this.xmiles?.map((x) => ({
              lat: x.address.lat,
              lng: x.address.lng,
            }))
          );
    const truckCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.showTrucks,
            this.trucks?.map((x) =>
              x.lat && x.lng
                ? { lat: x.lat, lng: x.lng }
                : { lat: x?.partner?.address.lat, lng: x?.partner?.address.lng }
            )
          );
    const eventCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            this.#showEvents,
            this.events?.map((x) => ({ lat: x?.lat, lng: x?.lng }))
          );
    const jobCoordinates =
      !ignoreSelected && selectedCoordinates.lats.length > 0
        ? { lats: [], lngs: [] }
        : this.getCoordinates(
            !!this.journey,
            this.customStops
              .filter((stop) => !this.journey?.jobs[Number(stop.id)].completed)
              ?.map((stop) => ({
                lat: this.journey?.jobs[Number(stop.id)].lat,
                lng: this.journey?.jobs[Number(stop.id)].lng,
              }))
          );
    const lats =
      ignoreSelected || selectedCoordinates.lats.length === 0
        ? [
            ...donationCoordinates.lats,
            ...partnerCoordinates.lats,
            ...charityCoordinates.lats,
            ...storeCoordinates.lats,
            ...xmileCoordinates.lats,
            ...truckCoordinates.lats,
            ...eventCoordinates.lats,
            ...jobCoordinates.lats,
          ]
        : [...selectedCoordinates.lats];
    const lngs =
      ignoreSelected || selectedCoordinates.lngs.length === 0
        ? [
            ...donationCoordinates.lngs,
            ...partnerCoordinates.lngs,
            ...charityCoordinates.lngs,
            ...storeCoordinates.lngs,
            ...xmileCoordinates.lngs,
            ...truckCoordinates.lngs,
            ...eventCoordinates.lngs,
            ...jobCoordinates.lngs,
          ]
        : [...selectedCoordinates.lngs];
    if (lngs?.length === 0) {
      this.fitBounds = [-125.0011, 24.9493, -66.9326, 49.5904];
      return;
    }
    const minLng = Math.min(...lngs);
    const minLat = Math.min(...lats);
    const maxLng = Math.max(...lngs);
    const maxLat = Math.max(...lats);
    const lngDiff =
      lngs.length === 1 ? 0.005 : Math.min((maxLng - minLng) * 0.15, 0.2);
    const latDiff =
      lngs.length === 1 ? 0.005 : Math.min((maxLat - minLat) * 0.15, 0.2);

    this.fitBounds = [
      minLng - lngDiff,
      minLat - latDiff,
      maxLng + lngDiff,
      maxLat + latDiff,
    ];
  }

  private getCoordinates(
    shouldInclude: boolean,
    list: Array<{ lng?: number; lat?: number } | undefined> = []
  ): { lngs: number[]; lats: number[] } {
    const lngs: number[] = [];
    const lats: number[] = [];
    if (shouldInclude)
      list
        ?.filter((p) => p?.lat !== undefined && p?.lng !== undefined)
        .forEach((p) => {
          if (p?.lat !== undefined) lats.push(p.lat);
          if (p?.lng !== undefined) lngs.push(p.lng);
        });
    return { lngs, lats };
  }

  setAddress(address?: Address, showPin = true): void {
    if (showPin) this.address = address;
    if (address) {
      this.fitBounds = undefined;
      this.center =
        address?.lat && address.lng ? [address.lng, address.lat] : undefined;
      this.zoom = this.center ? [showPin ? 13 : 8] : undefined;
    }
  }

  protected clearSelections(
    keep?:
      | 'donation'
      | 'partner'
      | 'charity'
      | 'store'
      | 'xmile'
      | 'truck'
      | 'event'
  ) {
    if (this.multiselectPins) return;
    if (this.selectedDonation) {
      this.#selectedDonation = undefined;
      this.selectedDonationLocation = undefined;
      this.onDonationSelected.emit(this.selectedDonation);
    }
    if (this.selectedPartner) {
      this.#selectedPartner = undefined;
      this.selectedPartnerLocation = undefined;
      this.onPartnerSelected.emit(this.selectedPartner);
    }
    if (this.selectedCharity) {
      this.selectedCharity = undefined;
      this.selectedCharityLocation = undefined;
      this.onCharitySelected.emit(this.selectedCharity);
    }
    if (this.selectedStore) {
      this.#selectedStore = undefined;
      this.selectedStoreLocation = undefined;
      this.onStoreSelected.emit(this.selectedStore);
    }
    if (this.selectedXmile) {
      this.selectedXmile = undefined;
      this.selectedXmileLocation = undefined;
      this.onXmileSelected.emit(this.selectedXmile);
    }
    if (this.selectedTruck) {
      this.selectedTruck = undefined;
      this.selectedTruckLocation = undefined;
      this.onTruckSelected.emit(this.selectedTruck);
    }
    if (this.selectedEvent) {
      this.#selectedEvent = undefined;
      this.onEventSelected.emit(this.selectedEvent);
    }
    if (this.selectedCustomStop) {
      this.#selectedCustomStop = undefined;
      this.onCustomStopSelected.emit(this.selectedCustomStop);
    }
  }

  //Zips
  protected zipClick($event: any): void {
    if (this.disabled) return;
    const zip = $event.features[0].properties.ZIP5;
    const res = [...this.selectedZips];
    !res.includes(zip) ? res.push(zip) : res.splice(res.indexOf(zip, 0), 1);
    this.zipSelectionUpdated.emit(res);
  }

  protected zipMouseMove($event: any): void {
    if (this.disabled) return;
    if (
      this.hoveredZipId &&
      (!$event?.features?.length || this.hoveredZipId !== $event.features[0].id)
    ) {
      this.map.mapInstance.setFeatureState(
        {
          source: 'zips',
          id: this.hoveredZipId,
          sourceLayer: 'zip5_topo_color-2bf335',
        },
        { hover: false }
      );
      this.hoveredZipId = null;
    }
    if (
      !this.disabled ||
      ($event.features && this.selectedZips.includes($event.features[0].id))
    ) {
      if ($event?.features && $event.features[0].id !== this.hoveredZipId) {
        this.hoveredZipId = $event.features[0].id;
        this.map.mapInstance.setFeatureState(
          {
            source: 'zips',
            id: $event.features[0].id,
            sourceLayer: 'zip5_topo_color-2bf335',
          },
          { hover: true }
        );
      }
      this.zipPopupLngLat = [$event.lngLat.lng, $event.lngLat.lat];
    }
  }

  //Donations
  protected setSelectedDonation(donation?: Donation) {
    if (this.disabled) return;
    this.clearSelections();
    this.#selectedDonation = donation;
    this.selectedDonationLocation =
      this.selectedDonation?.address.lng && this.selectedDonation?.address.lat
        ? [this.selectedDonation.address.lng, this.selectedDonation.address.lat]
        : undefined;
    this.setDonationClasses();
    this.onDonationSelected.emit(this.selectedDonation);
    this.updateBounds();
  }

  protected setHoveredDonation(donation?: Donation) {
    if (this.disabled) return;
    if (this.hoveredDonation?.id === donation?.id) return;
    this.#hoveredDonation = donation;
    this.setDonationClasses();
    this.onDonationHovered.emit(this.hoveredDonation);
    this.showTruckStoreHoverPopup = !this.hoveredDonation;
  }

  protected setDonationClasses() {
    if (this.donationClass) {
      this.donationClasses = {};
      this.donations?.forEach((d) => {
        if (d.id && this.donationClass) {
          this.donationClasses[d.id] = this.donationClass(
            d,
            !!this.selectedDonation && this.selectedDonation.id === d.id
          );
          if (d?.id && this.donationClasses && this.donationClasses[d.id]) {
            this.donationClasses[d.id] = {
              ...this.donationClasses[d.id],
              hovered: d.id === this.hoveredDonation?.id,
              selected: d.id === this.selectedDonation?.id,
              'has-number':
                !!d.id &&
                (this.journeyDonationsMap[d.id] === 0 ||
                  !!this.journeyDonationsMap[d.id]),
            };
          }
        }
      });
    }
  }

  //Partners
  protected setSelectedPartner(partner?: Partner) {
    if (this.disabled) return;
    this.clearSelections();
    this.#selectedPartner = partner;
    this.selectedPartnerLocation =
      this.selectedPartner?.address.lng && this.selectedPartner?.address.lat
        ? [this.selectedPartner.address.lng, this.selectedPartner.address.lat]
        : undefined;
    this.onPartnerSelected.emit(this.selectedPartner);
    this.updateBounds();
  }

  protected setHoveredPartner(partner?: Partner) {
    if (this.disabled) return;
    if (this.hoveredPartner?.id === partner?.id) return;
    this.#hoveredPartner = partner;
    this.onPartnerHovered.emit(this.hoveredPartner);
    this.showTruckStoreHoverPopup = !this.hoveredPartner;
  }

  //Trucks

  protected selectTruck(truck?: Truck) {
    if (this.disabled) return;
    this.clearSelections();
    this.selectedTruck = truck;
    this.selectedStoreLocation =
      this.selectedTruck?.partner?.address.lng &&
      this.selectedTruck?.partner?.address.lat
        ? [
            this.selectedTruck.partner?.address.lng,
            this.selectedTruck.partner?.address.lat,
          ]
        : undefined;
    this.onTruckSelected.emit(this.selectedTruck);
  }

  //Charities

  protected selectCharity(charity?: Charity) {
    if (this.disabled) return;
    this.clearSelections();
    this.selectedCharity = charity;
    this.selectedCharityLocation =
      this.selectedCharity?.address.lng && this.selectedCharity?.address.lat
        ? [this.selectedCharity.address.lng, this.selectedCharity.address.lat]
        : undefined;
    this.onCharitySelected.emit(this.selectedCharity);
    this.updateBounds();
  }

  //CharityStores
  protected setSelectedStore(charityStore?: CharityStore) {
    if (this.disabled) return;
    this.clearSelections();
    this.#selectedStore = charityStore;
    this.selectedStoreLocation =
      this.selectedStore?.address.lng && this.selectedStore?.address.lat
        ? [this.selectedStore.address.lng, this.selectedStore.address.lat]
        : undefined;
    this.onStoreSelected.emit(this.selectedStore);
    this.updateBounds();
  }

  protected setHoveredStore(partner?: CharityStore) {
    if (this.disabled) return;
    if (this.hoveredStore?.id === partner?.id) return;
    this.#hoveredStore = partner;
    this.onStoreHovered.emit(this.hoveredStore);
    this.showTruckStoreHoverPopup = !this.hoveredStore;
  }

  //Xmile

  protected selectXmile(xmile?: Xmile) {
    if (this.disabled) return;
    this.clearSelections();
    this.selectedXmile = xmile;
    this.selectedXmileLocation =
      this.selectedXmile?.address.lng && this.selectedXmile?.address.lat
        ? [this.selectedXmile.address.lng, this.selectedXmile.address.lat]
        : undefined;
    this.onXmileSelected.emit(this.selectedXmile);
    this.updateBounds();
  }

  //Truck Zips
  protected truckStoreZipsMouseMove(
    $event: any,
    id: string | undefined,
    type: 'truck' | 'store'
  ): void {
    if (this.disabled) return;
    if (!id) return;
    if (type === 'truck') {
      if (
        Object.keys(this.hoveredTrucks).length > 0 &&
        !$event?.features?.length
      ) {
        this.hoveredTrucks = {};
      }
      const truck = this.trucks?.find((t) => t.id?.toString() === id);
      if (truck && $event.features && !this.hoveredTrucks[id]) {
        if (this.hoveredZipId !== $event.features[0].id) {
          this.hoveredZipId = $event.features[0].id;
          this.hoveredTrucks = {};
        }
        this.hoveredTrucks[id] = truck;
      }
    } else {
      if (
        Object.keys(this.hoveredStores).length > 0 &&
        !$event?.features?.length
      ) {
        this.hoveredStores = {};
      }
      const charityStore = this.stores?.find((s) => s.id?.toString() === id);
      if (charityStore && $event.features && !this.hoveredStores[id]) {
        if (this.hoveredZipId !== $event.features[0].id) {
          this.hoveredZipId = $event.features[0].id;
          this.hoveredStores = {};
        }
        this.hoveredStores[id] = charityStore;
      }
    }
    this.truckStorePopupLngLat = $event.lngLat;
  }

  protected openTruckStoreChoice(
    $event: any,
    id: string | undefined,
    type: 'truck' | 'store'
  ): void {
    if (this.disabled) return;
    if (!id) return;
    this.clearSelections();
    if (this.showTruckStoreList) {
      this.showTruckStoreList = false;
      this.selectedTrucks = {};
      this.selectedStores = {};
    }
    if (type === 'truck') {
      if (
        Object.keys(this.selectedTrucks).length > 0 &&
        !$event?.features?.length
      ) {
        this.selectedTrucks = {};
      }
      const truck = this.trucks?.find((t) => t.id?.toString() === id);
      if (truck && $event.features && !this.selectedTrucks[id]) {
        if (this.hoveredZipId !== $event.features[0].id) {
          this.hoveredZipId = $event.features[0].id;
          this.selectedTrucks = {};
        }
        this.selectedTrucks[id] = truck;
      }
    } else {
      if (
        Object.keys(this.selectedStores).length > 0 &&
        !$event?.features?.length
      ) {
        this.selectedStores = {};
      }
      const charityStore = this.stores?.find((s) => s.id?.toString() === id);
      if (charityStore && $event.features && !this.selectedStores[id]) {
        if (this.hoveredZipId !== $event.features[0].id) {
          this.hoveredZipId = $event.features[0].id;
          this.selectedStores = {};
        }
        this.selectedStores[id] = charityStore;
      }
    }
    this.truckStorePopupLngLat = $event.lngLat;
    if (this.openTruckChoiceTimeout) {
      clearTimeout(this.openTruckChoiceTimeout);
      this.updateBounds();
    }
    this.openTruckChoiceTimeout = setTimeout(() => {
      this.onZipTrucksSelected.emit(this.selectedTrucks);
      this.onZipStoresSelected.emit(this.selectedStores);
      this.showTruckStoreList = true;
    }, 10);
  }

  //Journey
  protected drawLine() {
    if (this.routeID && !!this.map?.mapInstance?.getLayer(this.routeID)) {
      this.map?.mapInstance?.removeLayer(this.routeID);
    }
    if (!this.showRoute) return;
    let coordinates: [number, number][] = [];
    if (!this.journey?.geometry && (this.journey?.stops?.length || 0) > 0) {
      coordinates = [...(this.journey?.stops || [])]
        ?.sort((a, b) => a.order - b.order)
        ?.filter((stop) => {
          if (stop.type === JourneyStopType.Donation) {
            return (
              this.donationsMap[stop.id]?.address?.lng &&
              this.donationsMap[stop.id]?.address?.lat
            );
          } else if (stop.type === JourneyStopType.Store) {
            return (
              this.storesMap[stop.id]?.address?.lng &&
              this.storesMap[stop.id]?.address?.lat
            );
          } else if (stop.type === JourneyStopType.Truck) {
            return !!this.userLocation;
          } else if (stop.type === JourneyStopType.Custom) {
            return (
              this.journey?.jobs[Number(stop.id)].lat &&
              this.journey?.jobs[Number(stop.id)].lng
            );
          } else if (stop.type === JourneyStopType.Partner) {
            return (
              this.partnersMap[stop.id]?.address?.lng &&
              this.partnersMap[stop.id]?.address?.lat
            );
          } else {
            return false;
          }
        })
        ?.map((stop) => {
          if (stop.type === JourneyStopType.Donation) {
            return [
              this.donationsMap[stop.id]?.address?.lng || 0,
              this.donationsMap[stop.id]?.address?.lat || 0,
            ];
          } else if (stop.type === JourneyStopType.Store) {
            return [
              this.storesMap[stop.id]?.address?.lng || 0,
              this.storesMap[stop.id]?.address?.lat || 0,
            ];
          } else if (stop.type === JourneyStopType.Truck) {
            return this.userLocation || [0, 0];
          } else if (stop.type === JourneyStopType.Custom) {
            return [
              this.journey?.jobs[Number(stop.id)].lng || 0,
              this.journey?.jobs[Number(stop.id)].lat || 0,
            ];
          } else if (stop.type === JourneyStopType.Partner) {
            return [
              this.partnersMap[stop.id]?.address?.lng || 0,
              this.partnersMap[stop.id]?.address?.lat || 0,
            ];
          } else {
            return [0, 0];
          }
        });
      coordinates = coordinates.filter((c) => c[0] && c[1]) as [
        number,
        number
      ][];
    }
    this.route = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      },
    };
    this.routeID = (Math.random() + 1).toString(36).substring(7);
  }

  protected drawEventsLine() {
    if (this.routeID && !!this.map?.mapInstance?.getLayer(this.routeID)) {
      this.map?.mapInstance?.removeLayer(this.routeID);
    }
    if (!this.showRoute) return;
    let coordinates: [number, number][] = [];
    if ((this.events?.length || 0) > 0) {
      coordinates = [...(this.events || [])]?.map((event) => {
        return [event.lng || 0, event.lat || 0];
      });
      coordinates = coordinates.filter((c) => c[0] && c[1]) as [
        number,
        number
      ][];
    }
    this.route = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      },
    };
    this.routeID = (Math.random() + 1).toString(36).substring(7);
  }

  protected setSelectedCustomStop(stop?: JourneyStop) {
    if (this.disabled) return;
    this.clearSelections();
    this.#selectedCustomStop = stop;
    this.onCustomStopSelected.emit(this.selectedCustomStop);
    this.updateBounds();
  }

  protected setHoveredCustomStop(stop?: JourneyStop) {
    if (this.disabled) return;
    if (this.hoveredCustomStop?.id === stop?.id) return;
    this.#hoveredCustomStop = stop;
    this.onCustomStopHovered.emit(this.hoveredCustomStop);
    this.showTruckStoreHoverPopup = !this.hoveredCustomStop;
  }

  //HistoryEvents
  protected setSelectedEvent(event?: HistoryEvent) {
    if (this.disabled) return;
    this.clearSelections();
    this.#selectedEvent = event;
    this.onEventSelected.emit(this.selectedEvent);
    this.updateBounds();
  }

  protected setHoveredEvent(event?: HistoryEvent) {
    if (this.disabled) return;
    if (this.hoveredEvent?.id === event?.id) return;
    this.#hoveredEvent = event;
    this.onEventHovered.emit(this.hoveredEvent);
    this.showTruckStoreHoverPopup = !this.hoveredEvent;
  }

  //User Location
  protected getLocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        this.userLocation = [
          position.coords.longitude,
          position.coords.latitude,
        ];
        this.drawLine();
      });
    }
  }

  getJob(id: string): JourneyCustomStop | undefined {
    return this.journey?.jobs[Number(id)];
  }
}
