import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@domains';
import { Fee, Partner } from '@domains';
import { BaseApiService } from '../base-api.service';
import { Deserialize, Serialize } from 'cerialize';

@Injectable({
  providedIn: 'root',
})
export class PartnerService extends BaseApiService<Partner> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(config, http, 'partners', Partner, 'Partner');
  }

  override serialize(item: Partner) {
    return {
      ...Serialize(Partner.thresholdDolarsToCents({ ...item }), Partner),
      ...(item.fee ? { fee: Serialize(Fee.feeDolarsToCents({ ...item.fee }), Fee) } : {}),
    };
  }

  override deserialize(data: any): Partner {
    const res =  new Partner({
      ...Deserialize(data, Partner),
      ...(data.fee ? { fee: new Fee(Deserialize(data.fee, Fee)) } : {}),
    });
    res.setInitialValue();
    return res;
  }
}
