<div class="ss-wrapper">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="header container" fxLayout="column">
      <div
        class="logos"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="24px"
      >
        <img src="/assets/images/new_logo.svg" />
        <img [src]="config.logo || charity.logo" />
      </div>
      <h1>Trusted partner of {{ charity.name }}</h1>
      <p>
        Founded by veterans in 2015, ReSupply is proudly contracted with your
        charity to offer a 24-48 hour in-home pickup of household items—turning
        your cherished belongings into valuable resources for those in need.
      </p>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="primary"
          (click)="goToPrimary()"
        >
          <span fxFlex="100"
            >Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}</span
          >
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="config.standardEnabled"
      fxLayout="column"
      fxFlex="100"
      class="charity container"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="16px"
        class="title-wrapper"
      >
        <div class="img-wrapper">
          <img [src]="config.logo || charity.logo" />
        </div>
        <h2>{{ config.title }}</h2>
      </div>
      <p [innerHTML]="shortText"></p>
      <div
        class="buttons"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="16px"
        fxLayoutAlign="center center"
      >
        <button
          [ngClass]="{ hidden: config?.text?.length < 500 }"
          (click)="showText()"
        >
          Read More
        </button>
        <button (click)="goToFree()">
          {{ config.button || 'Free charity pickup' }}
        </button>
        <button (click)="goToPrimary()">Resupply priority pick-up</button>
      </div>
    </div>
    <div fxLayout="column" class="why" fxLayoutAlign="center center">
      <div fxLayout="column" class="container">
        <h2>Why choose ReSupply?</h2>
        <p>
          ReSupply is committed to putting your needs first as we know how
          important your donations are. Here is why so many charities partner
          with us to provide donation pickups.
        </p>
      </div>
    </div>
    <div fxLayout="column" class="we" fxLayoutAlign="center center">
      <div fxLayout="column" class="container">
        <div
          fxLayout="row wrap"
          fxFlex="100"
          fxLayoutGap="10px"
          fxLayoutGap.lt-lg="48px"
        >
          <div
            fxLayout="column"
            fxFlex="25"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayoutAlign="start center"
          >
            <div
              class="icon-wrapper"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-icon svgIcon="ss3-truck"></mat-icon>
            </div>
            <h3>We pick-up <span>everything</span></h3>
            <p>
              We pick up all items of <b>any</b> condition to find them a new
              home.
            </p>
          </div>
          <div
            fxLayout="column"
            fxFlex="25"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayoutAlign="start center"
          >
            <div
              class="icon-wrapper"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-icon svgIcon="ss3-stairs"></mat-icon>
            </div>
            <h3>We remove items from <span>anywhere</span> in your home</h3>
            <p>
              Our licensed and insured haulers can pickup up your items anywhere
              within your residence, including upstairs.
            </p>
          </div>
          <div
            fxLayout="column"
            fxFlex="25"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayoutAlign="start center"
          >
            <div
              class="icon-wrapper"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-icon svgIcon="ss3-wrench"></mat-icon>
            </div>
            <h3>We offer professionally <span>disassembly</span></h3>
            <p>
              Our haulers are equipped with the training and tools to
              disassemble any of your items for removal.
            </p>
          </div>
          <div
            fxLayout="column"
            fxFlex="25"
            fxFlex.sm="50"
            fxFlex.xs="100"
            fxLayoutAlign="start center"
          >
            <div
              class="icon-wrapper"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-icon svgIcon="ss3-dollar"></mat-icon>
            </div>
            <h3>A fee helps us <span>support</span> local charities</h3>
            <p>
              Our paid service provides offers a convenient, affordable solution
              with flexible scheduling, professional haulers, and full support
              throughout your pickup.
            </p>
          </div>
        </div>
        <div
          class="buttons"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="10px"
        >
          <button
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            class="primary"
            (click)="goToPrimary()"
          >
            <span fxFlex="100"
              >Continue to donation{{
                !isSmallWindowSize ? ' pickup' : ''
              }}</span
            >
            <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
          </button>
          <button
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            (click)="showVideo()"
          >
            <span fxFlex>About Resupply</span>
            <mat-icon svgIcon="ss3-play"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="config.discounts"
      fxLayout="column"
      class="discount"
      fxLayoutAlign="center center"
    >
      <div fxLayout="column" fxFlex="100" class="container">
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start"
          fxLayoutAlign="start center"
          fxLayoutGap="32px"
        >
          <mat-icon class="icon" svgIcon="ss3-percent"></mat-icon>
          <div fxLayout="column">
            <h2>Charity-Sponsored Pickups <span>Now Available!</span></h2>
            <p>
              You might qualify for a charity-sponsored pickup, which could help
              reduce your donation cost. Some of our charity partners offer
              special support to cover part of the pickup fee, making it even
              easier for you to give back.
            </p>
            <p>
              When you schedule your pickup, you’ll automatically be considered
              for one of these sponsored services. Take the next step today and
              see how your donation can make an even bigger impact!
            </p>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <button
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            class="primary"
            (click)="goToPrimary()"
          >
            <span fxFlex="100">Check if you’re eligible</span>
            <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="reviews" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex="100" class="container">
        <h2>Here’s what donors like you are saying about ReSupply</h2>
        <p>
          We deeply value each donor and strive to provide the highest level of
          care as you generously give back to your local charities and support
          local hauling groups.
        </p>

        <div fxLayout="row" fxFlex="100" fxLayoutGap="10px">
          <div fxFlex="50" *ngFor="let rl of reviews">
            <div
              fxLayout="column"
              fxLayoutGap="10px"
              fxLayoutAlign="start center"
            >
              <div class="review" *ngFor="let review of rl" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    class="name"
                    fxLayoutGap="10px"
                  >
                    <span fxLayoutAlign="center center">{{
                      review.name[0]
                    }}</span>
                    <span>{{ review.name }}</span>
                  </div>
                  <star-rating
                    [rating]="review.stars"
                    [starType]="'svg'"
                    [readOnly]="true"
                  ></star-rating>
                </div>
                <p [innerHTML]="review.review"></p>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-icon
                    [svgIcon]="review.icon"
                    *ngIf="review.icon"
                  ></mat-icon>
                  <span class="date" *ngIf="review.date">{{
                    review.date
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="buttons"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="10px"
        >
          <button
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            class="primary"
            (click)="goToPrimary()"
          >
            <span fxFlex="100"
              >Continue to donation{{
                !isSmallWindowSize ? ' pickup' : ''
              }}</span
            >
            <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
          </button>
          <button
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            (click)="showVideo()"
          >
            <span fxFlex>About Resupply</span>
            <mat-icon svgIcon="ss3-play"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div
      fxLayout="column"
      class="container coverage"
      fxLayoutAlign="center center"
    >
      <h2>We deliver to over 2,800 charity locations nationwide</h2>
      <p>
        ReSupply operates in thousands of zip codes with more to come as our
        service areas are constantly expanding. We're committed to reaching even
        more communities, making it easier for donors everywhere to support
        their local charities.
      </p>
      <img src="/assets/split-screen-v3/coverage.webp" />
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="100"
        style="width: 100%"
      >
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="primary"
          (click)="goToPrimary()"
        >
          <span fxFlex="100"
            >Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}</span
          >
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div
      fxLayout="column"
      class="container veterans"
      fxLayoutAlign="center center"
    >
      <h2>Veterans behind Resupply</h2>
      <p>
        ReSupply is proudly founded and led by Veterans who have left their
        military careers to continue serving their country in a different way.
        We strive to bring the same discipline and efficiency to help guide your
        cherished items to their next home.
      </p>
      <div class="carousel-wrapper">
        <ng-carousel
          #carouselRef="ngCarousel"
          [config]="carouselConfig"
          (itemIndexChange)="setItemIndex($event)"
        >
          <ng-template [ngCarouselSlide]="carouselRef" let-item>
            <div
              fxLayout="column"
              class="carousel-item"
              fxLayoutAlign="center center"
            >
              <div
                class="img-wrapper"
                style="background-image: url(/assets/split-screen-v3/people/{{
                  item.image
                }});"
              ></div>
              <span>
                {{ item.name }}
              </span>
              <span>
                {{ item.role }}
              </span>
              <div class="carousel-img" fxLayoutAlign="end center">
                <img
                  src="/assets/split-screen-v3/{{ item.icon }}.png"
                  width="100px"
                />
              </div>
            </div>
          </ng-template>
        </ng-carousel>
        <div fxFlex fxLayoutAlign="center center">
          <div
            class="control-panel"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button (click)="carouselRef.prev()" fxLayoutAlign="center center">
              <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="indicator" fxLayout="row" fxLayoutAlign="center center">
              <div
                *ngFor="let item of carouselConfig.items; index as i"
                [class.active]="i === itemIndex"
              ></div>
            </div>
            <button (click)="carouselRef.next()" fxLayoutAlign="center center">
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="100"
        class="buttons"
      >
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="primary"
          (click)="goToPrimary()"
        >
          <span fxFlex="100"
            >Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}</span
          >
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="column" class="faq" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex="100" class="container">
        <h2>Frequently asked questions</h2>
        <p>
          If you have any other questions, feel free to reach out to our support
          team!
        </p>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let faq of faqs; let i = index"
            [expanded]="i === 0"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                {{ faq.question }}
                <mat-icon
                  [svgIcon]="false ? 'ss3-minus-circle' : 'ss3-plus-circle'"
                ></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <p [innerHTML]="faq.answer"></p>
            <div fxLayout="column" fxLayoutAlign="start start">
              <button
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                class="link"
                (click)="goToPrimary()"
              >
                <span fxFlex="100"
                  >Continue to donation{{
                    !isSmallWindowSize ? ' pickup' : ''
                  }}</span
                >
                <mat-icon svgIcon="ss3-arrow-right-blue"></mat-icon>
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
          <button
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            class="primary"
            (click)="goToPrimary()"
          >
            <span fxFlex="100"
              >Continue to donation{{
                !isSmallWindowSize ? ' pickup' : ''
              }}</span
            >
            <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div
      fxLayout="column"
      fxFlex="100"
      class="container partners"
      fxLayoutGap="48px"
    >
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="20px">
          <h2>
            Resupply partners with 400+ insured and contracted hauling companies
          </h2>
          <p>
            When you schedule a pickup, our contracted team of licensed and
            insured professional haulers will take care of everything. These
            small businesses have the training, tools, and equipment to remove
            even the heaviest and largest of donations. They specialize in
            moving quickly and efficiently to protect charity staff from the
            physically demanding job of donation removal.
          </p>
        </div>
        <img
          class="img1"
          *ngIf="!isSmallWindowSize"
          src="/assets/split-screen-v3/partners1.webp"
        />
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column-reverse"
        fxLayoutGap="20px"
        fxLayoutGap.lt-lg="48px"
      >
        <img class="img2" src="/assets/split-screen-v3/partners2.webp" />
        <div
          fxLayout="column"
          fxFlex
          fxLayoutAlign="center center"
          fxLayoutAlign.lt-md="start start"
        >
          <div
            fxLayout="column"
            style="width: 80%"
            fxLayoutAlign="start start"
            fxLayoutGap="20px"
          >
            <div>
              <h3>25,000+</h3>
              <p>Zip codes covered</p>
            </div>
            <div>
              <h3>500+</h3>
              <p>Trucks</p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="primary"
          (click)="goToPrimary()"
        >
          <span fxFlex="100"
            >Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}</span
          >
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div
      fxLayout="column"
      fxFlex="100"
      class="container extra-mile"
      fxLayoutGap="48px"
    >
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="20px">
          <h2>Going the extra mile to help find your items a new home</h2>
          <p>
            While our charity partners strive to accept everything, some items
            may not be the right fit for their mission. In such cases, we
            encourage our haulers to go the extra mile to find a new home for
            those items, even if it means taking stops at additional charities
            or ethically disposing or recycling your items.
          </p>
        </div>
        <img src="/assets/split-screen-v3/extra-mile.png" />
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="primary"
          (click)="goToPrimary()"
        >
          <span fxFlex="100"
            >Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}</span
          >
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="column" class="support" fxLayoutAlign="center center">
      <div fxLayout="column" class="container" fxLayoutAlign="center center">
        <img src="/assets/split-screen-v3/support.webp" />
        <h2>Veteran-led customer service standing by</h2>
        <p>
          To reach the ReSupply’s veteran-led support team, you can text us at
          <a href="sms:+1 (857) 342-4937">+1 (857) 342-4937</a> or email us at
          <a href="mailto:donorsupport@resupplyme.com">{{
            'donorsupport@resupplyme.com'
          }}</a
          >. We are available Monday to Friday from 8 AM to 8 PM EST, and
          Saturday from 8 AM to 4 PM EST (excluding holidays).
        </p>
      </div>
      <div
        class="buttons"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="16px"
        fxLayoutAlign="center center"
      >
        <a
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-lg="center center"
          fxLayoutGap="10px"
          class="primary"
          href="sms:+1 (857) 342-4937"
        >
          <span fxFlex="100" fxFlex.lt-lg="0">Text us via SMS</span>
          <mat-icon svgIcon="ss3-sms"></mat-icon>
        </a>
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-lg="center center"
          fxLayoutGap="10px"
          class="primary"
          (click)="openZendesk()"
        >
          <span fxFlex="100" fxFlex.lt-lg="0">Chat with our agent</span>
          <mat-icon svgIcon="ss3-chat"></mat-icon>
        </button>
        <a
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-lg="center center"
          fxLayoutGap="10px"
          class="primary"
          href="mailto:donorsupport@resupplyme.com"
        >
          <span fxFlex="100" fxFlex.lt-lg="0">Send us an Email</span>
          <mat-icon svgIcon="ss3-email"></mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
