import { autoserialize } from 'cerialize';

export class DonationReview implements IDonationReview {
  @autoserialize rating: number;
  @autoserialize comment: string;

  constructor(payload: IDonationReview) {
    this.rating = payload.rating;
    this.comment = payload.comment;
  }
}

export interface IDonationReview {
  rating: number;
  comment: string;
}