<div class="page-header big-header" fxLayoutAlign="start end">
  <h2>Ups! Something went wrong</h2>
</div>
<div class="page-content">
  <div class="outcome-wrapper" fxLayout="column" fxLayoutAlign="center start">
    <p>We need your card details to schedule a pickup</p>
  </div>
  <mat-icon svgIcon="rspl-new-logo"></mat-icon>
</div>


