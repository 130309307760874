<div class="page-content org-landing">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div class="header" fxLayoutAlign="center center">NEW PARTNERSHIP</div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="logo" fxLayoutAlign="center center">
        <img src="/assets/images/org-landing/org-logo.png" />
      </div>
      <div class="logo" fxLayoutAlign="center center">
        <img src="/assets/images/rspl_logo.png" />
      </div>
    </div>
    <div
      fxFlex="100"
      class="msg"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <p>
        <b>Goodwill</b> has Partnered with <b>Resupply</b> to do priority
        donation pickups for a fee
      </p>
    </div>
    <div class="icons" fxFlex="100" fxLayout="row" fxLayout.lt-md="column">
      <div
        fxLayout="row"
        class="icons"
        fxFlex="100"
        fxFlex.gt-md="50"
      >
        <div
          class="icon"
          fxFlex="50"
          fxLayoutAlign="start center"
          fxLayout="column"
        >
          <img src="/assets/images/org-landing/i-1.png" />
          <p>Donation pickups <b>within 24 - 48 hours</b></p>
        </div>
        <div
          class="icon"
          fxFlex="50"
          fxLayoutAlign="start center"
          fxLayout="column"
        >
          <img src="/assets/images/org-landing/i-2.png" />
          <p>Our team goes up and down <b>stairs</b></p>
        </div>
      </div>
      <div
        fxLayout="row"
        class="icons"
        fxFlex="100"
        fxFlex.gt-md="50"
      >
        <div
          class="icon"
          fxFlex="50"
          fxLayoutAlign="start center"
          fxLayout="column"
        >
          <img src="/assets/images/org-landing/i-3.png" />
          <p>We provide <b>disassembly</b> on site</p>
        </div>
        <div
          class="icon"
          fxFlex="50"
          fxLayoutAlign="start center"
          fxLayout="column"
        >
          <img src="/assets/images/org-landing/i-4.png" />
          <p><b>We'll take everything</b> the donor is looking to part with</p>
        </div>
      </div>
    </div>
    <div
      fxFlex="100"
      fxLayoutAlign="center center"
      fxLayout="column"
      class="button"
    >
      <button (click)="goToSpec()">Get Cost Estimate</button>
    </div>
  </div>
</div>
