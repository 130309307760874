<form *ngIf="donation && form && !submitted && !donation.review" [formGroup]="form" fxLayout="column" fxFlex.gt-sm="100">
  <p>Please rate your experience with Resupply</p>
  <div
    class="form-row"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-sm="start start"
  >
    <star-rating
      [rating]="5"
      [starType]="'svg'"
      [hoverEnabled]="true"
      (ratingChange)="onRate($event)"
    ></star-rating>
  </div>
<!--  <div class="form-row" fxLayout="column" fxLayoutAlign="start start">-->
<!--    <textarea-->
<!--      required-->
<!--      type="text"-->
<!--      formControlName="comment"-->
<!--      placeholder="Add your review here."-->
<!--    ></textarea>-->
<!--  </div>-->
</form>
