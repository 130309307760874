import { autoserialize } from 'cerialize';

export class LeadUTM implements ILeadUTM {
  @autoserialize source?: string;
  @autoserialize medium?: string;
  @autoserialize campaign?: string;
  @autoserialize term?: string;
  @autoserialize content?: string;

  constructor(payload?: ILeadUTM) {
    this.source = payload?.source;
    this.medium = payload?.medium;
    this.campaign = payload?.campaign;
    this.term = payload?.term;
    this.content = payload?.content;
  }
}

export interface ILeadUTM {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
}
