import { autoserialize, autoserializeAs } from 'cerialize';
import * as moment from 'moment';
import { BaseDonationLead, IBaseDonationLead } from './base-donation-lead';
import { CallDetails, ICallDetails } from './call-details';
import { Charity, ICharity } from './charity';
import { Contact, IContact } from './contact';
import { Donation, IDonation } from './donation';
import { DonationCharityState } from './donation-charity-state';
import { DonationContent, IDonationContent } from './donation-content';
import { DonationDonorState } from './donation-donor-state';
import { DonationMeta, IDonationMeta } from './donation-meta';
import { DonationPartnerState } from './donation-partner-state';
import { DonationReview, IDonationReview } from './donation-review';
import { Fee } from './fee';
import { LeadChannel } from './lead-channel';
import { LeadUTM } from './lead-utm';
import { OwnerType } from './owner';
import { PartOfDay } from './part-of-day';
import { Partner } from './partner';
import { Specification } from './specification';
import { CharityStore, ICharityStore } from './store';
import { ITruck, Truck } from './truck';
import { IXmile, Xmile } from './xmile';

export class Lead extends BaseDonationLead implements ILead {
  @autoserializeAs('donation_id') donationId?: string;
  @autoserializeAs('donation') donation?: Donation;
  @autoserializeAs('call_details') callDetails?: CallDetails;
  @autoserializeAs('created_at') createdAt?: string;
  @autoserializeAs('updated_at') updatedAt?: string;
  @autoserializeAs('part_of_day') partOfDay?: PartOfDay;
  @autoserializeAs('dock_signature') dockSignature?: string;
  @autoserializeAs('donor_signature') donorSignature?: string;
  @autoserializeAs(Contact, 'dock_contact') dockContact?: Contact;
  @autoserializeAs(Specification, 'specification_description')
  specificationDescription?: string;
  @autoserializeAs('charity_id') charityId?: string | null;
  @autoserializeAs('store_id') storeId?: string;
  @autoserializeAs(Charity, 'charity') charity?: Charity;
  @autoserializeAs('partner_id') partnerId?: string | null;
  @autoserializeAs('partner_state') partnerState?: DonationPartnerState;
  @autoserializeAs('charity_state') charityState?: DonationCharityState;
  @autoserializeAs('donor_state') donorState?: DonationDonorState;
  @autoserializeAs('vehicle_id') vehicleId?: string;
  @autoserializeAs(Truck, 'vehicle') vehicle?: Truck;
  @autoserializeAs(CharityStore, 'store') store?: CharityStore;
  @autoserialize exception?: boolean;
  @autoserialize resolved?: boolean;
  @autoserializeAs('termination_reason') terminationReason?: string;
  @autoserializeAs('donation_description') donationDescription?: string;
  @autoserializeAs('accepted_content') acceptedContent?: DonationContent[];
  @autoserializeAs('donation_notes') donationNotes?: string;
  @autoserialize content?: DonationContent[];
  @autoserialize instructions?: string;
  @autoserializeAs('marketing_source') marketingSource?: string;
  @autoserialize review?: DonationReview;
  @autoserialize meta?: DonationMeta;
  @autoserializeAs('partner_comment') partnerComment?: string;
  @autoserializeAs('route_order') order?: number;
  @autoserializeAs('extra_mile_id') extraMileId?: string;
  @autoserializeAs('extra_mile') extraMile?: Xmile;
  @autoserialize photos?: string[];
  @autoserializeAs('expires_at') expiresAt?: string;
  @autoserialize utm?: LeadUTM;
  @autoserialize channel?: LeadChannel;
  @autoserializeAs('owner_type') ownerType?: OwnerType;
  @autoserializeAs('owner_id') ownerId?: string;

  constructor(payload: ILead) {
    super(payload);
    this.donation = payload?.donation ? new Donation(payload.donation) : undefined;
    this.donationId = payload?.donationId || payload?.donation?.id;
    this.callDetails = payload?.callDetails
      ? new CallDetails(payload.callDetails)
      : undefined;
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.partOfDay = payload?.partOfDay;
    this.dockSignature = payload?.dockSignature;
    this.donorSignature = payload?.donorSignature;
    this.donor = new Contact(payload?.donor);
    this.dockContact = payload?.dockContact
      ? new Contact(payload?.dockContact)
      : undefined;
    this.charity = payload?.charity ? new Charity(payload?.charity) : undefined;
    this.charityId = payload?.charityId?.toString() || this.charity?.id || null;
    this.partnerState = payload?.partnerState;
    this.partner = payload?.partner ? new Partner(payload?.partner) : undefined;
    this.partnerId = payload?.partnerId?.toString() || this?.partner?.id || null;
    this.donorState = payload?.donorState;
    this.vehicle = payload?.vehicle ? new Truck(payload.vehicle) : undefined;
    this.vehicleId = payload?.vehicleId?.toString() || this.vehicle?.id;
    this.charityState = payload?.charityState;
    this.exception = payload?.exception;
    this.terminationReason = payload?.terminationReason;
    this.store = payload?.store ? new CharityStore(payload?.store) : undefined;
    this.storeId = payload?.storeId?.toString() || this?.store?.id;
    this.donationDescription = payload?.donationDescription;
    this.content = payload?.content?.map((x) => new DonationContent(x));
    this.acceptedContent = payload?.acceptedContent?.map(
      (x) => new DonationContent(x)
    );
    this.specificationDescription = payload?.specificationDescription;
    this.donationNotes = payload?.donationNotes;
    this.instructions = payload?.instructions;
    this.marketingSource = payload?.marketingSource;
    this.resolved = payload?.resolved;
    this.review = payload?.review
      ? new DonationReview(payload?.review)
      : undefined;
    this.partnerComment = payload?.partnerComment;
    this.meta = new DonationMeta(payload?.meta);
    this.order = payload?.order;
    this.extraMile = payload?.extraMile
      ? new Xmile(payload.extraMile)
      : undefined;
    this.extraMileId = payload?.extraMileId?.toString() || this.extraMile?.id;
    this.photos = payload?.photos;
    this.fee = payload?.fee ? new Fee(payload?.fee) : undefined;
    this.expiresAt = payload?.expiresAt;
    this.utm = payload?.utm ? new LeadUTM(payload.utm) : undefined;
    this.channel = payload?.channel;
    this.ownerType = payload?.ownerType;
    this.ownerId = payload?.ownerId;
  }

  get isExpired(): boolean {
    return moment().isAfter(moment(this.expiresAt));
  }
}

export interface ILead extends IBaseDonationLead {
  donationId?: string;
  callDetails?: ICallDetails;
  createdAt?: string;
  updatedAt?: string;
  partOfDay?: PartOfDay;
  donation?: IDonation;
  dockSignature?: string;
  donorSignature?: string;
  dockContact?: IContact;
  charityId?: string | null;
  charity?: ICharity;
  partnerId?: string | null;
  partnerState?: DonationPartnerState;
  charityState?: DonationCharityState;
  donorState?: DonationDonorState;
  store?: ICharityStore;
  storeId?: string;
  vehicleId?: string;
  vehicle?: ITruck;
  exception?: boolean;
  terminationReason?: string;
  donationDescription?: string;
  acceptedContent?: IDonationContent[];
  content?: IDonationContent[];
  specificationDescription?: string;
  donationNotes?: string;
  instructions?: string;
  marketingSource?: string;
  resolved?: boolean;
  review?: IDonationReview;
  partnerComment?: string;
  meta?: IDonationMeta;
  order?: number;
  extraMileId?: string;
  extraMile?: IXmile;
  photos?: string[];
  expiresAt?: string;
  utm?: LeadUTM;
  channel?: LeadChannel;
  ownerType?: OwnerType;
  ownerId?: string;
}
