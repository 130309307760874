import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonActivity, Donation, PageActivity } from '@domains';
import { AppService } from '@donor/app.service';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss'],
})
export class EditInfoComponent extends Designable implements OnInit {
  donationCode: string;
  public form!: FormGroup<{
    donor: FormGroup<{
      name: FormControl<string>;
      email: FormControl<string>;
      phone: FormControl<string>;
    }>;
    address?: FormGroup<{
      state: FormControl<string>;
    }>;
    instructions: FormControl<string | null>;
    donationItems: FormControl<string | null>;
  }>;
  donation?: Donation;
  isSubmitting = false;
  queryParams: any;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          if (!this.service.canEditDonation(this.donation)) {
            this.router.navigate([`/i/${this.donationCode}`]);
          } else {
            this.service
              .createDonationActivity(
                this.donation?.id,
                PageActivity.EDIT_DONOR_INFO_PAGE
              )
              .subscribe();
            this.initForm();
            if (this.donation) this.form.patchValue(this.donation);
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        },
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      donor: new FormGroup({
        name: new FormControl(this.service.donation?.donor?.name || '', [
          Validators.required,
          Validators.pattern(/^[^\s]+.*$/),
        ]),
        email: new FormControl(this.service.donation?.donor?.email || '', [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
          ),
        ]),
        phone: new FormControl(this.service.donation?.donor?.phone || '', [
          Validators.required,
          Validators.pattern(/^([0-9]{10})$/),
        ]),
      }),
      instructions: new FormControl(this.service.donation?.instructions || ''),
      donationItems: new FormControl(
        this.service.donation?.donationItems || ''
      ),
    });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate([`/i`], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate([`/i/${this.donationCode}`]);
    }
  }

  onConfirm() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.EDIT_DONOR_INFO_SUBMIT
      )
      .subscribe();
    this.isSubmitting = true;
    this.service
      .updateDonationByCode(
        new Donation({
          ...(this.donation as Donation),
          ...this.form.getRawValue(),
        })
      )
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.snackBar.open(
            'Your information has been updated successfully!',
            'x',
            {
              duration: 5000,
              panelClass: 'success',
            }
          );
          this.back();
        },
        error: () => {
          this.service
            .createDonationActivity(
              this.donation?.id,
              ButtonActivity.EDIT_DONOR_INFO_SUBMIT_ERROR
            )
            .subscribe();
        },
      });
  }
}
