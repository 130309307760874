import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonActivity, PageActivity } from '@domains';
import { AppService } from '@donor/app.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-organization-landing',
  templateUrl: './organization-landing.component.html',
  styleUrls: ['./organization-landing.component.scss'],
})
export class OrganizationLandingComponent implements OnInit {
  @Input() leadId?: string;

  @Input() flowId!: string;
  constructor(
    private service: AppService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.service
      .createLeadActivity(this.leadId, PageActivity.GOODWILL_SHOP_LANDING_PAGE)
      .pipe(take(1))
      .subscribe();
  }

  goToSpec() {
    this.service
      .createLeadActivity(
        this.leadId,
        ButtonActivity.GOODWILL_SHOP_LANDING_PAGE_SUBMIT
      )
      .pipe(take(1))
      .subscribe();
    this.router.navigate(['/', 'spec', 'territory', this.flowId], {
      queryParams: this.route.snapshot.queryParams,
    });
  }
}
