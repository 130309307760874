import { autoserialize, autoserializeAs } from 'cerialize';

export class TaxReceiptSettings {
  @autoserializeAs('primary_color') primaryColor?: string;
  @autoserialize ein?: string;
  @autoserialize logo?: string;
  @autoserialize disclaimer?: string;

  constructor(pricingPayload?: ITaxReceiptSettings | null) {
    this.primaryColor = pricingPayload?.primaryColor;
    this.ein = pricingPayload?.ein;
    this.logo = pricingPayload?.logo;
    this.disclaimer = pricingPayload?.disclaimer;
  }
}

export interface ITaxReceiptSettings {
  primaryColor?: string;
  ein?: string;
  logo?: string;
  disclaimer?: string;
}
