<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <form *ngIf="form" [formGroup]="form">
    <div fxLayout="column" fxLayoutGap="0" fxLayoutGap.gt-sm="10px">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0">
        <div class="form-row" fxLayout="column" formGroupName="donor" *ngIf="name" fxFlex>
          <input type="text" #nameInput formControlName="name" placeholder="Full Name*"  [ngClass]="{invalid: name.invalid && name.touched}">
          <p class="hint error" *ngIf="name.invalid && name.touched && name.errors && name.errors['required']">Name is required</p>
          <p class="hint error" *ngIf="name.invalid && name.touched && name.errors && name.errors['pattern']">Name is invalid</p>
        </div>
        <div class="form-row" fxLayout="column" formGroupName="donor" *ngIf="email" fxFlex>
          <input type="email" formControlName="email" placeholder="Email Address*" [ngClass]="{invalid: email.invalid && email.touched}">
          <p class="hint error" *ngIf="email.invalid && email.touched && email.errors && email.errors['required']">Email is required</p>
          <p class="hint error" *ngIf="email.invalid && email.touched && email.errors && email.errors['pattern']">Email is invalid</p>
        </div>
        <div class="form-row" fxLayout="column" formGroupName="donor" *ngIf="phone" fxFlex>
          <input type="tel" formControlName="phone" placeholder="Cellphone Number*"
          [ngClass]="{invalid: phone.invalid && phone.touched}"
          mask="(000) 000 0000" [autocomplete]="false">
          <p class="hint error" *ngIf="phone.invalid && phone.touched && phone.errors && phone.errors['required']">Phone is required</p>
          <p class="hint error" *ngIf="phone.invalid && phone.touched && phone.errors && phone.errors['pattern']">Phone is invalid</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutGap.lt-md="0">
        <div class="form-row" fxLayout="column" fxFlex>
          <textarea formControlName="donationItems"></textarea>
          <p *ngIf="!hasDescription" class="placeholder"><span>Donation items description (Optional)</span> (i.e. "large Ethan
            Alan sectional sofa, working stainless steel refrigerator")</p>
        </div>
        <div class="form-row" fxLayout="column" fxFlex>
          <textarea formControlName="instructions"></textarea>
          <p *ngIf="!hasInstructions" class="placeholder"><span>Additional driver instructions (Optional)</span> (i.e. "Please
            use the elevator code 4356 to unlock the freight elevator")</p>
        </div>
      </div>
    </div>
  </form>
</ng-template>

