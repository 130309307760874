import { autoserialize } from 'cerialize';

export class StoreMeta {
  @autoserialize isStoreReady: boolean;

  constructor(payload?: IStoreMeta | null) {
    this.isStoreReady = !!payload?.isStoreReady;
  }
}

export interface IStoreMeta {
  isStoreReady?: boolean;
}
