import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorCode, PageActivity } from '@domains';
import { environment } from '@donor/../environments/environment';
import { AppService } from '@donor/app.service';
import { LocalStorageService } from '@rspl-api';
import { take } from 'rxjs/operators';
import { LEAD_FLOW, LEAD_ID, LEAD_ID_TIMESTAMP } from '../flow/donor-flow.service';

@Component({
  selector: 'app-created',
  templateUrl: './created.component.html',
  styleUrls: ['./created.component.scss'],
})
export class CreatedComponent implements OnInit {
  contactPhone = environment.supportNumber;
  leadId: string;
  tries = 0;
  donationCode: string;
  error: any;
  expired = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: AppService,
    private localStorage: LocalStorageService
  ) {
    this.leadId = this.route.snapshot.params['id'];
    this.donationCode = this.route.snapshot.params['code'];
  }

  ngOnInit(): void {
    if (this.leadId) {
      this.service
        .createLeadActivity(this.leadId, PageActivity.SPLASH_SCREEN_PAGE)
        .subscribe(() => {
          this.localStorage.removeItem(LEAD_ID);
          this.localStorage.removeItem(LEAD_FLOW);
          this.localStorage.removeItem(LEAD_ID_TIMESTAMP);
        });
      this.submit();
    }
  }

  submit() {
    this.service
      .submitDonation(this.leadId)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.router.navigate(['/', 'i', donation.donationCode], { queryParams: { origin: 'splash' } });
        },
        error: (error) => {
          if (this.tries < 5) {
            this.tries++;
            setTimeout(() => this.submit(), 1500);
          } else {
            this.error = error;
            if (this.error?.error?.code === ErrorCode.lead_expired_error) {
              this.expired = true;
            }
          }
        }
      });
  }
}
