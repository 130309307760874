export abstract class BaseDTO implements IBaseDTO {
  abstract id?: string;
  name?: string
  initialValue?: string;
  constructor(payload?: IBaseDTO) {
    this.initialValue = payload?.initialValue;
  }
  setInitialValue() {
    if (!this.initialValue) this.initialValue = JSON.stringify(this);
  }
  get isChanged(): boolean {
    const tmp = { ...this };
    delete tmp.initialValue;
    return (
      !!this.initialValue &&
      this.initialValue !== JSON.stringify(tmp)
    );
  }
  getChanges(): any {
    if (!this.initialValue) return {};
    const data: any = {};
    const initialObject = JSON.parse(this.initialValue);
    const tmp = { ...this } as any;
    delete tmp.initialValue;
    new Set([...Object.keys(tmp), ...Object.keys(initialObject)]).forEach((key) => {
      if (JSON.stringify(tmp[key]) !== JSON.stringify(initialObject[key])) {
        data[key] = tmp[key] === undefined ? null : tmp[key];
      }
    });
    return data;
  }
}
export interface IBaseDTO {
  initialValue?: string;
}