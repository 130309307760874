import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-already-paid',
  templateUrl: './already-paid.component.html',
  styleUrls: ['./already-paid.component.scss']
})
export class AlreadyPaidComponent {

  public constructor(
    public reference: MatDialogRef<AlreadyPaidComponent>,
    @Inject(MAT_DIALOG_DATA) public type: 'paid' | 'processing'
  ) {
  }

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }

  get isPaid(): boolean {
    return this.type === 'paid';
  }

}
