import {Component, OnInit} from '@angular/core';
import { HealthCheckService } from '@rspl-api';

@Component({
  selector: 'rspl-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit {

  public upAndRunning!: boolean;

  constructor(private service: HealthCheckService) {
  }

  ngOnInit(): void {
    this.service.healthCheck().subscribe({
      next: () => {
        this.upAndRunning = true;
      },
      error: () => {
        this.upAndRunning = false;
      }
    });
  }

}
