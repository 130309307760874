export enum OrganizationKind {
  Charity = 'Charity',
  MoveManager = 'MoveManager',
  Municipality = 'Municipality',
  Realtor = 'Realtor',
  Captain = 'Captain',
  SeniorLiving = 'SeniorLiving',
  HardwareStore = 'HardwareStore',
  Territory = 'Territory',
}
