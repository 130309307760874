import { autoserialize } from 'cerialize';

export class DayWorkingHours implements IDayWorkingHours {
  @autoserialize closed: boolean;
  @autoserialize opens: string;
  @autoserialize closes: string;

  constructor(
    payload?: IDayWorkingHours
  ) {
    this.closed = payload?.closed !== false;
    this.opens = payload?.opens || '9:00 AM';
    this.closes = payload?.closes || '5:00 PM';
  }
}

export interface IDayWorkingHours {
  closed: boolean;
  opens?: string;
  closes?: string;
}
