import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Deserialize, Serialize } from 'cerialize';
import { map, Observable } from 'rxjs';
import { BaseDTO, Schedule } from '@domains';
import { BaseApiService } from './base-api.service';
import { Environment, ENVIRONMENT } from '@domains';

export abstract class BaseScheduleApiService<T extends BaseDTO> extends BaseApiService<T> {
  constructor(
    @Inject(ENVIRONMENT) protected override config: Environment,
    protected override http: HttpClient,
    protected override path: string,
    protected override type: new (p?: any) => T,
    protected override historyTargetType?: string,
    protected override expandFilter: string[] = [],
    protected override expandFind: string[] = []
  ) {
    super(
      config,
      http,
      path,
      type,
      historyTargetType,
      expandFilter,
      expandFind
    );
  }

  createSchedule(parentId: string, schedule: Schedule): Observable<Schedule> {
    return this.http
      .post<Schedule>(
        this.config.urls.baseUrl + `/${this.path}/${parentId}/schedules`,
        Serialize(schedule, Schedule)
      )
      .pipe(
        map((response: Schedule) => {
          return new Schedule(Deserialize(response, Schedule));
        })
      );
  }

  updateSchedule(parentId: string, schedule: Schedule): Observable<Schedule> {
    return this.http
      .put<Schedule>(
        this.config.urls.baseUrl +
          `/${this.path}/${parentId}/schedules/${schedule.id}`,
        Serialize(schedule, Schedule)
      )
      .pipe(
        map((response: Schedule) => {
          return new Schedule(Deserialize(response, Schedule));
        })
      );
  }

  getSchedules(parentId: string, params: any): Observable<Schedule[]> {
    return this.http
      .get<Schedule[]>(
        this.config.urls.baseUrl + `/${this.path}/${parentId}/schedules`,
        { params }
      )
      .pipe(
        map((response: Schedule[]) => {
          return response.map((r) => new Schedule(Deserialize(r, Schedule)));
        })
      );
  }

  getSchedule(parentId: string, scheduleId: string): Observable<Schedule> {
    return this.http
      .get<Schedule>(
        this.config.urls.baseUrl +
          `/${this.path}/${parentId}/schedules/` +
          scheduleId
      )
      .pipe(
        map((response: any) => {
          return new Schedule(Deserialize(response, Schedule));
        })
      );
  }

  deleteSchedule(parentId: string, id: string): Observable<Schedule> {
    return this.http
      .delete<Schedule>(
        this.config.urls.baseUrl + `/${this.path}/${parentId}/schedules/` + id
      )
      .pipe(
        map((response: Schedule) => {
          return new Schedule(Deserialize(response, Schedule));
        })
      );
  }

  getScheduleZones(
    parentIds: string[],
    week: number,
    year: number,
    day: string
  ): Observable<
    Array<{
      id: number;
      monday: {
        am_zips: string[];
        pm_zips: string[];
      };
      resource_id: number;
      resource_type: string;
    }>
  > {
    return this.http
      .post<
        Array<{
          id: number;
          monday: {
            am_zips: string[];
            pm_zips: string[];
          };
          resource_id: number;
          resource_type: string;
        }>
      >(this.config.urls.baseUrl + `/schedules/zones`, {
        week,
        year,
        day,
        resource_id: parentIds,
        resource_type: [this.historyTargetType],
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
