import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rspl-input-audio',
  templateUrl: './input-audio.component.html',
  styleUrls: ['./input-audio.component.scss']
})
export class InputAudioComponent implements OnInit {
  showControls = false;
  @Input() audio?: string;
  @Input() isSuffix = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.showControls = !this.showControls;
  }

}
