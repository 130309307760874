import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { WindowSize } from '@domains'; // Import your WindowSize enum
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../responsive'; // Import your ResponsiveService

@Directive({
  selector: '[fxLayoutGap]',
})
export class FxLayoutGapDirective implements OnInit, OnDestroy {
  @Input('fxLayoutGap') defaultGap: string; // Default gap value
  @Input('fxLayoutGap.gt-lg') fxLayoutGapGtLg: string;
  @Input('fxLayoutGap.gt-md') fxLayoutGapGtMd: string;
  @Input('fxLayoutGap.gt-sm') fxLayoutGapGtSm: string;
  @Input('fxLayoutGap.gt-xs') fxLayoutGapGtXs: string;
  @Input('fxLayoutGap.lt-xl') fxLayoutGapLtXl: string;
  @Input('fxLayoutGap.lt-lg') fxLayoutGapLtLg: string;
  @Input('fxLayoutGap.lt-md') fxLayoutGapLtMd: string;
  @Input('fxLayoutGap.lt-sm') fxLayoutGapLtSm: string;
  @Input('fxLayoutGap.md') fxLayoutGapMd: string;
  @Input('fxLayoutGap.sm') fxLayoutGapSm: string;
  @Input('fxLayoutGap.lg') fxLayoutGapLg: string;
  @Input('fxLayoutGap.xl') fxLayoutGapXl: string;
  @Input('fxLayoutGap.xs') fxLayoutGapXs: string;

  private layoutChanges$: Subscription;

  constructor(
    private responsiveService: ResponsiveService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    // Subscribe to window size changes via ResponsiveService
    this.layoutChanges$ = this.responsiveService.size.subscribe((size) => {
      this.applyGap(size);
    });
  }

  ngOnDestroy() {
    if (this.layoutChanges$) {
      this.layoutChanges$.unsubscribe();
    }
  }

  private applyGap(size: WindowSize) {
    let gapValue = this.defaultGap;

    // Handle exact match breakpoints
    if (this.hasValue(this.fxLayoutGapXs) && size === WindowSize.XS) {
      gapValue = this.fxLayoutGapXs;
    } else if (this.hasValue(this.fxLayoutGapSm) && size === WindowSize.SM) {
      gapValue = this.fxLayoutGapSm;
    } else if (this.hasValue(this.fxLayoutGapMd) && size === WindowSize.MD) {
      gapValue = this.fxLayoutGapMd;
    } else if (this.hasValue(this.fxLayoutGapLg) && size === WindowSize.LG) {
      gapValue = this.fxLayoutGapLg;
    } else if (this.hasValue(this.fxLayoutGapXl) && size === WindowSize.XL) {
      gapValue = this.fxLayoutGapXl;
    }

    // Handle 'greater than' breakpoints (gt-*)
    if (this.hasValue(this.fxLayoutGapGtLg) && size === WindowSize.XL) {
      gapValue = this.fxLayoutGapGtLg;
    } else if (
      this.hasValue(this.fxLayoutGapGtMd) &&
      [WindowSize.XL, WindowSize.LG].includes(size)
    ) {
      gapValue = this.fxLayoutGapGtMd;
    } else if (
      this.hasValue(this.fxLayoutGapGtSm) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD].includes(size)
    ) {
      gapValue = this.fxLayoutGapGtSm;
    } else if (
      this.hasValue(this.fxLayoutGapGtXs) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD, WindowSize.SM].includes(
        size
      )
    ) {
      gapValue = this.fxLayoutGapGtXs;
    }

    // Handle 'less than' breakpoints (lt-*)
    if (this.hasValue(this.fxLayoutGapLtSm) && size === WindowSize.XS) {
      gapValue = this.fxLayoutGapLtSm;
    } else if (
      this.hasValue(this.fxLayoutGapLtMd) &&
      [WindowSize.XS, WindowSize.SM].includes(size)
    ) {
      gapValue = this.fxLayoutGapLtMd;
    } else if (
      this.hasValue(this.fxLayoutGapLtLg) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD].includes(size)
    ) {
      gapValue = this.fxLayoutGapLtLg;
    } else if (
      this.hasValue(this.fxLayoutGapLtXl) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD, WindowSize.LG].includes(size)
    ) {
      gapValue = this.fxLayoutGapLtXl;
    }

    this.renderer.setStyle(this.el.nativeElement, 'gap', gapValue);

  }

  private hasValue(value: string): boolean {
    return value !== undefined && value !== null;
  }
}
