import { Component, Input, OnInit } from '@angular/core';
import { ButtonActivity, Donation, DonationMeta } from '@domains';
import { AppService } from '@donor/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-eta',
  templateUrl: './eta.component.html',
  styleUrls: ['./eta.component.scss'],
})
export class EtaComponent implements OnInit {
  @Input() donation!: Donation;
  isSubmitting: boolean = false;

  constructor(private service: AppService) {}

  ngOnInit(): void {}

  get isSameDay(): boolean {
    return moment().format('YYYY-MM-DD') === this.donation?.date;
  }

  requestETA(): void {
    this.isSubmitting = true;
    this.service
      .createDonationActivity(this.donation?.id, ButtonActivity.REQUEST_ETA)
      .subscribe();
    this.service
      .updateDonationByCode(
        new Donation({
          ...(this.donation as Donation),
          requestEta: true,
          meta: new DonationMeta({
            ...this.donation.meta,
            etaRequestedAt: new Date().toISOString(),
          }),

        })
      )
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.isSubmitting = false;
        },
        error: (err) => {
          this.isSubmitting = false;
        },
      });
  }
}
