import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@donor/app.service';
import { Donation, Pricing } from '@domains';
import { take } from 'rxjs/operators';
import { environment } from '@donor/../environments/environment';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  donationCode: string;
  donation?: Donation;
  taxReceiptUrl = environment.urls.taxReceiptUrl || '';

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.donationCode = this.route.snapshot.params['code'];
  }

  ngOnInit(): void {
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        }
      });
  }
}
