import { autoserialize, autoserializeAs } from 'cerialize';
import { AuthorizationModel, IAuthorizationModel } from './authorization.model';
import { IUserMeta, UserMeta } from './user-meta';
import { IUserPermissions, UserPermissions } from './user-permissions';

export class AuthenticationModel {
  @autoserialize id: string;
  @autoserialize identity?: string;
  @autoserialize name?: string;
  @autoserialize email?: string;
  @autoserialize phone?: string;
  @autoserializeAs('charity_id') charityId?: string;
  @autoserializeAs('partner_id') partnerId?: string;
  @autoserializeAs('store_id') storeId?: string;
  @autoserializeAs(AuthorizationModel) authorization: AuthorizationModel;
  @autoserialize meta?: UserMeta;
  @autoserialize permissions?: UserPermissions;

  constructor(payload: IAuthenticationModel) {
    this.id = payload?.id?.toString();
    this.authorization = new AuthorizationModel(payload?.authorization);
    this.identity = payload?.identity;
    this.name = payload?.name;
    this.email = payload?.email;
    this.phone = payload?.phone;
    this.charityId = payload?.charityId?.toString();
    this.partnerId = payload?.partnerId?.toString();
    this.storeId = payload?.storeId?.toString();
    this.meta = new UserMeta(payload?.meta);
    this.permissions = new UserPermissions(payload?.permissions);
  }
}

export interface IAuthenticationModel {
  id: string;
  authorization: IAuthorizationModel;
  identity?: string;
  name?: string;
  email?: string;
  phone?: string;
  charityId?: string;
  storeId?: string;
  partnerId?: string;
  meta?: IUserMeta;
  permissions?: IUserPermissions;
}
