import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { WindowSize } from '@domains'; // Import your WindowSize enum
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../responsive'; // Import your ResponsiveService

@Directive({
  selector: '[fxLayout]',
})
export class FxLayoutDirective implements OnInit, OnDestroy {
  @Input('fxLayout') defaultLayout: string; // Default layout (row, column, etc.)
  @Input('fxLayout.gt-lg') fxLayoutGtLg: string;
  @Input('fxLayout.gt-md') fxLayoutGtMd: string;
  @Input('fxLayout.gt-sm') fxLayoutGtSm: string;
  @Input('fxLayout.gt-xs') fxLayoutGtXs: string;
  @Input('fxLayout.lt-xl') fxLayoutLtXl: string;
  @Input('fxLayout.lt-lg') fxLayoutLtLg: string;
  @Input('fxLayout.lt-md') fxLayoutLtMd: string;
  @Input('fxLayout.lt-sm') fxLayoutLtSm: string;
  @Input('fxLayout.md') fxLayoutMd: string;
  @Input('fxLayout.sm') fxLayoutSm: string;
  @Input('fxLayout.lg') fxLayoutLg: string;
  @Input('fxLayout.xl') fxLayoutXl: string;
  @Input('fxLayout.xs') fxLayoutXs: string;
  @Input('fxPosition') fxPosition:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'static'
    | 'sticky'
    | 'inherit'
    | 'initial' = 'relative';

  private layoutChanges$: Subscription;

  @HostBinding('style.display') display = 'flex'; // Always display as flex
  @HostBinding('style.flex-direction') layoutDirection: string; // Flex direction (row, column, etc.)

  constructor(
    private responsiveService: ResponsiveService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // Subscribe to window size changes via ResponsiveService
    this.layoutChanges$ = this.responsiveService.size.subscribe((size) => {
      this.applyLayout(size);
    });
  }

  ngOnDestroy() {
    if (this.layoutChanges$) {
      this.layoutChanges$.unsubscribe();
    }
  }

  private applyLayout(size: WindowSize) {
    this.layoutDirection = this.defaultLayout;

    // Handle exact match breakpoints
    if (this.hasValue(this.fxLayoutXs) && size === WindowSize.XS) {
      this.layoutDirection = this.fxLayoutXs;
    } else if (this.hasValue(this.fxLayoutSm) && size === WindowSize.SM) {
      this.layoutDirection = this.fxLayoutSm;
    } else if (this.hasValue(this.fxLayoutMd) && size === WindowSize.MD) {
      this.layoutDirection = this.fxLayoutMd;
    } else if (this.hasValue(this.fxLayoutLg) && size === WindowSize.LG) {
      this.layoutDirection = this.fxLayoutLg;
    } else if (this.hasValue(this.fxLayoutXl) && size === WindowSize.XL) {
      this.layoutDirection = this.fxLayoutXl;
    }

    // Handle 'greater than' breakpoints (gt-*)
    if (this.hasValue(this.fxLayoutGtLg) && size === WindowSize.XL) {
      this.layoutDirection = this.fxLayoutGtLg;
    } else if (
      this.hasValue(this.fxLayoutGtMd) &&
      [WindowSize.XL, WindowSize.LG].includes(size)
    ) {
      this.layoutDirection = this.fxLayoutGtMd;
    } else if (
      this.hasValue(this.fxLayoutGtSm) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD].includes(size)
    ) {
      this.layoutDirection = this.fxLayoutGtSm;
    } else if (
      this.hasValue(this.fxLayoutGtXs) &&
      [WindowSize.XL, WindowSize.LG, WindowSize.MD, WindowSize.SM].includes(
        size
      )
    ) {
      this.layoutDirection = this.fxLayoutGtXs;
    }

    // Handle 'less than' breakpoints (lt-*)
    if (this.hasValue(this.fxLayoutLtSm) && size === WindowSize.XS) {
      this.layoutDirection = this.fxLayoutLtSm;
    } else if (
      this.hasValue(this.fxLayoutLtMd) &&
      [WindowSize.XS, WindowSize.SM].includes(size)
    ) {
      this.layoutDirection = this.fxLayoutLtMd;
    } else if (
      this.hasValue(this.fxLayoutLtLg) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD].includes(size)
    ) {
      this.layoutDirection = this.fxLayoutLtLg;
    } else if (
      this.hasValue(this.fxLayoutLtXl) &&
      [WindowSize.XS, WindowSize.SM, WindowSize.MD, WindowSize.LG].includes(
        size
      )
    ) {
      this.layoutDirection = this.fxLayoutLtXl;
    }

    // Apply the flex direction to the element
    if (this.layoutDirection.includes('wrap')) {
      this.layoutDirection.replace('wrap', '');
      this.renderer.setStyle(this.el.nativeElement, 'flex-flow', 'wrap');
    }
    this.renderer.setStyle(
      this.el.nativeElement,
      'flex-direction',
      this.layoutDirection.trim()
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'position',
      this.fxPosition.trim()
    );
  }

  private hasValue(value: string): boolean {
    return value !== undefined && value !== null;
  }
}
