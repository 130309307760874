import { autoserialize } from 'cerialize';

export class ScreeningMeta implements IScreeningMeta {
  @autoserialize notes?: string;

  constructor(payload?: IScreeningMeta) {
    this.notes = payload?.notes;
  }
}

export interface IScreeningMeta {
  notes?: string;
}
