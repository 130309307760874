import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MapService } from 'ngx-mapbox-gl';
import { RSPL_VERSION_CONFIG, RsplVersionConfig } from './rspl-version-config';
import { VersionWarningComponent } from './version-check';
import { VersionCheckInterceptor } from './version-check/version-check.interceptor';

@NgModule({ declarations: [VersionWarningComponent],
    exports: [], imports: [CommonModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class RsplVersionModule {
  public static forRoot(
    config: RsplVersionConfig
  ): ModuleWithProviders<RsplVersionModule> {
    return {
      ngModule: RsplVersionModule,
      providers: [
        RsplVersionModule,
        MapService,
        { provide: RSPL_VERSION_CONFIG, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: VersionCheckInterceptor,
          multi: true,
        },
      ],
    };
  }
}
