import { autoserialize } from 'cerialize';

export class Specification {
  @autoserialize small: number;
  @autoserialize medium: number;
  @autoserialize large: number;
  @autoserialize xlarge: number;
  @autoserialize recycling: number;
  @autoserialize staircases: number;
  @autoserialize elevator: number;
  @autoserialize disassembly: number;

  constructor(specificationPayload?: ISpecification) {
    this.small = specificationPayload?.small || 0;
    this.medium = specificationPayload?.medium || 0;
    this.large = specificationPayload?.large || 0;
    this.xlarge = specificationPayload?.xlarge || 0;
    this.staircases = specificationPayload?.staircases || 0;
    this.elevator = specificationPayload?.elevator || 0;
    this.disassembly = specificationPayload?.disassembly || 0;
    this.recycling = specificationPayload?.recycling || 0;
  }

  isEmpty(): boolean {
    return (
      this.small === 0 &&
      this.medium === 0 &&
      this.large === 0 &&
      this.xlarge === 0
    );
  }

  isEmptyBig(): boolean {
    return (
      this.medium === 0 &&
      this.large === 0 &&
      this.xlarge === 0
    );
  }

  isSameAs(spec: Specification) {
    return (
      this.small === spec.small &&
      this.medium === spec.medium &&
      this.large === spec.large &&
      this.xlarge === spec.xlarge &&
      this.staircases === spec.staircases &&
      this.elevator === spec.elevator &&
      this.disassembly === spec.disassembly &&
      this.recycling === spec.recycling
    );
  }
}

export interface ISpecification {
  small?: number;
  medium?: number;
  large?: number;
  xlarge?: number;
  staircases?: number;
  elevator?: number;
  disassembly?: number;
  recycling?: number;
}
