import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Address,
  ButtonActivity,
  DonationMeta,
  ENVIRONMENT,
  Environment,
  InputActivity,
  Lead,
  PageActivity,
  PartOfDay,
} from '@domains';
import { AppService } from '@donor/app.service';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowService } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-flow-place-time',
  templateUrl: './flow-place-time.component.html',
  styleUrls: ['./flow-place-time.component.scss'],
})
export class FlowPlaceTimeComponent
  extends BaseFlowStepComponent
  implements OnInit
{
  partsOfDay = PartOfDay;
  selectedDate?: {
    date?: string;
    partOfDay?: PartOfDay;
  };
  address: Address;
  manualAddress = false;

  override onSaveSuccess = () => {
    this.flowService
      .assignCharityToLead(this.lead?.id)
      .pipe(take(1))
      .subscribe();
    return true;
  };

  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    protected override optimizelyService: OptimizelyService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      optimizelyService,
      environment
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.selectedDate = {
      date: this.flowService.lead.date,
      partOfDay: this.flowService.lead.partOfDay,
    };
    this.address = new Address(this.flowService.lead.address);
  }

  static override isCompleted(lead: Lead) {
    return !!lead.date && !!lead.partOfDay && lead.address.isValid;
  }

  override isValid(): boolean {
    return (
      !!this.selectedDate &&
      !!this.selectedDate.date &&
      !!this.selectedDate.partOfDay &&
      this.address.isValid
    );
  }

  override getFromValue(): Partial<Lead> {
    return {
      date: this.selectedDate.date,
      partOfDay: this.selectedDate.partOfDay,
      address: this.address,
      meta: new DonationMeta({
        ...this.flowService.lead.meta,
        manualAddress: this.manualAddress,
      }),
    };
  }

  override showError(): void {
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.AVAILABILITY_SUBMIT_ERROR,
      )
      .pipe(take(1))
      .subscribe();
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.AVAILABILITY_SUBMIT;
  }

  override pageVisitActivity(): PageActivity {
    return PageActivity.AVAILABILITY_PAGE;
  }

  onDateSelected(e: { date?: string; partOfDay?: PartOfDay }): void {
    if (
      e.date &&
      e.partOfDay &&
      e.date !== this.selectedDate?.date &&
      e.partOfDay !== this.selectedDate?.partOfDay
    ) {
      this.appService
        .createLeadActivity(
          this.lead?.id,
          InputActivity.DATE_SELECTED,
          `${e.date} ${e.partOfDay}`,
        )
        .subscribe();
    }
    this.selectedDate = e;
    this.showErrors = !this.selectedDate.date || !this.selectedDate.partOfDay;
  }

  onAddressChanged(address?: Address, manual?: boolean): void {
    this.address = new Address({
      ...address,
    });
    this.manualAddress = !!manual;
    this.flowService.paymentIntent = undefined;
    this.appService
      .createLeadActivity(
        this.leadId,
        address.isValid
          ? InputActivity.ADDRESS_ENTER
          : InputActivity.ADDRESS_ENTER_ERROR,
        this.address.fullAddress,
      )
      .subscribe();
    if (this.manualAddress) {
      this.appService
        .createLeadActivity(
          this.leadId,
          InputActivity.ADDRESS_MANUAL,
          this.address.fullAddress,
        )
        .subscribe();
    }
  }

  onAddressError(address?: string): void {
    this.appService
      .createLeadActivity(
        this.leadId,
        InputActivity.ADDRESS_ENTER_ERROR,
        address,
      )
      .subscribe();
  }

  onAddressPopupOpened(): void {
    this.appService
      .createLeadActivity(
        this.leadId,
        ButtonActivity.ADDRESS_ATTEMPT,
      )
      .subscribe();
  }
}
