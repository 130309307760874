import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  ENVIRONMENT,
  Environment,
  FlowTypes,
  Lead,
  PageActivity,
} from '@domains';
import { AppService } from '@donor/app.service';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowService, SPLIT_SCREEN_VISITED } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import { LocalStorageService } from '@rspl-api';
import { DesignService, ResponsiveService } from '@rspl-ui';

@Component({
  selector: 'app-flow-split-screen',
  templateUrl: './flow-split-screen.component.html',
  styleUrls: ['./flow-split-screen.component.scss'],
})
export class FlowSplitScreenComponent
  extends BaseFlowStepComponent
  implements OnInit
{
  flowTypes = FlowTypes;
  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    protected override optimizelyService: OptimizelyService,
    @Inject(ENVIRONMENT) protected override environment: Environment,
    private localStorage: LocalStorageService
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      optimizelyService,
      environment
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.localStorage.setItem(SPLIT_SCREEN_VISITED, 'true');
  }

  static override isCompleted(lead: Lead) {
    return !!localStorage.getItem(SPLIT_SCREEN_VISITED);
  }

  override isValid(): boolean {
    throw new Error('Method not implemented.');
  }

  override getFromValue(): Partial<Lead> {
    throw new Error('Method not implemented.');
  }

  override showError(): void {
    throw new Error('Method not implemented.');
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.SCREENING_PRIORITY;
  }

  override pageVisitActivity(): PageActivity {
    return PageActivity.SPLIT_LANDING_PAGE;
  }

  hideSplitScreen(
    res: { disassembly: boolean; stairs: boolean } = {
      disassembly: false,
      stairs: false,
    }
  ) {
    this.router.navigate(['/', 'spec', this.flowType, this.flowId], {
      queryParams: { ...this.route.snapshot.queryParams, ...res },
    });
  }
}
