<div class="container" fxLayout="column">
  <h1>Donations List</h1>
  <p class="text-center" style="padding: 0 20px">This is the list of donation requests associated with your email or phone number:</p>
  <mat-accordion fxFlex="100">
    <mat-expansion-panel *ngFor="let code of codes">
      <mat-expansion-panel-header>
        <mat-panel-title fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column">
            <label>Donation Code:</label>
            <span class="value">{{ code }}</span>
          </div>
          <div fxLayout="column">
            <label>Donation Date:</label>
            <span class="value">{{state?.donation?.date | date: "MMM d, y"}}</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-state #state [donationCode]="code" [page]="false"></app-state>
    </mat-expansion-panel>
  </mat-accordion>
</div>
