import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Donation } from '@domains';
import { AppService } from '@donor/app.service';
import { DesignService, Designable, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-eta-screen',
  templateUrl: './eta-screen.component.html',
  styleUrls: ['./eta-screen.component.scss'],
})
export class EtaScreenComponent extends Designable implements OnInit {
  donationCode: string;
  queryParams: any;
  donation?: Donation;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    designService: DesignService,
    responsiveService: ResponsiveService,
    private service: AppService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Donation <b>${this.donationCode}</b> could not be found!`,
              url: window.location.href,
            },
          });
        },
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate([`/i`], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate([`/i/${this.donationCode}`]);
    }
  }
}
