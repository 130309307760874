import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { DonationsService } from '@rspl-api';
import { Design } from '@domains';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs/operators';

export function optionalValidator(
  validators: (ValidatorFn | null | undefined)[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || (control.value as string).length === 0) {
      return null;
    }
    const validator = Validators.compose(validators);
    return validator ? validator(control) : null;
  };
}

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
})
export class LookupComponent extends Designable {
  form: FormGroup<{
    email_or_phone: FormControl<string | null>;
    last4: FormControl<string | null>;
  }>;
  message?: string;
  success = false;
  knowCode: boolean | undefined;

  constructor(
    private donationsService: DonationsService,
    private router: Router,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    designService.setDesign(Design.DESIGN_2);
    this.form = new FormGroup({
      email_or_phone: new FormControl<string | null>(null, [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$|^([0-9]{10})$/
        ),
      ]),
      last4: new FormControl(
        '',
        optionalValidator([Validators.minLength(4), Validators.maxLength(4)])
      ),
    });
  }

  get email_or_phone(): FormControl<string | null> {
    return this.form.get('email_or_phone') as FormControl<string | null>;
  }

  get last4(): FormControl<string | null> {
    return this.form.get('last4') as FormControl<string | null>;
  }

  lookup() {
    this.message = undefined;
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.donationsService
      .findDonorDonations(this.email_or_phone.value, this.last4.value)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (this.last4.value) {
            if (res.code) {
              this.router.navigate(['/', 'i', res.code], { queryParams: { origin: 'lookup' } });
            } else {
              this.success = false;
              this.message = "We couldn't find your donation!";
            }
          } else {
            this.success = true;
            const pattern = new RegExp(
              /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            );
            if (
              this.email_or_phone.value &&
              pattern.test(this.email_or_phone.value)
            ) {
              this.message =
                'The list of donation requests associated with your email is sent. Please check your Email';
            } else {
              this.message =
                'The list of donation requests associated with your phone is sent. Please check your SMS';
            }
          }
        },
        error: (err) => {
          this.success = false;
          this.message = "We couldn't find your donation!";
        },
      });
  }
}
