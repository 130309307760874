import {Component, OnInit} from '@angular/core';
import { Design } from '@domains';
import { DesignService } from '@rspl-ui';

@Component({
  selector: 'app-fail',
  templateUrl: './fail.component.html',
  styleUrls: ['./fail.component.scss']
})
export class FailComponent implements OnInit {

  constructor(
    designService: DesignService
  ) {
    designService.setDesign(Design.DESIGN_2);
  }

  ngOnInit(): void {
  }

}
