import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import {
  ButtonActivity,
  Donation,
  DonationStateByCodeDTO,
  PageActivity,
  PartOfDay,
} from '@domains';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss'],
})
export class RescheduleComponent extends Designable implements OnInit {
  donationCode: string;
  donation?: Donation;
  selectedDate: {
    date?: string;
    partOfDay?: PartOfDay;
  } = {};
  isSubmitting = false;
  queryParams;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe((donation) => {
        this.donation = donation;
        if (!this.service.canEditDonation(this.donation)) {
          this.router.navigate([`/i/${this.donationCode}`]);
        }
        this.service
          .createDonationActivity(
            this.donation?.id,
            PageActivity.RESCHEDULE_DONATION_PAGE
          )
          .subscribe();
        this.selectedDate = {
          date: donation.date,
          partOfDay: donation.partOfDay,
        };
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate([`/i`], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate([`/i/${this.donationCode}`]);
    }
  }

  onDateSelected(e: { date?: string; partOfDay?: PartOfDay }): void {
    this.selectedDate = e;
  }

  confirm() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.RESCHEDULE_DONATION_SUBMIT
      )
      .subscribe();
    this.isSubmitting = true;
    if (this.selectedDate?.date && this.selectedDate?.partOfDay) {
      this.donationsService
        .updateDonationStateByCode(
          this.donationCode,
          new DonationStateByCodeDTO({
            stateAction: 'reschedule',
            date: this.selectedDate.date,
            partOfDay: this.selectedDate.partOfDay,
          })
        )
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.router.navigate([
              `/i/${this.donationCode}/reschedule/success`,
            ]);
          },
          error: () => {
            this.isSubmitting = false;
            this.service
              .createDonationActivity(
                this.donation?.id,
                ButtonActivity.RESCHEDULE_DONATION_SUBMIT_ERROR
              )
              .subscribe();
          },
        });
    }
  }
}
