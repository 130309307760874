<app-split-screen
  *ngIf="flowType === flowTypes.CHARITY"
  [leadId]="lead?.id"
  [charity]="charity"
  [screening]="charity.screening"
  (close)="hideSplitScreen($event)"
></app-split-screen>
<app-organization-landing
  *ngIf="
    leadId &&
    flowType === flowTypes.TERRITORY
  "
  [flowId]="flowId"
  [leadId]="leadId"
></app-organization-landing>
