export enum DonationDonorState {
  submitted = 'submitted',
  rescheduled = 'rescheduled',
  confirmed = 'confirmed',
  canceled = 'canceled',
  quote_accepted = 'quote_accepted',
  quote_declined = 'quote_declined',
  completed = 'completed',
}

export const DONOR_STATES = [
  {
    value: 'submitted',
    viewValue: 'Submitted',
  },
  {
    value: 'rescheduled',
    viewValue: 'Rescheduled',
  },
  {
    value: 'confirmed',
    viewValue: 'Confirmed',
  },
  {
    value: 'canceled',
    viewValue: 'Canceled',
  },
  {
    value: 'quote_accepted',
    viewValue: 'Quote Accepted',
  },
  {
    value: 'quote_declined',
    viewValue: 'Quote Declined',
  },
];