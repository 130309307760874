import { autoserialize } from 'cerialize';

export class ItemMeta {
  @autoserialize createdByCaptain: boolean;

  constructor(payload?: IItemMeta) {
    this.createdByCaptain = !!payload?.createdByCaptain;
  }
}

export interface IItemMeta {
  createdByCaptain?: boolean;
}
