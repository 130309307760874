import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ButtonActivity,
    Donation,
    PageActivity,
    Pricing,
    Specification
} from '@domains';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-spec',
  templateUrl: './edit-spec.component.html',
  styleUrls: ['./edit-spec.component.scss'],
})
export class EditSpecComponent extends Designable implements OnInit {
  donationCode: string;
  donation?: Donation;
  isSubmitting = false;
  public totalCostEstimate?: number;
  public specification: Specification = new Specification();
  queryParams: any;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.queryParams = this.route.snapshot.queryParams;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          if (!this.service.canEditDonation(this.donation)) {
            this.router.navigate([`/i/${this.donationCode}`]);
          } else {
            this.service
              .createDonationActivity(
                this.donation?.id,
                PageActivity.EDIT_DONATION_SPEC_PAGE
              )
              .subscribe();
            this.onSpecificationChange(this.donation?.specification);
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Donation <b>${this.donationCode}</b> could not be found!`, url: window.location.href }});
        }
      });
  }

  back() {
    if (Object.keys(this.queryParams).length) {
      this.router.navigate([`/i`], {
        queryParams: this.queryParams,
      });
    } else {
      this.router.navigate([`/i/${this.donationCode}`]);
    }
  }

  onConfirm() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.EDIT_DONATION_SPEC_SUBMIT
      )
      .subscribe();
    this.isSubmitting = true;
    this.service
      .updateDonationByCode(
        new Donation({
          ...(this.donation as Donation),
          specification: this.specification,
        })
      )
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.snackBar.open(
            "The items you'll be donating have been updated successfully!",
            'x',
            {
              duration: 5000,
              panelClass: 'success',
            }
          );
          this.back();
        },
        error: () => {
          this.service
            .createDonationActivity(
              this.donation?.id,
              ButtonActivity.EDIT_DONATION_SPEC_SUBMIT_ERROR
            )
            .subscribe();
        },
      });
  }

  onSpecificationChange(e: any): void {
    Object.assign(this.specification || {}, e);
    this.specification = new Specification({ ...this.specification });
    this.getTotalPrice();
  }

  public getTotalPrice(): void {
    if (this.specification && this.donation?.pricing?.base) {
      this.totalCostEstimate = Pricing.formatPrice(
        Pricing.getTotalBasePrice(this.specification, this.donation.pricing)
      );
    }
  }
}
