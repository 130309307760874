import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ButtonActivity, ENVIRONMENT, Environment } from '@domains';
import {
  PaymentIntentResult,
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  SetupIntentResult,
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import {
  StripePaymentElementComponent,
  StripePaymentRequestButtonComponent,
  StripeService,
} from 'ngx-stripe';
import { Observable, finalize, map, take } from 'rxjs';

@Component({
  selector: 'rspl-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  payment!: StripePaymentElementComponent;
  @ViewChild(StripePaymentRequestButtonComponent)
  button?: StripePaymentRequestButtonComponent;
  @Output() onReady = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Output() onDisabledButtonsClicked = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onPaymentSetupSuccess = new EventEmitter<ButtonActivity>();
  @Input() type: 'setup' | 'payment' = 'setup';
  @Input() disableButtons = false;
  @Input() paymentIntent?: {
    client_secret: string;
    booking_fee?: boolean;
    stripe_account?: string;
    payment_method?: {
      card?: {
        brand?: string;
        exp_month?: number;
        exp_year?: number;
        last4?: string;
      };
      wallet?: {
        apple_pay?: any;
        google_pay?: any;
      };
    };
  };
  @Input() newCard = false;
  @Input() showAppleGoogle = true;

  stripeKey: string;
  isSubmitting = false;
  isPaymentElementLoaded = false;
  isButtonElementLoaded = false;

  paymentButtonOptions?: PaymentRequestOptions;

  @Input() set total(total: { label: string; amount: number }) {
    this.paymentButtonOptions = {
      country: 'US',
      currency: 'usd',
      total: {
        label: total.label,
        amount: Number.parseInt(total.amount.toFixed(2)),
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link', 'browserCard'],
    };
  }

  paymentOptions: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
    wallets: {
      applePay: 'never',
      googlePay: 'never',
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
      variables: {
        colorBackground: '#f6f7f9',
        colorText: '#7b7b7b',
        borderRadius: '0px',
        colorPrimary: '#0261ad',
      },
      rules: {
        '.Input': {
          border: '1px solid rgba(0,0,0,.12)',
          backgroundColor: '#ffffff',
        },
        '.TabIcon--selected': {
          color: '#0261ad',
        },
        '.AccordionItem--selected': {
          color: '#0261ad',
        },
      },
    },
  };

  constructor(
    private stripeService: StripeService,
    @Inject(ENVIRONMENT) private environment: Environment) {
      this.stripeKey = this.environment.stripeKey;
    }

  ngOnInit(): void {}

  get isLoaded(): boolean {
    return (!this.showAppleGoogle || this.isButtonElementLoaded) && this.isPaymentElementLoaded;
  }

  onButtonPayment(ev: PaymentRequestPaymentMethodEvent) {
    this.isSubmitting = true;
    if (!this.clientSecret) return;

    this.onPaymentSetupSuccess.emit(
      ev.walletName === 'applePay'
        ? ButtonActivity.PAYMENT_SETUP_SUCCESS_APPLE
        : ButtonActivity.PAYMENT_SETUP_SUCCESS_GOOGLE
    );
    const req: Observable<
      | PaymentIntentResult
      | SetupIntentResult
      | {
          error: Error;
        }
    > =
      this.type === 'setup'
        ? this.confirmSetupButton(ev, this.clientSecret)
        : this.confirmPaymentButton(ev, this.clientSecret);
    req
      .pipe(
        map((confirmResult) => {
          if (confirmResult.error) {
            console.debug(confirmResult);
            ev.complete('fail');
            return {
              error: new Error('Error Confirming the payment'),
            };
          } else {
            ev.complete('success');
            return { clientSecret: this.clientSecret };
          }
        }),
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe((res) => {
        if (res?.error) {
          this.onError.next(res.error.message);
        } else {
          this.onSuccess.emit(res.clientSecret);
        }
      });
  }

  onPayment() {
    this.isSubmitting = true;

    if (
      !this.newCard &&
      (this.paymentIntent?.payment_method?.card ||
        this.paymentIntent?.payment_method?.wallet)
    ) {
      this.confirmCardPayment();
      return;
    }

    const req: Observable<
      | PaymentIntentResult
      | SetupIntentResult
      | {
          error: Error;
        }
    > = this.type === 'setup' ? this.confirmSetup() : this.confirmPayment();
    req
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe((res) => {
        if (res?.error) {
          console.debug(res.error);
          this.onError.next(res.error.message);
        } else if ((res as any).paymentIntent || (res as any).setupIntent) {
          this.onSuccess.emit(res);
        }
      });
  }

  confirmCardPayment() {
    if (!this.paymentIntent) return;
    this.stripeService
      .confirmCardPayment(this.paymentIntent.client_secret)
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe((res) => {
        if (res.paymentIntent) {
          console.debug(res.error);
          this.onSuccess.emit(res);
        } else if (res.error) {
          this.onError.next(res.error.message);
        }
      });
  }

  protected cardReady() {
    this.isPaymentElementLoaded = true;
    if (this.isButtonElementLoaded || !this.showAppleGoogle) this.onReady.emit();
  }

  protected buttonReady() {
    this.isButtonElementLoaded = true;
    if (this.button?.paymentRequest) {
      this.button.canMakePayment().subscribe((res) => {
        if (
          res &&
          (res['applePay'] || res['googlePay']) &&
          this.button &&
          !this.button.isShowing
        ) {
          this.button.show();
        }
      });
    }
    if (this.isPaymentElementLoaded) this.onReady.emit();
  }

  private confirmPaymentButton(
    ev: PaymentRequestPaymentMethodEvent,
    clientSecret: string
  ) {
    return this.stripeService.confirmCardPayment(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    );
  }

  private confirmSetupButton(
    ev: PaymentRequestPaymentMethodEvent,
    clientSecret: string
  ) {
    return this.stripeService.confirmCardSetup(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    );
  }

  private confirmPayment() {
    return this.stripeService.confirmPayment({
      elements: this.payment.elements,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            address: {
              country: 'US',
            },
          },
        },
      },
      redirect: 'if_required',
    });
  }

  private confirmSetup() {
    return this.stripeService.confirmSetup({
      elements: this.payment.elements,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            address: {
              country: 'US',
            },
          },
        },
      },
      redirect: 'if_required',
    });
  }

  get clientSecret(): string | undefined {
    return this.paymentIntent?.client_secret;
  }

  disabledButtonsClicked() {
    this.onDisabledButtonsClicked.emit();
  }
}
