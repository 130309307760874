import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, Environment } from '@domains';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  readonly key = CryptoJS.enc.Utf8.parse('bL53y#KSml$S7WKF');
  constructor(@Inject(ENVIRONMENT) private config: Environment) {}

  getItem(key: string): string | null {
    const value = localStorage.getItem(key);
    if (value && this.config.production) return this.decrypt(value);
    return value;
  }

  setItem(key: string, value: string) {
    localStorage.setItem(
      key,
      this.config.production ? this.encrypt(value) : value
    );
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  private encrypt(value: string) {
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value?.toString()),
      this.key,
      {
        keySize: 128 / 8,
        iv: this.key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted?.toString();
  }

  private decrypt(value: string) {
    const decrypted = CryptoJS.AES.decrypt(value, this.key, {
      keySize: 128 / 8,
      iv: this.key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let res;
    try {
      res = decrypted?.toString(CryptoJS.enc.Utf8);
    } catch (err) {
      res = null;
      localStorage.clear();
    }

    return res;
  }
}
