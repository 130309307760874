import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import { ButtonActivity, Design, Donation } from '@domains';
import { DesignService } from '@rspl-ui';
import { RatingChangeEvent } from 'angular-star-rating';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  @Input() donation?: Donation;
  form: FormGroup<{ comment: FormControl<string | null>, rating: FormControl<number | null> }>;
  submitted = false;

  constructor(
    private donationsService: DonationsService,
    private service: AppService,
    public designService: DesignService
  ) {
    this.designService.setDesign(Design.DESIGN_2);
    this.form = new FormGroup({
      comment: new FormControl(''),
      rating: new FormControl(5, Validators.required)
    });
  }

  ngOnInit(): void {
  }

  onRate(event: RatingChangeEvent) {
    this.form.get('rating')?.patchValue(event.rating);
  }

  get rating() {
    return this.form.get('rating')?.value;
  }

  submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.invalid || !this.donation?.donationCode) {
      return;
    }
    this.service.createDonationActivity(this.donation?.id, ButtonActivity.SUBMIT_REVIEW).subscribe();
    this.donationsService.reviewDonation(this.donation?.donationCode, this.form.getRawValue()).subscribe({
      next: () => {
        this.submitted = true;
      },
      error: () => {
        this.service.createDonationActivity(this.donation?.id, ButtonActivity.SUBMIT_REVIEW_ERROR).subscribe();
      }
    });
  }

}
