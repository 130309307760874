import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Charity, Organization } from '@domains';
import { Destructible } from '@rspl-ui';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends Destructible implements OnInit {
  @Input() title: string = '';
  @Input() subtitle?: string;
  @Input() hasBack = false;
  @Input() logo?: string = '';
  @Input() charity?: Charity | null;
  @Input() territory?: Organization | null;

  @Output() onBack = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
