import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DonorFlowStepConfig, FlowStep } from '@domains';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';

@Component({
  selector: 'app-create-progress',
  templateUrl: './create-progress.component.html',
  styleUrls: ['./create-progress.component.scss'],
})
export class CreateProgressComponent extends Designable implements OnInit {
  @Input() step: FlowStep;

  id?: string;
  flowType?: string;

  #flowConfiguration: DonorFlowStepConfig[];
  @Input() set flowConfiguration(flowConfiguration: DonorFlowStepConfig[]) {
    this.#flowConfiguration = flowConfiguration.filter(step => !!step.stepperTitle);
  }
  get flowConfiguration():  DonorFlowStepConfig[]{
    return this.#flowConfiguration;
  }

  constructor(
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    protected route: ActivatedRoute
  ) {
    super(designService, responsiveService);
    this.id = this.route.snapshot.params['id'];
    this.flowType = this.route.snapshot.params['flowType'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  getRoute(step: DonorFlowStepConfig) {
    return ['/', step.step, this.flowType, this.id];
  }

  get stepIndex(): number {
    return this.flowConfiguration.findIndex(step => step.step === this.step)
  }
}
