import { autoserialize, autoserializeAs } from 'cerialize';

export class StripeMetadata {
  @autoserializeAs('donation_id') donationId: string;
  @autoserializeAs('donation_code') donationCode: string;
  @autoserialize gratuity?: number;

  constructor(payload: IStripeMetaData) {
    this.donationId = payload?.donationId;
    this.donationCode = payload?.donationCode;
    this.gratuity = payload?.gratuity;
  }
}

export interface IStripeMetaData {
  donationId: string;
  donationCode: string;
  gratuity?: number;
}
