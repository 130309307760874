import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@donor/app.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.scss'],
})
export class DonationsComponent implements OnInit, OnDestroy {
  codes: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private service: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.service.showCharityLogo = false;
    this.service.reset();
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (!params['c[]']) {
        this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `No codes were provided`, url: window.location.href }});
      } else if (Array.isArray(params['c[]'])) {
        this.codes = params['c[]'];
      } else if (typeof params['c'] === 'string') {
        this.router.navigate(['/', 'i', params['c[]']]);
      }
    });
  }

  ngOnDestroy(): void {
    this.service.showCharityLogo = true;
  }
}
