
import { autoserialize, autoserializeAs } from 'cerialize';

export class PartnerPayment {
  @autoserialize completed: boolean;
  @autoserializeAs('auth_completed') authCompleted: boolean;
  @autoserializeAs('setup_completed') setupCompleted: boolean;
  @autoserializeAs('stripe_customer') stripeCustomer?: string;
  @autoserializeAs('stripe_connected_account') stripeConnectedAccount: string;
  @autoserializeAs('stripe_connected_session') stripeConnectedSession?: string;
  @autoserializeAs('stripe_connected_customer')
  stripeConnectedCustomer?: string;
  @autoserializeAs('stripe_connected_payment_intent')
  stripeConnectedPaymentIntent?: string;

  constructor(payload: IPartnerPayment) {
    this.completed = payload?.completed;
    this.authCompleted = payload?.authCompleted;
    this.setupCompleted = payload?.setupCompleted;
    this.stripeConnectedAccount = payload?.stripeConnectedAccount;
    this.stripeConnectedCustomer = payload?.stripeConnectedCustomer;
    this.stripeConnectedPaymentIntent = payload?.stripeConnectedPaymentIntent;
    this.stripeConnectedSession = payload?.stripeConnectedSession;
    this.stripeCustomer = payload?.stripeCustomer;
  }
}

export interface IPartnerPayment {
  completed: boolean;
  authCompleted: boolean;
  setupCompleted: boolean;
  stripeCustomer?: string;
  stripeConnectedAccount: string;
  stripeConnectedSession?: string;
  stripeConnectedCustomer?: string;
  stripeConnectedPaymentIntent?: string;
}
