<div class="audio-controls" [ngClass]="{suffix: isSuffix}">
  <button
    type="button"
    *ngIf="audio && isSuffix"
    mat-icon-button
    (click)="toggle($event)"
  >
    <mat-icon>audio_file</mat-icon>
  </button>
  <div *ngIf="showControls || !isSuffix" fxLayout="row">
    <audio *ngIf="audio" controls>
      <source [src]="audio" />
    </audio>
    <div fxLayoutAlign="center center" *ngIf="isSuffix">
      <button
        type="button"
        mat-icon-button
        (click)="toggle($event)"
      >
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
