import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ButtonActivity,
  Charity,
  InputActivity,
  PageActivity,
  Screening,
  ScreeningQuestion,
} from '@domains';
import { AppService } from '@donor/app.service';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-donor-screening',
  templateUrl: './donor-screening.component.html',
  styleUrls: ['./donor-screening.component.scss'],
})
export class DonorScreeningComponent implements OnInit {
  @Input() leadId?: string;
  @Output() close: EventEmitter<{
    disassembly: boolean;
    stairs: boolean;
  }> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  form = new FormGroup({
    disassembly: new FormControl(null, Validators.required),
    stairs: new FormControl(null, Validators.required),
    restrictedItems: new FormControl(null, Validators.required),
    damaged: new FormControl(null, Validators.required),
    zip: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/^\d{5}$/),
    ]),
  });
  #screening?: Screening;
  question = ScreeningQuestion;
  inputActivity = InputActivity;
  videoVisible = false;

  @Input() set screening(screening: Screening | undefined) {
    this.#screening = screening;
    if (
      this.screening?.disassembly ||
      this.screening?.hiddenFields.includes(ScreeningQuestion.DISASSEMBLY)
    ) {
      this.disassembly.setValue(false);
      this.disassembly.removeValidators(Validators.required);
    }
    if (
      this.screening?.floor === 'any' ||
      this.screening?.hiddenFields.includes(ScreeningQuestion.FLOOR)
    ) {
      this.stairs.setValue(false);
      this.stairs.removeValidators(Validators.required);
    }
    if (
      (this.screening?.restrictedItems?.length || 0) === 0 ||
      this.screening?.hiddenFields.includes(ScreeningQuestion.RESTRICTED_ITEMS)
    ) {
      this.restrictedItems.setValue(false);
      this.restrictedItems.removeValidators(Validators.required);
    }
    if (this.screening?.hiddenFields.includes(ScreeningQuestion.DAMAGED)) {
      this.damaged.removeValidators(Validators.required);
    }
    if (this.screening?.hiddenFields.includes(ScreeningQuestion.ZIPS) || (this.screening?.zips.length || 0) === 0) {
      this.zip.removeValidators(Validators.required);
    }
  }

  get screening(): Screening | undefined {
    return this.#screening;
  }

  @Input() charity!: Charity;
  showQuestions = true;
  videoWidth = Math.min(536, window.innerWidth - 90);

  constructor(private service: AppService) {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  ngOnInit(): void {
    this.service
      .createLeadActivity(this.leadId, PageActivity.SCREENING_PAGE)
      .pipe(take(1))
      .subscribe();
  }

  goToPrimary(): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.SCREENING_PRIORITY)
      .pipe(take(1))
      .subscribe(() =>
        this.close.next({
          stairs: this.stairs.value,
          disassembly: this.disassembly.value,
        })
      );
  }

  goToFree(createActivity: boolean): void {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.SCREENING_STANDARD)
      .pipe(take(1))
      .subscribe(() => {
        if (this.charity.meta?.url)
          window.location.href = this.charity.meta.url;
      });
  }

  goToDropOff() {
    this.service
      .createLeadActivity(this.leadId, ButtonActivity.SCREENING_DROP_OFF)
      .pipe(take(1))
      .subscribe(() => {
        if (this.charity.meta?.dropOffUrl)
          window.location.href = this.charity.meta?.dropOffUrl;
      });
  }

  goBack() {
    this.back.emit();
    this.showQuestions = true;
  }

  next() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (!this.form.invalid) {
      if (
        (this.screening?.zips.length === 0 || this.screening?.hiddenFields.includes(ScreeningQuestion.ZIPS) || this.screening?.zips.includes(this.zip.value)) &&
        (!this.screening?.floor || this.screening?.hiddenFields.includes(ScreeningQuestion.FLOOR) || this.stairs.value === false) &&
        (this.screening?.disassembly || this.screening?.hiddenFields.includes(ScreeningQuestion.DISASSEMBLY) || this.disassembly.value === false) &&
        (this.screening?.restrictedItems.length === 0 || this.screening?.hiddenFields.includes(ScreeningQuestion.RESTRICTED_ITEMS) || this.restrictedItems.value === false) &&
        (this.screening?.hiddenFields.includes(ScreeningQuestion.DAMAGED) || this.damaged.value === false)
      ) {
        this.service
          .createLeadActivity(
            this.leadId,
            ButtonActivity.SCREENING_SUBMIT,
            'qualified'
          )
          .pipe(take(1))
          .subscribe(() => this.goToFree(false));
      } else {
        this.service
          .createLeadActivity(
            this.leadId,
            ButtonActivity.SCREENING_SUBMIT,
            'unqualified'
          )
          .pipe(take(1))
          .subscribe(() => {
            this.showQuestions = false;
            setTimeout(() => {
              document.querySelector('div.split-screen')?.scrollTo(0, 0);
            });
          });
      }
    } else {
      this.service
        .createLeadActivity(
          this.leadId,
          ButtonActivity.SCREENING_SUBMIT_ERROR,
        )
        .pipe(take(1))
        .subscribe();
    }
  }

  inputChange(activity: InputActivity, value: any) {
    this.service
      .createLeadActivity(
        this.leadId,
        activity,
        value
      )
      .pipe(take(1))
      .subscribe();
  }

  showVideo() {
    if (!this.videoVisible) {
      this.videoVisible = true;
      this.service
        .createLeadActivity(
          this.leadId,
          ButtonActivity.SCREENING_YOUTUBE
        )
        .pipe(take(1))
        .subscribe();
    }
  }

  get zip(): FormControl {
    return this.form.get('zip') as FormControl;
  }

  get disassembly(): FormControl {
    return this.form.get('disassembly') as FormControl;
  }

  get stairs(): FormControl {
    return this.form.get('stairs') as FormControl;
  }

  get restrictedItems(): FormControl {
    return this.form.get('restrictedItems') as FormControl;
  }

  get damaged(): FormControl {
    return this.form.get('damaged') as FormControl;
  }
}
