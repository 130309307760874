import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Responsive, ResponsiveService } from '../../responsive';

@Component({
  selector: 'rspl-about-resupply-dialog',
  templateUrl: './about-resupply-dialog.component.html',
  styleUrl: './about-resupply-dialog.component.scss',
})
export class AboutResupplyDialogComponent extends Responsive {
  constructor(
    public override responsiveService: ResponsiveService,
    public reference: MatDialogRef<AboutResupplyDialogComponent>
  ) {
    super(responsiveService);
  }

  get window() {
    return window;
  }
}
