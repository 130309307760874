export enum PlaceType {
  address = 'address',
  postcode = 'postcode',
}
export interface PlaceContext {
  id: string;
  language: string;
  language_en: string;
  short_code: string;
  text: string;
  text_en: string;
  wikidata: string;
}
export interface Place {
  place_type: PlaceType[];
  center: [number, number];
  place_name: string;
  place_name_en: string;
  text: string;
  text_en: string;
  address: string;
  context: PlaceContext[];
}
