import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Design,
  Donation,
  DonorFlowStepConfig,
  ENVIRONMENT,
  Environment,
  FlowStep,
  Lead,
} from '@domains';
import { AppService } from '@donor/app.service';
import { Client, createInstance } from '@optimizely/optimizely-sdk';
import { IOptimizelyUserContext } from '@optimizely/optimizely-sdk/dist/optimizely_user_context';
import { LocalStorageService } from '@rspl-api';

@Injectable({
  providedIn: 'root',
})
export class OptimizelyService {
  loaded = false;
  optimizelyMarketingData: {
    [key: string]: any;
  } = {};

  optimizelyFlowData: {
    flowConfiguration: DonorFlowStepConfig[];
    design: Design;
  } = {
    flowConfiguration: [
      {
        step: FlowStep.SplitScreen,
        component: 'FlowSplitScreenComponent',
      },
      {
        step: FlowStep.DonationDetails,
        component: 'FlowDonationDetailsComponent',
        stepperTitle: 'Items',
      },
      {
        step: FlowStep.PlaceTime,
        component: 'FlowPlaceTimeComponent',
        stepperTitle: 'Schedule',
      },
      {
        step: FlowStep.AdditionalInformation,
        component: 'FlowAdditionalInformationComponent',
        stepperTitle: 'Information',
      },
      {
        step: FlowStep.Payment,
        component: 'FlowPaymentComponent',
        stepperTitle: 'Payment',
      },
    ],
    design: Design.DESIGN_2,
  };
  optimizely: Client;
  optimizelyUser: IOptimizelyUserContext;
  marketingVariation: string;
  marketingRule: string;
  flowVariation: string;
  flowRule: string;

  constructor(
    protected localStorage: LocalStorageService,
    @Inject(ENVIRONMENT) private environment: Environment,
    protected sanitizer: DomSanitizer,
    private appService: AppService
  ) {}

  async initLeadOptimizely(
    lead: Lead,
    marketing_variation?: string,
    flow_variation?: string,
    marketId?: string
  ) {
    return this.initOptimizely(lead.id, {
      ...(marketing_variation ? { marketing_variation } : {}),
      ...(flow_variation ? { flow_variation } : {}),
      ...(marketId ? { market_id: marketId } : {}),
      ...(lead.charityId ? { charity_id: lead.charityId } : {}),
      ...(lead.partnerId ? { partner_id: lead.partnerId } : {}),
      ...(lead.ownerId ? { owner_id: lead.ownerId } : {}),
      ...(lead.ownerType ? { owner_type: lead.ownerType } : {}),
    });
  }

  async initDonationOptimizely(
    donation: Donation,
    marketing_variation?: string,
    flow_variation?: string,
    marketId?: string
  ) {
    return this.initOptimizely(donation.donationCode, {
      ...(marketing_variation ? { marketing_variation } : {}),
      ...(flow_variation ? { flow_variation } : {}),
      ...(marketId ? { market_id: marketId } : {}),
      ...(donation.charityId ? { charity_id: donation.charityId } : {}),
      ...(donation.partnerId ? { partner_id: donation.partnerId } : {}),
    });
  }

  async initOptimizely(id: string, data: any) {
    if (this.loaded)
      return {
        marketing: {
          variation: this.marketingVariation,
          rule: this.marketingRule,
          variables: this.optimizelyMarketingData,
        },
        flowConfiguration: {
          variation: this.flowVariation,
          rule: this.flowRule,
          variables: this.optimizelyFlowData,
        },
      };
    if (!this.environment.optimizelyKey || this.optimizelyUser) return null;
    try {
      const DATAFILE_URL = `https://cdn.optimizely.com/datafiles/${this.environment.optimizelyKey}.json`;
      const response = await fetch(DATAFILE_URL);
      const datafile = await response.json();

      this.optimizely = createInstance({
        datafile,
      });

      if (!this.optimizely) {
        console.log('Optimizely init error');
        return null;
      } else {
        this.optimizelyUser = this.optimizely.createUserContext(id, data);
        this.appService.optimizelyUser = this.optimizelyUser;

        // decide and populate marketing variables
        const optimizelyMarketingDecision =
          this.optimizelyUser.decide('marketing');
        this.optimizelyMarketingData = optimizelyMarketingDecision.variables;

        // decide and populate flowConfiguration & design
        const optimizelyFlowDecision = this.optimizelyUser.decide('flow');
        this.optimizelyFlowData = {
          flowConfiguration:
            (optimizelyFlowDecision.variables['flowConfiguration'] as any)
              ?.flowConfiguration || this.optimizelyFlowData.flowConfiguration,
          design: (optimizelyFlowDecision.variables['design'] ||
            this.optimizelyFlowData.design) as Design,
        };

        this.flowRule = optimizelyFlowDecision.ruleKey;
        this.flowVariation = optimizelyFlowDecision.variationKey;
        this.marketingRule = optimizelyMarketingDecision.ruleKey;
        this.marketingVariation = optimizelyMarketingDecision.variationKey;
      }
    } catch (err) {
      this.loaded = true;
      this.flowRule = 'optimizely-error';
      this.flowVariation = undefined;
      this.marketingRule = 'optimizely-error';
      this.marketingVariation = undefined;
    }
    this.loaded = true;
    return {
      marketing: {
        variation: this.marketingVariation,
        rule: this.marketingRule,
        variables: this.optimizelyMarketingData,
      },
      flowConfiguration: {
        variation: this.flowVariation,
        rule: this.flowRule,
        variables: this.optimizelyFlowData,
      },
    };
  }

  bypassSecurityTrustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
