export enum DonationPartnerState {
  unassigned = 'unassigned',
  assigned = 'assigned',
  accepted = 'accepted',
  canceled = 'canceled',
  declined = 'declined',
  en_routed = 'en_routed',
  arrived = 'arrived',
  quote_sent = 'quote_sent',
  payment_skipped = 'payment_skipped',
  en_routed_to_store = 'en_routed_to_store',
  arrived_at_store = 'arrived_at_store',
  completed = 'completed',
  en_routed_to_secondary = 'en_routed_to_secondary',
  arrived_at_secondary = 'arrived_at_secondary',
  completed_secondary = 'completed_secondary',
}

export const PARTNER_STATES = [
  {
    value: 'submitted',
    viewValue: 'Submitted',
  },
  {
    value: 'unassigned',
    viewValue: 'Unassigned',
  },
  {
    value: 'assigned',
    viewValue: 'Assigned',
  },
  {
    value: 'accepted',
    viewValue: 'Accepted',
  },
  {
    value: 'canceled',
    viewValue: 'Canceled',
  },
  {
    value: 'declined',
    viewValue: 'Declined',
  },
  {
    value: 'en_routed',
    viewValue: 'En Routed',
  },
  {
    value: 'arrived',
    viewValue: 'Arrived',
  },
  {
    value: 'quote_sent',
    viewValue: 'Quote Sent',
  },
  {
    value: 'payment_skipped',
    viewValue: 'Payment Skipped',
  },
  {
    value: 'en_routed_to_store',
    viewValue: 'En Routed to Store',
  },
  {
    value: 'arrived_at_store',
    viewValue: 'Arrived at Store',
  },
  {
    value: 'completed',
    viewValue: 'Completed',
  },
  {
    value: 'en_routed_to_secondary',
    viewValue: 'En Routed to Secondary',
  },
  {
    value: 'arrived_at_secondary',
    viewValue: 'Arrived at Secondary',
  },
  {
    value: 'completed_secondary',
    viewValue: 'Completed Secondary',
  },
];
