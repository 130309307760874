<mat-form-field appearance="outline" fxFlex>
  <mat-label>{{ label }}</mat-label>
  <input
    [formControl]="street"
    #inputElement
    matInput
    [matAutocomplete]="auto"
    (input)="getAddressesDelayed()"
    (focus)="getAddressesInitial()"
    autocomplete="off"
  />
  <mat-icon *ngIf="hasIcon" matPrefix>search</mat-icon>
  <rspl-input-audio matSuffix [audio]="audio"></rspl-input-audio>
  <mat-autocomplete #auto="matAutocomplete" class="addr-autocomplete">
    <mat-option disabled class="loading" *ngIf="loading" fxLayoutAlign="center center">
      <div fxFlex="100" fxLayoutAlign="center center">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </mat-option>
    <mat-option
      disabled
      class="loading"
      *ngIf="
        !loading && addresses.length === 0 && (street.value?.length || 0) > 0
      "
      fxLayoutAlign="center center"
    >
      No matches
    </mat-option>
    <mat-option
      *ngFor="let addr of addresses; let i = index"
      [value]="addr.street"
      (onSelectionChange)="select(addr, $event)"
    >
      <div fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-icon>location_pin</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex>
          <p>
            {{ addr.street }}
            {{ addr.secondary && '#' + addr.secondary }}
          </p>
          <p>{{ addr.city }}, {{ addr.state }} {{ addr.zip }}, US</p>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
