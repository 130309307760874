import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';

export class Zone extends BaseDTO implements IZone {
  @autoserialize id?: string;
  @autoserializeAs('partner_id') partnerId?: string;
  @autoserializeAs('created_at') createdAt?: string;
  @autoserializeAs('updated_at') updatedAt?: string;
  @autoserializeAs('charity_id') charityId?: string;
  @autoserialize override name: string;
  @autoserialize zips: string[];
  @autoserialize meta: any;

  constructor(payload?: IZone) {
    super(payload);
    this.id = payload?.id?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.name = payload?.name || '';
    this.zips = payload?.zips || [];
    this.meta = payload?.meta;
    this.partnerId = payload?.partnetId?.toString();
    this.charityId = payload?.charityId?.toString();
  }
}

export interface IZone extends IBaseDTO {
  id?: string;
  name?: string;
  zips?: string[];
  partnetId?: string;
  charityId?: string;
  createdAt?: string;
  updatedAt?: string;
  meta?: any;
}
