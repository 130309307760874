import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';

@Component({
  selector: 'app-info-form',
  templateUrl: './info-form.component.html',
  styleUrls: ['./info-form.component.scss'],
})
export class InfoFormComponent extends Designable implements OnInit {
  @Input() form!: FormGroup<{
    donor: FormGroup<{
      name: FormControl<string>;
      email: FormControl<string>;
      phone: FormControl<string>;
    }>;
    address?: FormGroup<{
      state: FormControl<string>;
    }>;
    instructions: FormControl<string | null>;
    donationItems: FormControl<string | null>;
  }>;

  constructor(
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  get hasInstructions(): boolean {
    return (this.form.get('instructions')?.value?.trim().length ?? 0) > 0;
  }

  get hasDescription(): boolean {
    return (this.form.get('donationItems')?.value?.trim().length ?? 0) > 0;
  }

  get donor(): FormGroup<{
    name: FormControl<string | null>;
    email: FormControl<string | null>;
    phone: FormControl<string | null>;
  }> {
    return this.form.get('donor') as FormGroup<{
      name: FormControl<string | null>;
      email: FormControl<string | null>;
      phone: FormControl<string | null>;
    }>;
  }

  get email(): FormControl<string | null> {
    return this.donor.get('email') as FormControl<string | null>;
  }

  get name(): FormControl<string | null> {
    return this.donor.get('name') as FormControl<string | null>;
  }

  get phone(): FormControl<string | null> {
    return this.donor.get('phone') as FormControl<string | null>;
  }
}
