<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <app-page-header title="Find your donation" logo="assets/images/rspl_logo.png"></app-page-header>
  <div class="page-content">
    <div
      class="lookup-wrapper"
      fxLayoutAlign="center center"
      fxLayout="column"
    >


      <h2 class="text-center lookup-instructions">In order to access your Donation we need the last 4 digits of your
        Donation Code.</h2>
      <a class="link">What is Donation Code?</a>
      <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
        <mat-radio-group fxLayout="column" style="width: 100%;" [(ngModel)]="knowCode">
          <mat-radio-button [value]="true" labelPosition="before">I know my Donation Code</mat-radio-button>
          <mat-radio-button [value]="false" labelPosition="before">I DON'T know my Donation Code</mat-radio-button>
        </mat-radio-group>
      </div>
      <form [formGroup]="form" fxLayout="column" fxFlex>
        <div class="form-row" fxLayout="column" fxLayoutAlign="center start" style="width: 100%" *ngIf="knowCode || knowCode === false">
          <p *ngIf="knowCode === false">Please enter your email or phone associated with your Donation pickup request. We will send you a link to
            manage your donation pickup appointments.</p>
          <p *ngIf="knowCode">Please enter your email or phone associated with your Donation pickup request, and enter the last 4 digits of your Donation Code.</p>
          <input
            [ngClass]="{invalid: email_or_phone.invalid && email_or_phone.touched}"
            type="text"
            formControlName="email_or_phone"
            placeholder="Your E-mail or Phone"
          />
          <p
            class="hint error"
            *ngIf="email_or_phone.invalid && email_or_phone.touched"
          >
            Please enter a valid e-mail or phone number
          </p>
        </div>
        <div class="form-row" fxLayout="column" fxLayoutAlign="center start" style="width: 100%; margin-bottom: 0;" *ngIf="knowCode">
          <input
            [ngClass]="{ invalid: last4.invalid && last4.touched }"
            type="text"
            formControlName="last4"
            placeholder="Last 4 characters of Your Donation Code"
          />
          <p class="hint error" *ngIf="last4.invalid && last4.touched">
            Please input last 4 characters of your donation code
          </p>
        </div>
        <p class="{{ success ? 'success' : 'error' }}-message">{{ message }}</p>
      </form>
        <button *ngIf="knowCode || knowCode === false" class="submit-button primary-page-btn btn-block" (click)="lookup()">
          Find
        </button>
    </div>
  </div>
</ng-template>
