import { autoserialize, autoserializeAs, Deserialize } from 'cerialize';
import { IStripeCustomer, StripeCustomer } from './stripe-customer';
import { IStripeFee, StripeFee } from './stripe-fee';
import { IStripeMetaData, StripeMetadata } from './stripe-metadata';

export class StripePayment {
  @autoserialize id: string;
  @autoserialize application: string;
  @autoserialize amount: number;
  @autoserializeAs('amount_capturable') amountCapturable: number;
  @autoserializeAs('amount_received') amountReceived: number;
  @autoserialize metadata: StripeMetadata;
  @autoserialize customer: StripeCustomer;
  @autoserialize fees: Array<StripeFee>;
  @autoserialize created: number;
  @autoserialize status: string;

  constructor(payload: IStripePayment) {
    this.id = payload?.id?.toString();
    this.application = payload?.application;
    this.amount = payload?.amount;
    this.amountCapturable = payload?.amountCapturable;
    this.amountReceived = payload?.amountReceived;
    this.metadata = new StripeMetadata(Deserialize(payload?.metadata, StripeMetadata));
    this.customer = new StripeCustomer(Deserialize(payload?.customer, StripeCustomer));
    this.fees = payload?.fees?.map(x => new StripeFee(Deserialize(x, StripeFee)));
    this.created = payload?.created * 1000;
    this.status = payload?.status;
  }

  get total(): number {
    let total = this.amount;
    this.fees.forEach(f => total -= f.amount);
    return total;
  }
}


export class StripePayments {
  @autoserializeAs('has_more') hasMore: boolean;
  @autoserialize data: Array<StripePayment>;

  constructor(payload?: IStripePayments) {
    this.hasMore = payload ? payload.hasMore : true;
    this.data = payload?.data?.map(x => new StripePayment(Deserialize(x, StripePayment))) || [];
  }

}

export interface IStripePayments {
  hasMore: boolean;
  data: Array<StripePayment>;
}

export interface IStripePayment {
  id: string;
  application: string;
  amount: number;
  amountCapturable: number;
  amountReceived: number;
  customer: IStripeCustomer;
  metadata: IStripeMetaData;
  fees: Array<IStripeFee>;
  created: number;
  status: string;
}
