<div class="info-wrapper">
  <span class="title" *ngIf="header">{{header}}</span>
  <div class="content">
    <p>
      {{message}}
      <ng-content></ng-content>
    </p>
  </div>
  <mat-icon [ngClass]="{'warning' : type === 'warning'}">{{type === 'warning' ? 'warning' : 'info'}}</mat-icon>
</div>
