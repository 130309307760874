import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RsplUIModule } from '@rspl-ui';
import { LaddaModule } from 'angular2-ladda';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AddressInputFormComponent } from './address-input-form/address-input-form.component';
import { AdvancedAddressInputComponent } from './advanced-address-input';
import { MapComponent } from './map';
import { SimpleAddressInputComponent } from './simple-address-input';

@NgModule({ declarations: [
        SimpleAddressInputComponent,
        MapComponent,
        AdvancedAddressInputComponent,
        AddressInputFormComponent,
    ],
    exports: [
        SimpleAddressInputComponent,
        MapComponent,
        AdvancedAddressInputComponent,
        AddressInputFormComponent,
    ], imports: [CommonModule,
        MatButtonModule,
        NgxMapboxGLModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        RouterModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        RsplUIModule,
        MatTooltipModule,
        MatAutocompleteModule,
        LaddaModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class RsplMapModule {
  public static forRoot(): ModuleWithProviders<RsplMapModule> {
    return {
      ngModule: RsplMapModule,
      providers: [RsplMapModule, MapService],
    };
  }
}
