<h2 mat-dialog-title>About Resupply</h2>
<div mat-dialog-content>
  <youtube-player
      style="margin: 20px 0"
      videoId="FYqsbNxuTUI"
      suggestedQuality="highres"
      [width]="isSmallWindowSize ? (window.innerWidth * 0.8 - 20) : '640'"
      [playerVars]="{autoplay: 1}"
    >
    </youtube-player>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-raised-button class="primary-btn filled-btn" (click)="reference.close()">Close</button>
</div>
