import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ButtonActivity,
    Donation,
    DonationPartnerState,
    PageActivity,
    Pricing,
} from '@domains';
import { environment } from '@donor/../environments/environment';
import { AppService } from '@donor/app.service';
import { AlreadyPaidComponent } from '@donor/components/already-paid/already-paid.component';
import { StripeComponent } from '@payment';
import { DonationsService, UploadFileService } from '@rspl-api';
import {
    DesignService, Designable, DonationEstimateComponent,
    ResponsiveService, SignatureComponent
} from '@rspl-ui';
import { StripeService } from 'ngx-stripe';
import { finalize, take } from 'rxjs/operators';

export enum TipType {
  TEN = 'ten',
  FIFTEEN = 'fifteen',
  TWENTY = 'twenty',
  CUSTOM = 'custom',
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent extends Designable implements OnInit {
  error?: string;
  @ViewChild(SignatureComponent) signaturePad!: SignatureComponent;
  @ViewChild('estimate') estimate?: DonationEstimateComponent;
  donationCode: string;
  donation?: Donation;
  isSubmitting = false;
  form: FormGroup<{
    tip: FormControl<number | null>;
    signature: FormControl<string | null>;
  }>;
  tipTypes = TipType;
  tip?: TipType;
  gratuity = 0;
  newCard = false;
  signaturePadOptions: any;
  client_secret?: string | null;
  isAccepted = false;
  paymentIntent?: {
    client_secret: string;
    stripe_account: string;
    payment_method?: {
      card?: {
        brand?: string;
        exp_month?: number;
        exp_year?: number;
        last4?: string;
      };
      wallet?: {
        apple_pay?: any;
        google_pay?: any;
      };
    };
  };
  @ViewChild(StripeComponent)
  stripe!: StripeComponent;
  totalPrice = 0;

  isLoaded = false;

  constructor(
    private service: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private uploadService: UploadFileService,
    private dialog: MatDialog,
    private stripeService: StripeService,
    private donationsService: DonationsService,
    designService: DesignService,
    responsiveService: ResponsiveService
  ) {
    super(designService, responsiveService);
    this.donationCode = this.route.snapshot.params['code'];
    this.form = new FormGroup({
      tip: new FormControl(0),
      signature: new FormControl<string | null>(null),
    });
    this.newCard = !!this.route.snapshot.queryParams['new'];
    this.signaturePadOptions = {
      canvasWidth: Math.min(830, window.innerWidth - 80),
      canvasHeight: 200,
    };
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.form.valueChanges.subscribe((valueChanges) => {
      if (this.tip === TipType.CUSTOM) {
        this.updateTip(valueChanges.tip);
      }
    });
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.newCard =
            this.newCard || !this.donation?.payment?.setupCompleted;
          this.totalPrice = this.donationPrice + this.gratuity;
          if (this.donation?.partnerState !== DonationPartnerState.quote_sent) {
            // this.router.navigate(['/', 'page-not-found'], {queryParams: { message: `Payment for donation <b>${this.donationCode}</b> was already authorized!`, url: window.location.href }});
          }
          this.service
            .createDonationActivity(
              this.donation?.id,
              PageActivity.PAYMENT_AUTHORIZATION_PAGE
            )
            .subscribe();
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Donation <b>${this.donationCode}</b> could not be found!`,
              url: window.location.href,
            },
          });
        },
      });
  }

  goToPayment() {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.CONTINUE_TO_PAYMENT
      )
      .subscribe();
    this.service
      .getDonationByCode(this.donationCode)
      .pipe(take(1))
      .subscribe((donation) => {
        this.donation = donation;
        if (!this.donation) {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Donation <b>${this.donationCode}</b> could not be found!`,
              url: window.location.href,
            },
          });
        } else if (this.donation.payment?.authCompleted) {
          this.dialog
            .open(AlreadyPaidComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height: '100vh',
              data: 'paid',
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
              if (res) {
                this.continue();
              }
            });
        } else {
          this.continue();
        }
      });
  }

  private continue() {
    if (this.donation) {
      this.isSubmitting = true;
      if (this.signaturePad.isEmpty()) {
        this.acceptDonation();
      } else {
        this.uploadService
          .uploadFile(
            this.uploadService.convertDataUrlToFile(
              this.signaturePad.toDataURL()
            )
          )
          .pipe(take(1))
          .subscribe((response) => {
            this.acceptDonation(response);
          });
      }
    }
  }

  private acceptDonation(response?: any) {
    this.error = undefined;
    if (this.donation?.donationCode) {
      this.isSubmitting = true;
      this.donationsService
        .updateDonationStateByCode(this.donation.donationCode, {
          stateAction: 'accept',
          gratuity: this.gratuity * 100,
          donorSignature: response?.url,
        })
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.createPaymentIntent();
          },
          error: () => (this.isSubmitting = false),
        });
    }
  }

  setGratuity() {
    let gratuity: number | undefined | null = 0;
    switch (this.tip) {
      case TipType.TEN:
        gratuity = (this.estimate?.donationPrice || 0) * 0.1;
        break;
      case TipType.FIFTEEN:
        gratuity = (this.estimate?.donationPrice || 0) * 0.15;
        break;
      case TipType.TWENTY:
        gratuity = (this.estimate?.donationPrice || 0) * 0.2;
        break;
      case TipType.CUSTOM:
        gratuity = this.form.get('tip')?.value;
        break;
    }
    this.updateTip(gratuity);
  }

  updateTip(gratuity: number | string | null | undefined) {
    this.gratuity = Math.round(Number(gratuity) * 100) / 100;
    this.totalPrice = this.donationPrice + this.gratuity;
  }

  createPaymentIntent() {
    if (!this.donation) {
      this.isSubmitting = false;
      return;
    }
    this.isSubmitting = true;
    this.donationsService
      .createPaymentIntent(this.donation)
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        take(1)
      )
      .subscribe({
        next: (intent) => {
          this.paymentIntent = intent;
          if (intent.stripe_account) {
            this.stripeService.changeKey(environment.stripeKey, {
              stripeAccount: intent.stripe_account,
            });
          }
          if (
            !this.paymentIntent.payment_method?.card &&
            !this.paymentIntent.payment_method?.wallet
          ) {
            this.newCard = true;
          }
          this.isAccepted = true;
        },
        error: (err) => {
          if (err.error.code === 'quote_digest_error') {
            this.error = 'Payment has already been completed!';
          }
        },
      });
  }

  checkout() {
    if (!this.paymentIntent) {
      this.isSubmitting = false;
      this.isAccepted = false;
      return;
    }
    this.error = undefined;
    this.isSubmitting = true;
    this.service
      .createDonationActivity(this.donation?.id, ButtonActivity.CONFIRM_PAYMENT)
      .subscribe();

    this.stripe.onPayment();
  }

  onPaymentSuccess() {
    setTimeout(() => {
      this.isSubmitting = false;
      this.router.navigate(['/', 'i', this.donation?.donationCode]);
    }, 1500);
  }

  onPaymentError(error: string) {
    this.error = error;
    this.isSubmitting = false;
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.CONFIRM_PAYMENT_ERROR
      )
      .subscribe();
  }

  toggleTip(tip: TipType) {
    if (this.tip === tip) {
      this.tip = undefined;
    } else {
      this.tip = tip;
    }
    this.setGratuity();
  }

  toggleCustom(event: MouseEvent, inputElement: HTMLInputElement) {
    if (this.tip !== TipType.CUSTOM) {
      this.tip = TipType.CUSTOM;
      this.setGratuity();
      inputElement.select();
      return;
    }
    if (event.target !== inputElement) {
      this.toggleTip(TipType.CUSTOM);
    }
  }

  setSignature() {
    this.form?.get('signature')?.patchValue(this.signaturePad.toDataURL());
  }

  clearSignature(): void {
    this.signaturePad.clear();
    this.form?.get('signature')?.patchValue(null);
  }

  cardReady() {
    this.isLoaded = true;
  }

  get signatureFormControl(): FormControl<string | null | undefined> {
    return this.form?.get('signature') as FormControl<
      string | null | undefined
    >;
  }

  get donationPrice(): number {
    return this.donation
      ? Pricing.getTotalPrice(
          this.donation.adjustedSpecification,
          this.donation.pricing
        )
      : 0;
  }

  back() {
    this.isAccepted = false;
    this.form.get('signature')?.patchValue(null);
  }

  onPaymentSetupSuccess(activity: ButtonActivity) {
    this.service
      .createDonationActivity(this.donation?.id, activity)
      .subscribe();
  }
}
