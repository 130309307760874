<div fxLayout="row" fxLayoutGap="5px">
  <a
    [fxFlex]="100 / flowConfiguration.length"
    *ngFor="let s of flowConfiguration; let i = index"
    [ngClass]="{ active: stepIndex === i, filled: stepIndex >= i }"
    [routerLink]="stepIndex >= i ? getRoute(s) : undefined"
  >
  {{(i + 1) + '. ' + s.stepperTitle}}
  </a>
</div>
