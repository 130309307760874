import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Pricing } from './pricing';
import { Pricing2 } from './pricing2';

export class Market extends BaseDTO implements IMarket {
  @autoserialize id?: string;
  @autoserialize override name: string;
  @autoserialize description?: string;
  @autoserializeAs(Pricing, 'pricing') pricing: Pricing;
  @autoserializeAs(Pricing2, 'pricing2') pricing2?: Pricing2 | null;
  @autoserializeAs('pricing2_percent') pricing2Percent?: number;
  @autoserializeAs('discarded_at') discardedAt?: string;
  @autoserializeAs('score_random_sampling') scoreRandomSampling?: boolean;
  @autoserializeAs('auto_cancel') autoCancel?: boolean;

  constructor(payload?: IMarket) {
    super(payload);
    this.id = payload?.id?.toString();
    this.name = payload?.name || '';
    this.description = payload?.description;
    this.pricing = new Pricing(payload?.pricing);
    this.pricing2 = payload?.pricing2 ? new Pricing2(payload?.pricing2) : null;
    this.pricing2Percent = payload?.pricing2Percent;
    this.discardedAt = payload?.discardedAt;
    this.scoreRandomSampling = payload?.scoreRandomSampling;
    this.autoCancel = payload?.autoCancel;
  }
}

export interface IMarket extends IBaseDTO {
  id?: string;
  name?: string;
  description?: string;
  pricing?: Pricing;
  pricing2?: Pricing2 | null;
  pricing2Percent?: number;
  discardedAt?: string;
  scoreRandomSampling?: boolean;
  autoCancel?: boolean;
}
