import { autoserialize } from 'cerialize';
import { SplitScreenVersion } from './split-screen-version';

export class SplitScreen {
  public static DEFAULT = {
    v1: {
      INITIAL_TEXT:
        'Pickup services are free to you\n' +
        'Ground floor disassembly only\n' +
        'Items accepted based on condition\n' +
        'Scheduling based on availability',
      INITIAL_TITLE_TEXT: 'STANDARD PICK-UP',
      INITIAL_BTN_TEXT: 'Request Approval',
      INITIAL_BACKGROUND_COLOR: 'rgba(190,190,190,0.25)',
      INITIAL_TEXT_COLOR: '#919191',
      INITIAL_BTN_BACKGROUND_COLOR: 'rgba(0,48,87,0.22)',
      INITIAL_BTN_TEXT_COLOR: '#ffffff',
      INITIAL_TITLE_BACKGROUND_COLOR: 'rgba(0,48,87,0.22)',
      INITIAL_TITLE_TEXT_COLOR: '#ffffff',
      INITIAL_LOGO_BACKGROUND_COLOR: '#ffffff',
      INITIAL_STRIPE_BACKGROUND_COLOR: '#E0201B',
      INITIAL_BULLET_COLOR: '#0261AD',
      INITIAL_TEXT_PRIMARY:
        'Pickups as soon as 2 business days (Mon-Sat), subject to timeslot availability\n' +
        'Can pick up from multiple floors\n' +
        'Can disassemble furniture\n' +
        'Items of ANY condition accepted\n' +
        'Cost of the pickup for the charity is covered (Fee Based Service)',
      INITIAL_TITLE_TEXT_PRIMARY: 'PRIORITY PICK-UP',
      INITIAL_BTN_TEXT_PRIMARY: 'Schedule Now!',
      INITIAL_RESTRICTED_ITEMS_TEXT: 'Restricted Items',
      INITIAL_BACKGROUND_COLOR_PRIMARY: '#0261AD',
      INITIAL_TEXT_COLOR_PRIMARY: '#ffffff',
      INITIAL_BTN_BACKGROUND_COLOR_PRIMARY: '#003057',
      INITIAL_BTN_TEXT_COLOR_PRIMARY: '#ffffff',
      INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY: '#003057',
      INITIAL_TITLE_TEXT_COLOR_PRIMARY: '#ffffff',
      INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY: '#ffffff',
      INITIAL_BULLET_COLOR_PRIMARY: '#ffffff',
    },
    v2: {
      INITIAL_TEXT: '',
      INITIAL_TITLE_TEXT: 'STANDARD PICK-UP',
      INITIAL_BTN_TEXT: 'Request Approval',
      INITIAL_BACKGROUND_COLOR: 'rgba(190,190,190,0.25)',
      INITIAL_TEXT_COLOR: '#000000',
      INITIAL_BTN_BACKGROUND_COLOR: '#FBAA18',
      INITIAL_BTN_TEXT_COLOR: '#000000',
      INITIAL_TITLE_BACKGROUND_COLOR: '#FBAA18',
      INITIAL_TITLE_TEXT_COLOR: '#ffffff',
      INITIAL_LOGO_BACKGROUND_COLOR: '#ffffff',
      INITIAL_STRIPE_BACKGROUND_COLOR: '#E0201B',
      INITIAL_BULLET_COLOR: '#0261AD',
      INITIAL_TEXT_PRIMARY: '',
      INITIAL_TITLE_TEXT_PRIMARY: 'PRIORITY PICK-UP',
      INITIAL_BTN_TEXT_PRIMARY: 'Schedule Now!',
      INITIAL_RESTRICTED_ITEMS_TEXT: 'Restricted Items',
      INITIAL_BACKGROUND_COLOR_PRIMARY: '#D8E6F2',
      INITIAL_TEXT_COLOR_PRIMARY: '#0261AD',
      INITIAL_BTN_BACKGROUND_COLOR_PRIMARY: '#003057',
      INITIAL_BTN_TEXT_COLOR_PRIMARY: '#ffffff',
      INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY: '#003057',
      INITIAL_TITLE_TEXT_COLOR_PRIMARY: '#ffffff',
      INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY: '#ffffff',
      INITIAL_BULLET_COLOR_PRIMARY: '#ffffff',
    },
    v3A: {
      INITIAL_TEXT: '',
      INITIAL_TITLE_TEXT: 'STANDARD PICK-UP',
      INITIAL_BTN_TEXT: 'Ask for Charity free pick-up',
      INITIAL_BACKGROUND_COLOR: '',
      INITIAL_TEXT_COLOR: '',
      INITIAL_BTN_BACKGROUND_COLOR: '',
      INITIAL_BTN_TEXT_COLOR: '',
      INITIAL_TITLE_BACKGROUND_COLOR: '',
      INITIAL_TITLE_TEXT_COLOR: '',
      INITIAL_LOGO_BACKGROUND_COLOR: '',
      INITIAL_STRIPE_BACKGROUND_COLOR: '',
      INITIAL_BULLET_COLOR: '',
      INITIAL_TEXT_PRIMARY: '',
      INITIAL_TITLE_TEXT_PRIMARY: '',
      INITIAL_BTN_TEXT_PRIMARY: '',
      INITIAL_RESTRICTED_ITEMS_TEXT: '',
      INITIAL_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_TEXT_COLOR_PRIMARY: '',
      INITIAL_BTN_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_BTN_TEXT_COLOR_PRIMARY: '',
      INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_TITLE_TEXT_COLOR_PRIMARY: '',
      INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_BULLET_COLOR_PRIMARY: '',
    },
    v3B: {
      INITIAL_TEXT: '',
      INITIAL_TITLE_TEXT: 'STANDARD PICK-UP',
      INITIAL_BTN_TEXT: 'Free charity pickup',
      INITIAL_BACKGROUND_COLOR: '',
      INITIAL_TEXT_COLOR: '',
      INITIAL_BTN_BACKGROUND_COLOR: '',
      INITIAL_BTN_TEXT_COLOR: '',
      INITIAL_TITLE_BACKGROUND_COLOR: '',
      INITIAL_TITLE_TEXT_COLOR: '',
      INITIAL_LOGO_BACKGROUND_COLOR: '',
      INITIAL_STRIPE_BACKGROUND_COLOR: '',
      INITIAL_BULLET_COLOR: '',
      INITIAL_TEXT_PRIMARY: '',
      INITIAL_TITLE_TEXT_PRIMARY: '',
      INITIAL_BTN_TEXT_PRIMARY: '',
      INITIAL_RESTRICTED_ITEMS_TEXT: '',
      INITIAL_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_TEXT_COLOR_PRIMARY: '',
      INITIAL_BTN_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_BTN_TEXT_COLOR_PRIMARY: '',
      INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_TITLE_TEXT_COLOR_PRIMARY: '',
      INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY: '',
      INITIAL_BULLET_COLOR_PRIMARY: '',
    }
  };
  @autoserialize enabled?: boolean;
  @autoserialize version?: SplitScreenVersion;
  @autoserialize text?: string;
  @autoserialize title?: string;
  @autoserialize button?: string;
  @autoserialize url?: string;
  @autoserialize logoBackgroundColor?: string;
  @autoserialize backgroundColor?: string;
  @autoserialize textColor?: string;
  @autoserialize btnBackgroundColor?: string;
  @autoserialize btnTextColor?: string;
  @autoserialize titleBackgroundColor?: string;
  @autoserialize titleTextColor?: string;
  @autoserialize stripeBackgroundColor?: string;
  @autoserialize bulletColor?: string;
  @autoserialize primaryReady?: boolean;
  @autoserialize standardEnabled?: boolean;
  @autoserialize textPrimary?: string;
  @autoserialize titlePrimary?: string;
  @autoserialize buttonPrimary?: string;
  @autoserialize logoBackgroundColorPrimary?: string;
  @autoserialize backgroundColorPrimary?: string;
  @autoserialize textColorPrimary?: string;
  @autoserialize btnBackgroundColorPrimary?: string;
  @autoserialize btnTextColorPrimary?: string;
  @autoserialize titleBackgroundColorPrimary?: string;
  @autoserialize titleTextColorPrimary?: string;
  @autoserialize bulletColorPrimary?: string;
  @autoserialize showRestricedItems?: boolean;
  @autoserialize restricedItemsUrl?: string;
  @autoserialize restricedItemsText?: string;
  @autoserialize standardReady?: boolean;
  @autoserialize logo?: string;
  @autoserialize logoOffsetTop?: number;
  @autoserialize logoOffsetLeft?: number;
  @autoserialize logoSize?: number;
  @autoserialize logoOffsetTopMobile?: number;
  @autoserialize logoOffsetLeftMobile?: number;
  @autoserialize logoSizeMobile?: number;
  @autoserialize logoStandardOffsetTop?: number;
  @autoserialize logoStandardOffsetLeft?: number;
  @autoserialize logoStandardSize?: number;
  @autoserialize logoStandardOffsetTopMobile?: number;
  @autoserialize logoStandardOffsetLeftMobile?: number;
  @autoserialize logoStandardSizeMobile?: number;
  @autoserialize reverse?: boolean;
  @autoserialize discounts?: boolean;

  @autoserialize isReady?: boolean;

  constructor(payload?: ISplitScreen | null, shouldPopulate = true) {
    if (!shouldPopulate) return;
    this.enabled = payload?.enabled === true;
    this.version =
      payload?.version ||
      (payload?.enabled ? SplitScreenVersion.V1 : SplitScreenVersion.V2);
    if (this.version === SplitScreenVersion.V1) {
      this.text = payload?.text || SplitScreen.DEFAULT.v1.INITIAL_TEXT;
      this.title = payload?.title || SplitScreen.DEFAULT.v1.INITIAL_TITLE_TEXT;
      this.button = payload?.button || SplitScreen.DEFAULT.v1.INITIAL_BTN_TEXT;
      this.url = payload?.url;
      this.backgroundColor =
        payload?.backgroundColor ||
        SplitScreen.DEFAULT.v1.INITIAL_BACKGROUND_COLOR;
      this.textColor =
        payload?.textColor || SplitScreen.DEFAULT.v1.INITIAL_TEXT_COLOR;
      this.btnBackgroundColor =
        payload?.btnBackgroundColor ||
        SplitScreen.DEFAULT.v1.INITIAL_BTN_BACKGROUND_COLOR;
      this.btnTextColor =
        payload?.btnTextColor || SplitScreen.DEFAULT.v1.INITIAL_BTN_TEXT_COLOR;
      this.titleBackgroundColor =
        payload?.titleBackgroundColor ||
        SplitScreen.DEFAULT.v1.INITIAL_TITLE_BACKGROUND_COLOR;
      this.titleTextColor =
        payload?.titleTextColor ||
        SplitScreen.DEFAULT.v1.INITIAL_TITLE_TEXT_COLOR;
      this.logoBackgroundColor =
        payload?.logoBackgroundColor ||
        SplitScreen.DEFAULT.v1.INITIAL_LOGO_BACKGROUND_COLOR;
      this.stripeBackgroundColor =
        payload?.stripeBackgroundColor ||
        SplitScreen.DEFAULT.v1.INITIAL_STRIPE_BACKGROUND_COLOR;
      this.bulletColor =
        payload?.bulletColor || SplitScreen.DEFAULT.v1.INITIAL_BULLET_COLOR;
      this.primaryReady = payload?.primaryReady !== false;
      this.standardEnabled = payload?.standardEnabled !== false;
      this.textPrimary =
        payload?.textPrimary || SplitScreen.DEFAULT.v1.INITIAL_TEXT_PRIMARY;
      this.titlePrimary =
        payload?.titlePrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_TITLE_TEXT_PRIMARY;
      this.buttonPrimary =
        payload?.buttonPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_BTN_TEXT_PRIMARY;
      this.backgroundColorPrimary =
        payload?.backgroundColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_BACKGROUND_COLOR_PRIMARY;
      this.textColorPrimary =
        payload?.textColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_TEXT_COLOR_PRIMARY;
      this.btnBackgroundColorPrimary =
        payload?.btnBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_BTN_BACKGROUND_COLOR_PRIMARY;
      this.btnTextColorPrimary =
        payload?.btnTextColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_BTN_TEXT_COLOR_PRIMARY;
      this.titleBackgroundColorPrimary =
        payload?.titleBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY;
      this.titleTextColorPrimary =
        payload?.titleTextColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_TITLE_TEXT_COLOR_PRIMARY;
      this.logoBackgroundColorPrimary =
        payload?.logoBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY;
      this.bulletColorPrimary =
        payload?.bulletColorPrimary ||
        SplitScreen.DEFAULT.v1.INITIAL_BULLET_COLOR_PRIMARY;
      this.showRestricedItems = payload?.showRestricedItems === true;
      this.restricedItemsUrl = payload?.restricedItemsUrl;
      this.restricedItemsText =
        payload?.restricedItemsText ||
        SplitScreen.DEFAULT.v1.INITIAL_RESTRICTED_ITEMS_TEXT;
    } else {
      this.text = payload?.text || SplitScreen.DEFAULT.v2.INITIAL_TEXT;
      this.title = payload?.title || SplitScreen.DEFAULT.v2.INITIAL_TITLE_TEXT;
      this.button = payload?.button || SplitScreen.DEFAULT.v2.INITIAL_BTN_TEXT;
      this.url = payload?.url;
      this.backgroundColor =
        payload?.backgroundColor ||
        SplitScreen.DEFAULT.v2.INITIAL_BACKGROUND_COLOR;
      this.textColor =
        payload?.textColor || SplitScreen.DEFAULT.v2.INITIAL_TEXT_COLOR;
      this.btnBackgroundColor =
        payload?.btnBackgroundColor ||
        SplitScreen.DEFAULT.v2.INITIAL_BTN_BACKGROUND_COLOR;
      this.btnTextColor =
        payload?.btnTextColor || SplitScreen.DEFAULT.v2.INITIAL_BTN_TEXT_COLOR;
      this.titleBackgroundColor =
        payload?.titleBackgroundColor ||
        SplitScreen.DEFAULT.v2.INITIAL_TITLE_BACKGROUND_COLOR;
      this.titleTextColor =
        payload?.titleTextColor ||
        SplitScreen.DEFAULT.v2.INITIAL_TITLE_TEXT_COLOR;
      this.logoBackgroundColor =
        payload?.logoBackgroundColor ||
        SplitScreen.DEFAULT.v2.INITIAL_LOGO_BACKGROUND_COLOR;
      this.stripeBackgroundColor =
        payload?.stripeBackgroundColor ||
        SplitScreen.DEFAULT.v2.INITIAL_STRIPE_BACKGROUND_COLOR;
      this.bulletColor =
        payload?.bulletColor || SplitScreen.DEFAULT.v2.INITIAL_BULLET_COLOR;
      this.primaryReady = payload?.primaryReady !== false;
      this.standardEnabled = payload?.standardEnabled !== false;
      this.textPrimary =
        payload?.textPrimary || SplitScreen.DEFAULT.v2.INITIAL_TEXT_PRIMARY;
      this.titlePrimary =
        payload?.titlePrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_TITLE_TEXT_PRIMARY;
      this.buttonPrimary =
        payload?.buttonPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_BTN_TEXT_PRIMARY;
      this.backgroundColorPrimary =
        payload?.backgroundColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_BACKGROUND_COLOR_PRIMARY;
      this.textColorPrimary =
        payload?.textColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_TEXT_COLOR_PRIMARY;
      this.btnBackgroundColorPrimary =
        payload?.btnBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_BTN_BACKGROUND_COLOR_PRIMARY;
      this.btnTextColorPrimary =
        payload?.btnTextColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_BTN_TEXT_COLOR_PRIMARY;
      this.titleBackgroundColorPrimary =
        payload?.titleBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY;
      this.titleTextColorPrimary =
        payload?.titleTextColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_TITLE_TEXT_COLOR_PRIMARY;
      this.logoBackgroundColorPrimary =
        payload?.logoBackgroundColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY;
      this.bulletColorPrimary =
        payload?.bulletColorPrimary ||
        SplitScreen.DEFAULT.v2.INITIAL_BULLET_COLOR_PRIMARY;
      this.showRestricedItems = payload?.showRestricedItems === true;
      this.restricedItemsUrl = payload?.restricedItemsUrl;
      this.restricedItemsText =
        payload?.restricedItemsText ||
        SplitScreen.DEFAULT.v2.INITIAL_RESTRICTED_ITEMS_TEXT;
    }
    this.standardReady = payload?.standardReady !== false;
    this.reverse = payload?.reverse === true;
    this.isReady = payload?.isReady !== false;
    this.logo = payload?.logo;

    this.logoOffsetTop = payload?.logoOffsetTop;
    this.logoOffsetLeft = payload?.logoOffsetLeft;
    this.logoSize = payload?.logoSize;
    this.logoOffsetTopMobile = payload?.logoOffsetTopMobile;
    this.logoOffsetLeftMobile = payload?.logoOffsetLeftMobile;
    this.logoSizeMobile = payload?.logoSizeMobile;

    this.logoStandardOffsetTop = payload?.logoStandardOffsetTop !== undefined ? payload?.logoStandardOffsetTop : payload?.logoOffsetTop;
    this.logoStandardOffsetLeft = payload?.logoStandardOffsetLeft !== undefined ? payload?.logoStandardOffsetLeft : payload?.logoOffsetLeft;
    this.logoStandardSize = payload?.logoStandardSize !== undefined ? payload?.logoStandardSize : payload?.logoSize;
    this.logoStandardOffsetTopMobile = payload?.logoStandardOffsetTopMobile !== undefined ? payload?.logoStandardOffsetTopMobile : payload?.logoOffsetTopMobile;
    this.logoStandardOffsetLeftMobile = payload?.logoStandardOffsetLeftMobile !== undefined ? payload?.logoStandardOffsetLeftMobile : payload?.logoOffsetLeftMobile;
    this.logoStandardSizeMobile = payload?.logoStandardSizeMobile !== undefined ? payload?.logoStandardSizeMobile : payload?.logoSizeMobile;
    this.discounts = !!payload?.discounts;
  }
}
export interface ISplitScreen {
  enabled?: boolean;
  version?: SplitScreenVersion;
  text?: string;
  title?: string;
  button?: string;
  url?: string;
  logoBackgroundColor?: string;
  backgroundColor?: string;
  textColor?: string;
  btnBackgroundColor?: string;
  btnTextColor?: string;
  titleBackgroundColor?: string;
  titleTextColor?: string;
  stripeBackgroundColor?: string;
  bulletColor?: string;
  primaryReady?: boolean;
  standardEnabled?: boolean;
  textPrimary?: string;
  titlePrimary?: string;
  buttonPrimary?: string;
  logoBackgroundColorPrimary?: string;
  backgroundColorPrimary?: string;
  textColorPrimary?: string;
  btnBackgroundColorPrimary?: string;
  btnTextColorPrimary?: string;
  titleBackgroundColorPrimary?: string;
  titleTextColorPrimary?: string;
  bulletColorPrimary?: string;
  showRestricedItems?: boolean;
  restricedItemsUrl?: string;
  restricedItemsText?: string;
  standardReady?: boolean;
  logo?: string;
  logoOffsetTop?: number;
  logoOffsetLeft?: number;
  logoSize?: number;
  logoOffsetTopMobile?: number;
  logoOffsetLeftMobile?: number;
  logoSizeMobile?: number;
  logoStandardOffsetTop?: number;
  logoStandardOffsetLeft?: number;
  logoStandardSize?: number;
  logoStandardOffsetTopMobile?: number;
  logoStandardOffsetLeftMobile?: number;
  logoStandardSizeMobile?: number;
  reverse?: boolean;
  discounts?: boolean;
  isReady?: boolean;
}
