import { autoserialize, autoserializeAs } from 'cerialize';
import { BaseDTO, IBaseDTO } from './base-dto';
import { IItemMeta, ItemMeta } from './item-meta';
import { ISpecification, Specification } from './specification';

export class Item extends BaseDTO implements IItem {
  @autoserialize id?: string;
  @autoserializeAs('created_at') createdAt?: string;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserialize approved?: boolean;
  @autoserialize override name: string;
  @autoserializeAs(Specification, 'specification') specification: Specification;
  @autoserialize width?: number;
  @autoserialize height?: number;
  @autoserialize depth?: number;
  @autoserialize volume?: number;
  @autoserializeAs(ItemMeta, 'meta') meta?: ItemMeta | null;

  constructor(payload?: IItem) {
    super(payload);
    this.id = payload?.id?.toString();
    this.specification = new Specification(payload?.specification);
    this.createdAt = payload?.createdAt;
    this.discardedAt = payload?.discardedAt;
    this.approved = payload?.approved;
    this.name = payload?.name;
    this.width = payload?.width || 0;
    this.height = payload?.height || 0;
    this.depth = payload?.depth || 0;
    this.volume = payload?.volume || 0;
    this.meta = new ItemMeta(payload?.meta);
  }
}

export interface IItem extends IBaseDTO {
  id?: string;
  createdAt?: string;
  discardedAt?: string;
  approved?: boolean;
  name: string;
  specification: ISpecification;
  width?: number;
  height?: number;
  depth?: number;
  volume?: number;
  meta?: IItemMeta | null;
}
