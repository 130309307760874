import { InjectionToken } from '@angular/core';

export const RSPL_VERSION_CONFIG = new InjectionToken<RsplVersionConfig>(
  'config'
);
export const RSPL_APP_VERSION = 'rspl-app-version';

export interface RsplVersionConfig {
  currentHash: string;
}
