<div class="page-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column" fxLayoutAlign="center start">
    <h2>{{ title }}</h2>
    <p *ngIf="subtitle">{{ subtitle }}</p>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" class="header-buttons" fxLayoutAlign="start start">
      <button
        *ngIf="hasBack"
        class="primary-btn filled-btn"
        (click)="onBack.emit()"
      >
        Back
      </button>
      <ng-content></ng-content>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <img [src]="logo ? logo : 'assets/icons-v2/van.png'" alt="Resupply" />
    <span class="logo-arrow" *ngIf="((charity?.logo) || (territory?.logo && territory?.id !== '1')) && !logo"></span>
    <img class="charity-logo" *ngIf="charity?.logo && charity?.id && !logo" [src]="charity.logo" [alt]="charity.name" />
    <img class="charity-logo" *ngIf="(territory?.logo && territory?.id !== '1') && !logo" [src]="territory.logo" [alt]="territory.name" />
  </div>
</div>
