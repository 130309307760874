import { autoserialize } from 'cerialize';

export class StripeCustomer {
  @autoserialize name: string;

  constructor(payload: IStripeCustomer) {
    this.name = payload?.name;
  }
}

export interface IStripeCustomer {
  name: string;
}
