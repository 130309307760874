export enum ErrorCode {
  access_denied_error = 'access_denied_error',
  incorrect_credentials_error = 'incorrect_credentials_error',
  invalid_token_error = 'invalid_token_error',
  record_not_found_error = 'record_not_found_error',
  missing_param_error = 'missing_param_error',
  pagination_error = 'pagination_error',
  unknown_provider_error = 'unknown_provider_error',
  bad_state_action_error = 'bad_state_action_error',
  inapplicable_skip_payment_error = 'inapplicable_skip_payment_error',
  unknown_refund_type_error = 'unknown_refund_type_error',
  stale_resource_error = 'stale_resource_error',
  payment_completed_error = 'payment_completed_error',
  payment_not_completed_error = 'payment_not_completed_error',
  payment_setup_incomplete = 'payment_setup_incomplete',
  invalid_record_error = 'invalid_record_error',
  invalid_state_error = 'invalid_state_error',
  lead_expired_error = 'lead_expired_error'
}
