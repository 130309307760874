import { autoserialize } from 'cerialize';

export class Contact {
  @autoserialize name: string;
  @autoserialize email: string;
  @autoserialize phone: string;

  constructor(
    contactPayload?: IContact
  ) {
    this.name = contactPayload?.name || '';
    this.email = contactPayload?.email || '';
    this.phone = contactPayload?.phone || '';

  }

  get formattedPhoneValue(): string {
    let val: string = this.phone;
    if (val?.length > 0) {
      val = `(${val.slice(0, 3)}) ${val.slice(3, 6)} ${val.slice(6, 10)}`;
    }
    return val;
  }
}

export interface IContact {
  name: string;
  email?: string;
  phone?: string;
}
