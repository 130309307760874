import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rspl-version-warning',
  templateUrl: './version-warning.component.html'
})
export class VersionWarningComponent {

  public constructor(
    public reference: MatDialogRef<VersionWarningComponent>,
  ) {
  }

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }
}
