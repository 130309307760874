import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Charity,
  CharityMeta,
  DonorFlowStepConfig,
  ENVIRONMENT,
  Environment,
  FlowStep,
  FlowTypes,
  Organization,
} from '@domains';
import { AppService } from '@donor/app.service';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowAdditionalInformationComponent } from '@donor/screens/flow/steps/flow-additional-information/flow-additional-information.component';
import { FlowDonationDetailsComponent } from '@donor/screens/flow/steps/flow-donation-details/flow-donation-details.component';
import { FlowPaymentComponent } from '@donor/screens/flow/steps/flow-payment/flow-payment.component';
import { FlowPlaceTimeComponent } from '@donor/screens/flow/steps/flow-place-time/flow-place-time.component';
import { FlowSplitScreenComponent } from '@donor/screens/flow/steps/flow-split-screen/flow-split-screen.component';
import { CharityService, OrganizationsService, PartnerService } from '@rspl-api';
import { DesignService, Destructible } from '@rspl-ui';
import {
  catchError,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { FlowService } from '../donor-flow.service';

@Component({
  selector: 'app-donor-flow',
  templateUrl: './donor-flow.component.html',
  styleUrls: ['./donor-flow.component.scss'],
})
export class DonorFlowComponent extends Destructible implements OnInit {
  #container: ViewContainerRef;
  @ViewChild('dynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  set container(container: ViewContainerRef) {
    this.#container = container;
    this.showStep();
  }
  get container(): ViewContainerRef {
    return this.#container;
  }

  flowType!: FlowTypes;
  flowTypes = FlowTypes;
  flowId: string;
  currentComponent: any;

  charity: Charity;
  organization: Organization;

  stepComponents = {
    FlowSplitScreenComponent,
    FlowDonationDetailsComponent,
    FlowPlaceTimeComponent,
    FlowAdditionalInformationComponent,
    FlowPaymentComponent,
  };

  flowConfiguration: DonorFlowStepConfig[];

  #flowStep: FlowStep;
  set flowStep(flowStep: FlowStep) {
    this.#flowStep = flowStep;
    if (flowStep) {
      let order = this.getCurrentComponent(flowStep);
      if (this.route.snapshot.url[0].path !== 'card-on-file') {
        let correctOrder = order >= 0 ? order : 0;
        for (let i = 0; i < order; i++) {
          if (
            !this.stepComponents[
              this.flowConfiguration[i].component
            ].isCompleted(this.flowService.lead)
          ) {
            correctOrder = i;
          }
        }
        if (order === correctOrder) {
          this.currentComponent =
            this.stepComponents[this.flowConfiguration[correctOrder].component];
          this.showStep();
        } else {
          this.router.navigate(
            [
              '/',
              this.flowConfiguration[correctOrder].step,
              this.flowType,
              this.flowId,
            ],
            {
              queryParams: this.route.snapshot.queryParams,
            }
          );
        }
      } else {
        this.currentComponent =
          this.stepComponents[this.flowConfiguration[order].component];
        this.showStep();
      }
    }
  }

  get flowStep(): FlowStep {
    return this.#flowStep;
  }

  constructor(
    @Inject(ENVIRONMENT) protected environment: Environment,
    protected route: ActivatedRoute,
    protected router: Router,
    private charityService: CharityService,
    private partnerService: PartnerService,
    private organizationsService: OrganizationsService,
    protected flowService: FlowService,
    protected appService: AppService,
    private http: HttpClient,
    private designService: DesignService,
    protected optimizelyService: OptimizelyService,
  ) {
    super();
    if (this.route.snapshot.queryParams['m'] === 'true') {
      this.appService.manualUrlParam = true;
    }
    this.flowId = this.route.snapshot.params['id'];
    this.flowType = this.route.snapshot.params['flowType'];
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.flowStep = this.route.snapshot.params['flowStep'];
      });

    forkJoin([
      this.flowType === FlowTypes.CHARITY && this.flowId
        ? this.charityService.find(this.flowId, true, ['market', 'screening']).pipe(
            //get charity
            switchMap((charity) => {
              if (charity.meta?.splitScreenConfig) {
                //get charity split screen config
                return this.http
                  .get(
                    (charity?.meta?.splitScreenConfig.startsWith('http') ? '' : this.environment.urls.baseUrl) + charity.meta?.splitScreenConfig +
                      `?t=${new Date().getTime()}`
                  )
                  .pipe(
                    catchError((err) => {
                      return of({});
                    }),
                    map((splitScreenConfig) => {
                      charity.meta = new CharityMeta({
                        ...charity.meta,
                        ...splitScreenConfig,
                      });
                      return charity;
                    })
                  );
              } else {
                return of(charity);
              }
            })
          )
        : of(undefined),
      this.flowType === FlowTypes.TERRITORY && this.flowId
        ? this.organizationsService.find(this.flowId) //get organization
        : of(undefined),
      // this.flowType === FlowTypes.PARTNER && this.flowId
      //   ? this.partnerService.find(this.flowId) //get organization
      //   : of(undefined),
    ])
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.charity = res[0];
          this.organization = res[1];

          if (this.charity?.meta?.design)
            this.designService.setDesign(this.charity.meta.design);

          this.flowService
            .initLead(
              this.flowType,
              this.flowId,
              this.route.snapshot.params['flowStep'],
              this.charity,
              this.route.snapshot.queryParams
            )
            .subscribe((res) => {
              if (!res) return;
              this.flowConfiguration =
                this.optimizelyService.optimizelyFlowData.flowConfiguration;

              //remove split screen from steps if needed
              const splitScreenStepIndex = this.flowConfiguration.findIndex(
                (step) => step.component === 'FlowSplitScreenComponent'
              );
              if (splitScreenStepIndex >= 0) {
                if (
                  this.flowType === FlowTypes.PARTNER ||
                  (this.flowType === FlowTypes.TERRITORY &&
                    !this.environment.special.territoriesWithLanding.includes(
                      this.flowId
                    )) ||
                  (this.flowType === FlowTypes.CHARITY &&
                    !this.charity.meta.enabled)
                ) {
                  this.flowConfiguration.splice(splitScreenStepIndex, 1);
                }
              }

              if (this.route.snapshot.url[0].path !== 'card-on-file') {
                //check if flow type exists
                if (!Object.values(FlowTypes).includes(this.flowType)) {
                  this.router.navigate(['/', 'page-not-found']);
                  return;
                }
                //check if flow step exists
                if (
                  !this.flowConfiguration.find(
                    (step) =>
                      step.step === this.route.snapshot.params['flowStep']
                  )
                ) {
                  this.router.navigate(
                    [
                      '/',
                      this.flowConfiguration[0].step,
                      this.flowType,
                      this.flowId,
                    ],
                    {
                      queryParams: this.route.snapshot.queryParams,
                    }
                  );
                  return;
                }

                this.flowStep = this.route.snapshot.params['flowStep'];
              } else {
                if (this.flowService.lead.charityId) {
                  this.charityService
                    .find(this.flowService.lead.charityId)
                    .pipe(take(1))
                    .subscribe((charity) => {
                      this.charity = charity;
                      this.flowStep = FlowStep.Payment;
                    });
                } else {
                  this.flowStep = FlowStep.Payment;
                }
              }
            });
        },
        error: (err) => {
          // flow id doesn't exist
          this.router.navigate(['/', 'page-not-found']);
        },
      });
  }

  getCurrentComponent(flowStep: FlowStep): number {
    return this.flowConfiguration.findIndex((item) => item.step === flowStep);
  }

  showStep() {
    if (
      this.container &&
      this.flowService.lead &&
      (this.route.snapshot.url[0].path === 'card-on-file' ||
        this.flowType === FlowTypes.TERRITORY ||
        this.flowType === FlowTypes.PARTNER ||
        (this.flowType === FlowTypes.CHARITY && this.charity))
    ) {
      this.container.clear();
      const componentRef = this.container.createComponent(
        this.currentComponent
      );
      componentRef.setInput('charity', this.charity);
      componentRef.setInput('organization', this.organization);
      componentRef.setInput('flowConfiguration', this.flowConfiguration);
      componentRef.setInput('flowStep', this.flowStep);
      componentRef.setInput('flowId', this.flowId);
      componentRef.setInput('flowType', this.flowType);

      if (this.flowType === FlowTypes.PARTNER)
        componentRef.setInput('partnerId', this.flowId);
    }
  }
}
