import { autoserialize, autoserializeAs } from 'cerialize';
import { FeeType } from './fee-type';

export class Fee implements IFee{
  @autoserializeAs('fee_type') feeType: FeeType;
  @autoserialize level1?: number | null;
  @autoserialize level2?: number | null;
  @autoserialize level3?: number | null;
  @autoserializeAs('flat_amount') flatAmount?: number | null;
  @autoserializeAs('fixed_percent') fixedPercent?: number | null;

  constructor(payload?: IFee) {
    this.feeType = payload?.feeType || FeeType.Flat;
    this.level1 = payload?.level1;
    this.level2 = payload?.level2;
    this.level3 = payload?.level3;
    this.flatAmount = payload
      ? this.feeType === FeeType.Flat
        ? payload.flatAmount || 30
        : payload.flatAmount
      : 30;
    this.fixedPercent = payload
      ? this.feeType === FeeType.Fixed
        ? payload.fixedPercent || 18
        : payload.fixedPercent
      : 18;
  }

  public static OnSerialized(instance: Fee, json: any): void {
    Fee.feeDolarsToCents(json);
  }

  public static OnDeserialized(instance: Fee, json: any): void {
    instance = Fee.feeCentsToDolars(instance);
  }

  public static feeDolarsToCents(fee: any): any {
    fee.flatAmount =
      fee.flatAmount !== null ? Math.round(fee.flatAmount * 100) : null;
    fee.level1 = fee.level1 !== null ? fee.level1 / 100 : null;
    fee.level2 = fee.level2 !== null ? fee.level2 / 100 : null;
    fee.level3 = fee.level3 !== null ? fee.level3 / 100 : null;
    fee.fixedPercent = fee.fixedPercent !== null ? fee.fixedPercent / 100 : null;
    return fee;
  }

  public static feeCentsToDolars(fee: any): any {
    fee.flatAmount = fee.flatAmount !== null ? fee.flatAmount / 100 : null;
    fee.level1 = fee.level1 !== null ? fee.level1 * 100 : null;
    fee.level2 = fee.level2 !== null ? fee.level2 * 100 : null;
    fee.level3 = fee.level3 !== null ? fee.level3 * 100 : null;
    fee.fixedPercent = fee.fixedPercent !== null ? fee.fixedPercent * 100 : null;
    return fee;
  }
}

export interface IFee {
  feeType: FeeType;
  level1?: number | null;
  level2?: number | null;
  level3?: number | null;
  flatAmount?: number | null;
  fixedPercent?: number | null;
}
