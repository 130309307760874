<app-page-header
  [charity]="donation?.charity"
  title="Request ETA"
  [hasBack]="true"
  (onBack)="back()"
></app-page-header>
<div class="page-content has-back" fxLayout="column">
  <div class="eta-wrapper" fxLayout="column">
    <app-eta *ngIf="donation" [donation]="donation"></app-eta>
  </div>
</div>
