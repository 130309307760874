import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonActivity, Charity, Pricing, Specification } from '@domains';
import { environment } from '@donor/../environments/environment';
import { DesignService, Designable, ResponsiveService } from '@rspl-ui';
import { AppService } from '../../app.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-donation-spec',
  templateUrl: './donation-spec.component.html',
  styleUrls: ['./donation-spec.component.scss'],
})
export class DonationSpecComponent extends Designable {
  specialIds: string[] = environment.special.onlySmall;

  @Input() charityId?: string | null;
  @Input() leadId?: string;
  @Input() specification?: Specification;
  @Input() totalCostEstimate?: number;
  @Input() pricing!: Pricing;
  @Input() showFee = false;
  @Input() saveLabel = 'Next Step';
  @Input() smallLabel = '1-person lift';
  @Output() onSpecificationChange = new EventEmitter();
  @Output() onSave = new EventEmitter();
  activity = ButtonActivity;
  @Input() charity?: Charity | null;
  @Input() showPrice = true;
  @Input() submitColorPrimary? = false;
  @Input() showElevator? = true;

  constructor(
    private dialog: MatDialog,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    protected service: AppService
  ) {
    super(designService, responsiveService);
  }

  onUpdate(e: any) {
    this.onSpecificationChange.emit(e);
  }

  showEstimateInfo() {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Cost Estimate',
        icon: 'info',
        subtitle: 'Potential upcharges',
        message: `This is an estimate based on all items being donatable items as determined by the assigned charity. Additional fees may be determined on-site for anything not included in this estimate and local cost associated with recycling of or disposal of items not accepted by the charity. 
          ${
            (this.pricing.fuelFee || 0) > 0
              ? '</br>*Due to abnormal fuel prices a small (' +
                this.pricing.fuelFee +
                '%) fee may be added on to your final cost estimate.'
              : ''
          }`,
        button: 'I understand.',
      },
    });
  }

  save() {
    this.onSave.emit();
  }

  get onlySmall(): boolean {
    return !!this.charity?.meta?.onlySmallItems;
  }
}
