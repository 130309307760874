import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title: string;
  yes?: string;
  no?: string;
  color: string;
  message: string;
  hideNo?: boolean;
}

@Component({
  selector: 'rspl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  public constructor(
    public reference: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
  }

  public onChoice(choice: boolean): void {
    this.reference.close(choice);
  }
}
