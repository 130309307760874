import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  ENVIRONMENT,
  Environment,
  ErrorCode,
  Lead,
  PageActivity,
  Pricing,
  Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowService } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import { StripeComponent } from '@payment';
import {
  DesignService,
  DiscountInfoDialogComponent,
  DonationEstimateComponent,
  ResponsiveService,
} from '@rspl-ui';

@Component({
  selector: 'app-flow-payment',
  templateUrl: './flow-payment.component.html',
  styleUrls: ['./flow-payment.component.scss'],
})
export class FlowPaymentComponent
  extends BaseFlowStepComponent
  implements OnInit
{
  showQuoteDetails = false;

  @ViewChild(DonationEstimateComponent) estimate!: DonationEstimateComponent;
  @ViewChild(StripeComponent) payment!: StripeComponent;

  cardOnFile = false;
  expired = false;
  userTerms = false;
  isLoaded = false;
  error?: string;

  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    protected override optimizelyService: OptimizelyService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      optimizelyService,
      environment
    );

    if (this.route.snapshot.url.find((x) => x.path === 'card-on-file')) {
      this.cardOnFile = true;
    }

    this.onSaveSuccess = () => {
      this.isSubmitting = true;
      this.payment.onPayment();
      return false;
    };
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.flowService.createPaymentSetup().subscribe({
      error: (error) => {
        if (error?.error?.code === ErrorCode.lead_expired_error) {
          this.expired = true;
        } else {
          this.error = 'Something went wrong, please contact support';
        }
      },
    });
  }

  static override isCompleted(lead: Lead) {
    return false;
  }

  override isValid(): boolean {
    if (new Specification(this.estimate.specification).isEmpty()) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          title: 'No Items',
          message:
            'You need to select at least one item to donate before proceeding to the next step',
          button: 'I understand.',
          icon: 'warning',
        },
      });
      return false;
    }
    if (!this.userTerms) {
      this.appService
        .createLeadActivity(
          this.lead?.id,
          ButtonActivity.PAYMENT_SETUP_SUBMIT_ERROR
        )
        .subscribe();
      return false;
    }
    return true;
  }

  override getFromValue(): Partial<Lead> {
    return {
      specification: new Specification(this.estimate.specification),
    };
  }

  override showError(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.PAYMENT_SETUP_SUBMIT;
  }

  override pageVisitActivity(): PageActivity {
    return PageActivity.PAYMENT_SETUP_PAGE;
  }

  get donationPrice(): number {
    return this.lead?.specification && this.pricing
      ? Pricing.getTotalPrice(this.lead.specification, this.pricing)
      : 0;
  }

  onCheckboxChange(event: any) {
    this.userTerms = !this.userTerms;
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.PAYMENT_CHECKBOX,
        this.userTerms ? 'checked' : 'unchecked'
      )
      .subscribe();
  }

  cardReady() {
    this.isLoaded = true;
  }

  disabledButtonsClicked() {
    if (!this.isValid()) {
      this.showError();
    }
  }

  onPaymentSetupSuccess(activity: ButtonActivity) {
    this.appService.createLeadActivity(this.lead?.id, activity).subscribe();
  }

  protected onPaymentError(err?: string) {
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.PAYMENT_SETUP_SUBMIT_ERROR
      )
      .subscribe();
    this.isSubmitting = false;
    this.error = err;
  }

  paymentSuccess() {
    this.appService
      .createLeadActivity(this.lead?.id, ButtonActivity.PAYMENT_SETUP_SUBMIT)
      .subscribe(() => {
        this.router.navigate(['/', 'created', this.flowService.lead.id]);
        this.flowService.reset();
      });
  }

  showDiscountInfoDialog() {
    this.dialog.open(DiscountInfoDialogComponent, {
      panelClass: 'discount-info-dialog',
    });
  }
}
