import { autoserialize } from 'cerialize';

export class LoginModel {
  @autoserialize email: string;
  @autoserialize password: string;

  constructor(payload: LoginModel) {
    this.email = payload.email;
    this.password = payload.password;
  }
}

export interface ILoginModel {
  email: string;
  password: string;
}
