<div class="page-header big-header" fxLayoutAlign="start end">
  <h2>Payment Successful!</h2>
</div>
<div class="page-content" *ngIf="donation?.id" fxLayout.gt-sm="column">
  <div
    class="outcome-wrapper"
    fxLayout="column"
    fxLayoutAlign="center start"
    fxLayoutGap="20px"
    fxLayoutGap.gt-sm="20px"
  >
    <div fxLayoutGap="20px" fxLayout="column">
      <p>
        Your confirmation code is <br /><b>{{ donationCode }}</b>
      </p>
      <a
        class="primary-page-btn success-btn manage-donation"
        mat-raised-button
        [routerLink]="['/', 'i', donationCode]"
      >
        Review Tax Receipt and Donation Info
      </a>
    </div>
    <h2
      *ngIf="hasReview || review.submitted"
      style="margin-top: 15px"
    >
      Thank you!
    </h2>
    <div *ngIf="(hasReview || review.submitted) && (donation?.review?.rating || review.rating) === 5"  fxLayout="column"  fxLayoutGap="15px">
      <p>
        Help our charity partners receive more donations by listing your review to
        the public. Click below to help raise awareness in your community with a
        public review.
      </p>
      <div fxLayout="column" fxLayoutGap="10px" class="review-options" *ngIf="reviewOptions">
        <div *ngFor="let o of reviewOptions" fxLayout="row">
          <a class="primary-page-btn gray-btn block-btn" mat-raised-button [href]="o.url" target="_blank" *ngIf="o.active" fxFlex (click)="submitPublicReviewActivity(o.platform)">
            <img [src]="'assets/images/icon-' + o.platform.toLowerCase().split(' ').join('') + '.png'" [alt]="o.platform">{{o.platform}}
          </a>
        </div>
      </div>
    </div>
    <app-review #review [donation]="donation"></app-review>
  </div>
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    class="page-buttons"
    fxLayoutAlign.gt-sm="end center"
    *ngIf="!hasReview && !review.submitted"
  >
    <div fxLayoutAlign="center center">
      <button
        class="submit-button primary-page-btn"
        [disabled]="review.form.invalid"
        (click)="review.submit()"
      >
        Submit Review
      </button>
    </div>
  </div>
</div>
