import { autoserialize } from 'cerialize';
import { Design } from './design';
import { ISplitScreen, SplitScreen } from './split-screen';
import { CharityScreeningVersion } from './charity-screening-version';


export class CharityMeta extends SplitScreen {
  @autoserialize design?: Design | null;
  @autoserialize splitScreenConfig?: string | null;
  @autoserialize assignedCSM?: string | null;
  @autoserialize override isReady: boolean;
  @autoserialize onlySmallItems: boolean;
  @autoserialize screening: CharityScreeningVersion | null;
  @autoserialize dropOffUrl?: string | null;
  @autoserialize pickUp?: boolean;

  constructor(payload?: ICharityMeta | null) {
    super(payload, !!payload?.splitScreenConfig && !!payload?.enabled);
    this.design = payload?.design || Design.DESIGN_2;
    this.isReady = payload?.isReady !== false;
    this.splitScreenConfig = payload?.splitScreenConfig;
    this.assignedCSM = payload?.assignedCSM;
    this.onlySmallItems = !!payload?.onlySmallItems;
    this.screening = payload?.screening || null;
    this.dropOffUrl = payload?.dropOffUrl;
    this.pickUp = payload?.pickUp !== false;
  }
}

export interface ICharityMeta extends ISplitScreen {
  design?: Design | null;
  splitScreenConfig?: string | null;
  assignedCSM?: string | null;
  isReady?: boolean;
  onlySmallItems?: boolean;
  screening?: CharityScreeningVersion | null;
  dropOffUrl?: string | null;
  pickUp?: boolean;
}
