<ng-container
  *ngIf="design"
  [ngTemplateOutlet]="DESIGN_2"
></ng-container>
<ng-template #DESIGN_2>
  <div class="page-header" fxLayout="column" fxLayoutAlign="center start">
    <img src="assets/icons-v2/van.png" alt="Resupply" />
    <h2>Donation photos</h2>
    <button class="primary-btn filled-btn" (click)="back()">Back</button>
  </div>
  <div class="page-content has-back" fxLayout="column" fxLayoutGap="20px">
    <div
      class="donation-info step-content"
      fxLayoutAlign="start center"
      fxLayout="column"
      fxLayoutGap="15px"
    >
      <div fxLayout="row wrap" class="photos" fxFlex="100" fxLayoutAlign="start">
        <rspl-photo-upload
          fxFlex="50"
          (uploadedImageUrl)="add($event)"
          *ngIf="step < 2"
        ></rspl-photo-upload>
        <div
          *ngFor="let photo of donation?.photos; let i = index"
          fxFlex="50"
          fxLayoutAlign="center center"
        >
          <div>
            <img [src]="photo" />
            <button mat-icon-button (click)="remove(i)" *ngIf="step < 2">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <button
          class="primary-btn warn-btn filled-btn"
          (click)="onConfirm()"
          [ladda]="isSubmitting"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>
