import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowStep, FlowTypes, OwnerType } from '@domains';
import { AppService } from '@donor/app.service';
import { LeadsService } from '@rspl-api';
import { take } from 'rxjs';

@Component({
  selector: 'app-continue-lead',
  templateUrl: './continue-lead.component.html',
  styleUrl: './continue-lead.component.css',
})
export class ContinueLeadComponent implements OnInit {
  leadId: string;
  flowStep: FlowStep;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private leadService: LeadsService,
    private appService: AppService
  ) {
    this.leadId = this.route.snapshot.params['leadId'];
    this.flowStep = this.route.snapshot.params['flowStep'];
    this.appService.leadOrigin = this.route.snapshot.params['originCode'].toLowerCase();
  }

  ngOnInit(): void {
    this.leadService
      .find(this.leadId)
      .pipe(take(1))
      .subscribe((lead) => {
        let flowType: FlowTypes;
        let flowId: string;
        if (lead.ownerType === OwnerType.Charity) {
          flowType = FlowTypes.CHARITY;
        } else if (lead.ownerType === OwnerType.Partner) {
          flowType = FlowTypes.PARTNER;
        } else if (lead.ownerType === OwnerType.Organization) {
          flowType = FlowTypes.TERRITORY;
        }
        flowId = lead.ownerId;
        const params: any = {
          lead_id: this.leadId,
          d_date: true
        };

        this.router.navigate(['/', this.flowStep, flowType, flowId], {
          queryParams: params,
        });
      });
  }
}
