import { autoserialize } from 'cerialize';
import { StripeFeeType } from './stripe-fee-type';

export class StripeFee {
  @autoserialize amount: number;
  @autoserialize application: string;
  @autoserialize currency: string;
  @autoserialize description: string;
  @autoserialize type: StripeFeeType;

  constructor(payload: IStripeFee) {
    this.amount = payload?.amount;
    this.application = payload?.application;
    this.currency = payload?.currency;
    this.description = payload?.description;
    this.type = payload?.type;
  }
}

export interface IStripeFee {
  amount: number;
  application: string;
  currency: string;
  description: string;
  type: StripeFeeType;
}