import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@domains';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  public constructor(
    @Inject(ENVIRONMENT) private config: Environment,
    private http: HttpClient,
  ) {
  }

  getAppConfig(): Observable<any> {
    return this.http
      .get<any>(
        this.config.urls.baseUrl + '/app_configuration'
      )
      .pipe(
        map((appConfig: any) => {
          return appConfig;
        })
      );
  }

  updateAppConfig(reviewOptions: any): Observable<any> {
    return this.http
      .put<any>(
        this.config.urls.baseUrl + '/app_configuration', { review_options: reviewOptions }
      )
      .pipe(
        map((appConfig: any) => {
          return appConfig;
        })
      );
  }

}
