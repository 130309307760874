import { autoserialize, autoserializeAs } from 'cerialize';
import { Address, IAddress } from './address';
import { BaseDTO, IBaseDTO } from './base-dto';
import { Contact, IContact } from './contact';
import { DayWorkingHours } from './day-working-hours';
import { IMarket, Market } from './market';
import { Day } from './time';

export class Xmile extends BaseDTO implements IXmile {
  @autoserialize id?: string;
  @autoserialize override name: string;
  @autoserialize kind?: string;
  @autoserialize address: Address;
  @autoserializeAs(Contact, 'primary_contact') primaryContact: Contact;
  @autoserializeAs('allowed_items') allowedItems: Array<string>;
  @autoserializeAs(Market, 'market') market?: Market;
  @autoserializeAs('market_id') marketId?: string;
  @autoserializeAs('discarded_at') discardedAt?: string;

  @autoserializeAs(DayWorkingHours, 'monday_time') mondayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'tuesday_time')
  tuesdayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'wednesday_time')
  wednesdayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'thursday_time')
  thursdayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'friday_time') fridayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'saturday_time')
  saturdayTime: DayWorkingHours;
  @autoserializeAs(DayWorkingHours, 'sunday_time') sundayTime: DayWorkingHours;

  public get fullAddress(): string {
    return this.address.fullAddress;
  }

  constructor(payload?: IXmile) {
    super(payload);
    this.id = payload?.id?.toString();
    this.name = payload?.name || '';
    this.kind = payload?.kind;
    this.address = new Address(payload?.address);
    this.primaryContact = new Contact(payload?.primaryContact);
    this.market = payload?.market ? new Market(payload?.market) : undefined;
    this.marketId = payload?.marketId?.toString() || this.market?.id;
    this.allowedItems = payload?.allowedItems || [];
    this.discardedAt = payload?.discardedAt;
    this.mondayTime = new DayWorkingHours(payload?.mondayTime);
    this.tuesdayTime = new DayWorkingHours(payload?.tuesdayTime);
    this.wednesdayTime = new DayWorkingHours(payload?.wednesdayTime);
    this.thursdayTime = new DayWorkingHours(payload?.thursdayTime);
    this.fridayTime = new DayWorkingHours(payload?.fridayTime);
    this.saturdayTime = new DayWorkingHours(payload?.saturdayTime);
    this.sundayTime = new DayWorkingHours(payload?.sundayTime);
  }

  getTime(day: Day): DayWorkingHours {
    return (this as any)[day + 'Time'] as DayWorkingHours;
  }
}

export interface IXmile extends IBaseDTO {
  id?: string;
  name: string;
  kind?: string;
  address: IAddress;
  primaryContact: IContact;
  mondayTime?: DayWorkingHours;
  tuesdayTime?: DayWorkingHours;
  wednesdayTime?: DayWorkingHours;
  thursdayTime?: DayWorkingHours;
  fridayTime?: DayWorkingHours;
  saturdayTime?: DayWorkingHours;
  sundayTime?: DayWorkingHours;
  market?: IMarket;
  marketId?: string;
  discardedAt?: string;
  allowedItems?: Array<string>;
}
