<div class="ss-wrapper">
  <div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100">
      <div
        fxLayout="column"
        class="header"
        fxFlex="50"
        fxFlex.lt-md="100"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="space-between center"
        fxLayoutGap="0"
        fxLayoutGap.lt-md="20px"
      >
        <div fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="start end"
            fxLayoutAlign.lt-md="center end"
          >
            <h1>Welcome to</h1>
            <img class="logo" src="/assets/images/new_logo.svg" />
          </div>
          <span>Trusted partner of {{ charity.name }}</span>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column-reverse"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="center center"
          fxLayoutGap="28px"
          fxLayoutGap.lt-md="20px"
        >
          <button fxFlex="100" (click)="goToPrimary()">
            Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}
          </button>
          <div fxLayoutAlign="center center">
            <img [src]="config.logo || charity.logo" />
          </div>
        </div>
      </div>
      <div fxFlex="50" class="hero" fxLayoutAlign="center center">
        <img
          *ngIf="!isSmallWindowSize"
          class="img1"
          src="/assets/split-screen-v3/image1.webp"
        />
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFlex
          (click)="showVideo()"
        >
          <span fxFlex="100">About Resupply</span>
          <mat-icon svgIcon="ss3-play"></mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="column" fxFlex="100" class="we" fxLayoutGap="10px">
      <h2 *ngIf="isSmallWindowSize">Why choose ReSupply?</h2>
      <div
        fxLayout="row wrap"
        fxFlex="100"
        fxLayoutGap="10px"
        fxLayoutGap.lt-lg="48px"
      >
        <div
          fxLayout="column"
          fxFlex="25"
          fxFlex.sm="50"
          fxFlex.xs="100"
          fxLayoutAlign="start center"
        >
          <div
            class="icon-wrapper"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-icon svgIcon="ss3-truck"></mat-icon>
          </div>
          <h3>We pick-up <span>everything</span></h3>
          <p>
            We pick up all items of <b>any</b> condition to find them a new
            home.
          </p>
        </div>
        <div
          fxLayout="column"
          fxFlex="25"
          fxFlex.sm="50"
          fxFlex.xs="100"
          fxLayoutAlign="start center"
        >
          <div
            class="icon-wrapper"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-icon svgIcon="ss3-stairs"></mat-icon>
          </div>
          <h3>We remove items from <span>anywhere</span> in your home</h3>
          <p>
            Our licensed and insured haulers can pickup up your items anywhere
            within your residence, including upstairs.
          </p>
        </div>
        <div
          fxLayout="column"
          fxFlex="25"
          fxFlex.sm="50"
          fxFlex.xs="100"
          fxLayoutAlign="start center"
        >
          <div
            class="icon-wrapper"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-icon svgIcon="ss3-wrench"></mat-icon>
          </div>
          <h3>We offer professionally <span>disassembly</span></h3>
          <p>
            Our haulers are equipped with the training and tools to disassemble
            any of your items for removal.
          </p>
        </div>
        <div
          fxLayout="column"
          fxFlex="25"
          fxFlex.sm="50"
          fxFlex.xs="100"
          fxLayoutAlign="start center"
        >
          <div
            class="icon-wrapper"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-icon svgIcon="ss3-dollar"></mat-icon>
          </div>
          <h3>A fee helps us <span>support</span> local charities</h3>
          <p>
            Our paid service provides offers a convenient, affordable solution
            with flexible scheduling, professional haulers, and full support
            throughout your pickup.
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isSmallWindowSize">
      <div class="header" fxLayout="row">
        <button fxFlex="100" (click)="goToPrimary()">
          Continue to donation{{ !isSmallWindowSize ? ' pickup' : '' }}
        </button>
      </div>
      <div class="hero" fxLayout="row">
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFlex
          (click)="showVideo()"
        >
          <span fxFlex="100">About Resupply</span>
          <mat-icon svgIcon="ss3-play"></mat-icon>
        </button>
      </div>
    </ng-container>
    <div
      *ngIf="config.discounts"
      fxLayout="row"
      fxFlex="100"
      class="discount"
      fxLayout.lt-md="column"
    >
      <mat-icon svgIcon="ss3-percent"></mat-icon>
      <div fxLayout="column">
        <h2>Charity Sponsored Pickups <span>Now Available!</span></h2>
        <p>
          You might qualify for a charity-sponsored pickup, which could help
          reduce your donation cost. Some of our charity partners offer special
          support to cover part of the pickup fee, making it even easier for you
          to give back.
        </p>
        <p>
          When you schedule your pickup, you’ll automatically be considered for
          one of these sponsored services. Take the next step today and see how
          your donation can make an even bigger impact!
        </p>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="end end"
        fxLayoutAlign.lt-md="center center"
      >
        <button
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="goToPrimary()"
        >
          <span fxFlex="100">Check if you’re eligible</span>
          <mat-icon svgIcon="ss3-arrow-right"></mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="config.standardEnabled" fxLayout="column" fxFlex="100" class="charity">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start start"
        fxLayoutGap="16px"
        class="title-wrapper"
      >
        <img [src]="config.logo || charity.logo" />
        <h2>{{ config.title }}</h2>
      </div>
      <p [innerHTML]="shortText"></p>
      <div
        class="buttons"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="16px"
      >
        <button
          [ngClass]="{ hidden: config?.text?.length < 500 }"
          (click)="showText()"
        >
          Read More
        </button>
        <button fxFlex (click)="goToFree()">
          {{ config.button || 'Ask for Charity free pick-up' }}
        </button>
        <button fxFlex (click)="goToPrimary()">
          Resupply Priority Pick-up
        </button>
      </div>
    </div>
  </div>
</div>
