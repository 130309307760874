import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[rsplDisableDoubleClick]'
})
export class DisableDoubleClickDirective {
  clicked = false;
  @Input()
  debounceTime = 600;

  @Output()
  oneClick = new EventEmitter();

  constructor() {
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.clicked) {
      this.clicked = true;
      this.oneClick.emit(event);
      setTimeout(() => this.clicked = false, this.debounceTime);
    }
  }

}
