<div>
  <div fxLayout="column" fxLayoutAlign="start center">
    <div
      class="card-on-file"
      *ngIf="
        !newCard &&
        (paymentIntent?.payment_method?.card ||
          paymentIntent?.payment_method?.wallet)
      "
    >
      <p>Confirm payment with:</p>
      <p class="last4" *ngIf="paymentIntent?.payment_method?.card">
        {{ paymentIntent?.payment_method?.card?.brand }} ...
        {{ paymentIntent?.payment_method?.card?.last4 }}
      </p>
      <p class="last4" *ngIf="paymentIntent?.payment_method?.wallet?.apple_pay">
        <span>
          <mat-icon>apple</mat-icon>
          Apple Pay
        </span>
      </p>
      <p
        class="last4"
        *ngIf="paymentIntent?.payment_method?.wallet?.google_pay"
      >
        Google Pay
      </p>
      <p>Or use</p>
    </div>
    <button
      class="primary-btn filled-btn"
      (click)="newCard = true"
      *ngIf="!newCard"
    >
      New Card
    </button>
  </div>
  <div
    class="stripe-container"
    *ngIf="
      newCard ||
      !(
        paymentIntent?.payment_method?.card ||
        paymentIntent?.payment_method?.wallet
      )
    "
  >
    <img src="assets/images/loading.png" alt="Loading" *ngIf="!isLoaded" />
    <div
      style="margin-bottom: 10px"
      [ngClass]="{ 'disabled-stripe-buttons': disableButtons }"
      (click)="disableButtons ? disabledButtonsClicked() : undefined"
      *ngIf="showAppleGoogle"
    >
      <ngx-stripe-payment-request-button
        [style.position]="isLoaded ? 'relative' : 'absolute'"
        [style.visibility]="isLoaded ? 'visible' : 'hidden'"
        *ngIf="paymentButtonOptions"
        [paymentOptions]="paymentButtonOptions"
        (paymentMethod)="onButtonPayment($event)"
        (notavailable)="buttonReady()"
        (ready)="buttonReady()"
      ></ngx-stripe-payment-request-button>
    </div>

    <ngx-stripe-payment
      *ngIf="clientSecret"
      [style.position]="isLoaded ? 'relative' : 'absolute'"
      [style.visibility]="isLoaded ? 'visible' : 'hidden'"
      [doNotCreateUntilClientSecretIsSet]="true"
      [clientSecret]="clientSecret"
      [accessKey]="stripeKey"
      [options]="paymentOptions"
      [elementsOptions]="elementsOptions"
      (ready)="cardReady()"
    ></ngx-stripe-payment>
  </div>
  <ng-content></ng-content>
</div>
