import { autoserializeAs } from 'cerialize';


export enum OwnerType {
  Charity = 'Charity',
  Partner = 'Partner',
  Organization = 'Organization',
}

export class Owner implements IOwner {
  @autoserializeAs('owner_id') ownerId?: string;
  @autoserializeAs('owner_type') ownerType?: OwnerType;

  constructor(payload?: IOwner) {
    this.ownerId = payload?.ownerId;
    this.ownerType = payload?.ownerType;
  }
}

export interface IOwner {
  ownerId?: string;
  ownerType?: OwnerType;
}
