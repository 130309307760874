import { autoserialize } from 'cerialize';

export class PartnerMeta implements IPartnerMeta {
  @autoserialize autoAcceptEnabled: boolean;
  @autoserialize etaTrackingEnabled: boolean;

  constructor(payload?: IPartnerMeta | null) {
    this.autoAcceptEnabled = !!payload?.autoAcceptEnabled;
    this.etaTrackingEnabled = !!payload?.etaTrackingEnabled;
  }
}

export interface IPartnerMeta {
  autoAcceptEnabled?: boolean;
  etaTrackingEnabled?: boolean;
}
