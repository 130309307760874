import { autoserializeAs } from 'cerialize';

export class DayZone {
  @autoserializeAs('am_zips') amZips: string[];
  @autoserializeAs('pm_zips') pmZips: string[];

  constructor(
    payload?: IDayZone
  ) {
    this.amZips = payload?.amZips || [];
    this.pmZips = payload?.pmZips || [];
  }
}

export interface IDayZone {
  amZips?: string[];
  pmZips?: string[];
}
