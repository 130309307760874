import { environment } from './../../../../../apps/donor/src/environments/environment.staging';
import { Platform } from '@angular/cdk/platform';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppType, ENVIRONMENT, Environment } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  RsplVersionConfig,
  RSPL_APP_VERSION,
  RSPL_VERSION_CONFIG
} from '../rspl-version-config';
import { VersionCheckService } from './version-check.service';

@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {
  constructor(
    private versionCheck: VersionCheckService,
    private localStorage: LocalStorageService,
    private platform: Platform,
    @Inject(RSPL_VERSION_CONFIG) private config: RsplVersionConfig,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const appVersion = this.localStorage.getItem(RSPL_APP_VERSION);
    if (appVersion) {
      request = request.clone({
        setHeaders: {
          app_version: this.environment.app.toLowerCase() + '-' + appVersion,
        },
      });
    }
    let os: string;
    if (this.platform.ANDROID) {
      os = 'android';
    } else if (this.platform.IOS) {
      os = 'ios';
    } else if (this.platform.BLINK) {
      os = 'blink';
    } else if (this.platform.EDGE) {
      os = 'edge';
    } else if (this.platform.FIREFOX) {
      os = 'firefox';
    } else if (this.platform.SAFARI) {
      os = 'safari';
    } else if (this.platform.TRIDENT) {
      os = 'trident';
    } else if (this.platform.WEBKIT) {
      os = 'webkit';
    } else {
      os = 'unknown';
    }

    request = request.clone({
      setHeaders: {
        user_agent: window.navigator.userAgent,
        os,
        screen_width: window.innerWidth.toString(),
        screen_height: window.innerHeight.toString(),
      },
    });

    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('version.json') ||
      this.environment.app === AppType.TAX_RECEIPT
    ) {
      return next.handle(request);
    }

    request = this.setHeaders(request);

    const now = new Date().getTime();
    if (
      this.environment.production &&
      (!this.versionCheck.lastCheck ||
        now - this.versionCheck.lastCheck > this.environment.versionCheckInterval)
    ) {
      return this.versionCheck.checkVersion().pipe(
        switchMap((proceed) => {
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
