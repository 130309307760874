import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  message?: string | null;
  url?: string | null;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.message = this.route.snapshot.queryParamMap.get('message');
    this.url = this.route.snapshot.queryParamMap.get('url');
    if (
      !this.url &&
      !this.route.snapshot.url.find((e) => e.path === 'page-not-found')
    ) {
      this.router.navigate(['/', 'page-not-found'], {queryParams: { url: window.location.href }})
    } else {
      window.history.replaceState({}, document.title, "/page-not-found");
    }
  }
}
