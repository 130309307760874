import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rspl-categorize-help-dialog',
  templateUrl: './discount-info-dialog.component.html',
  styleUrls: ['./discount-info-dialog.component.scss'],
})
export class DiscountInfoDialogComponent {

  constructor(
    public reference: MatDialogRef<DiscountInfoDialogComponent>

  ) {}
}
